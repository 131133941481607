import { useHistory } from 'react-router-dom'
import { useSelector, shallowEqual } from 'react-redux'

import parse from 'html-react-parser'

import { Button } from 'mmfintech-portal-commons'
import { ButtonsContainer, MessageContainer, ModalMessageWrapper, StyledButton } from './NoSuitableAccountModal.styled'

import { tr } from 'mmfintech-commons'
import { isOwnerOrAdministrator, paths } from 'mmfintech-backend-api'

import { ReactComponent as DepositIcon } from '../../../../images/icons/welcome-deposit.svg'
import { ReactComponent as ExchangeIcon } from '../../../../images/icons/welcome-exchange.svg'

export const NoSuitableAccountModal = ({ onClose }) => {
  const history = useHistory()

  const { customerRole } = useSelector(
    ({ user: { customerRole } }: any) => ({
      customerRole
    }),
    shallowEqual
  )

  const hasDepositAccess = isOwnerOrAdministrator(customerRole)

  const handleDepositClick = () => {
    history.push(paths.banking.deposit())
    onClose()
  }

  const handleExchangeClick = () => {
    history.push(paths.banking.exchange())
    onClose()
  }

  return (
    <ModalMessageWrapper data-test='no-suitable-account-warning'>
      <MessageContainer>
        {hasDepositAccess
          ? parse(
              tr(
                'FRONTEND.WITHDRAW.NO_ACCOUNT_MESSAGE',
                'You have no suitable account to initiate the payment. <b>Deposit</b> or <b>Exchange</b> respective funds to be able to send them.'
              )
            )
          : tr(
              'FRONTEND.WITHDRAW.NO_ACCOUNT_MESSAGE.STAFF',
              'You have no suitable account to initiate the payment. Please ask Administrator or the Owner to Deposit or Exchange funds to be able to send them.'
            )}
      </MessageContainer>

      {hasDepositAccess && (
        <ButtonsContainer>
          <StyledButton data-test='button-deposit' onClick={handleDepositClick}>
            <DepositIcon />
            {tr('FRONTEND.DASHBOARD.BUTTONS.DEPOSIT', 'Deposit')}
          </StyledButton>
          <StyledButton data-test='exchange-button' onClick={handleExchangeClick}>
            <ExchangeIcon />
            {tr('FRONTEND.DASHBOARD.BUTTONS.EXCHANGE', 'Exchange')}
          </StyledButton>
        </ButtonsContainer>
      )}

      <Button
        type='button'
        color='primary'
        text={tr('FRONTEND.BUTTONS.CLOSE', 'Close')}
        onClick={onClose}
        data-test='button-close'
      />
    </ModalMessageWrapper>
  )
}
