import NewsBullet from './NewsBullet'

export const NewsContentSection = ({ title, content, availableNews, activeNewsIndex, handleTransition }) => (
  <div className='inner'>
    {title && <h3>{title}</h3>}
    {content && <p>{content}</p>}

    <div className='bullets-container'>
      {availableNews.length > 1 &&
        availableNews.map((_, index) => (
          <NewsBullet
            key={index}
            index={index}
            active={index === activeNewsIndex}
            handleTransition={handleTransition}
          />
        ))}
    </div>
  </div>
)
