import { useHistory } from 'react-router-dom'

import { Counterparty } from 'mmfintech-commons-types'
import { isValidArray, tr } from 'mmfintech-commons'
import { DataTable } from 'mmfintech-portal-commons'
import { paths } from 'mmfintech-backend-api'

import { ReportStatusBadge } from '../../reports/components/ReportStatusBadge'

import { CounterpartyTableWrapper } from './CounterpartyTableStyle'

const { Table, Row, HeadCell, Head, Body, Cell } = DataTable

type CounterpartyTableProps = {
  data: Counterparty[]
}

const CounterpartyTable: React.FC<CounterpartyTableProps> = ({ data }) => {
  const history = useHistory()

  const handleCounterpartyRowClick = (counterparty: Counterparty) => {
    history.push(paths.expenseManagement.singleCounterparty(counterparty.id))
  }

  return (
    <CounterpartyTableWrapper>
      <Table>
        <Head>
          <Row className='headRow'>
            <HeadCell colSpan={2}>{tr('FRONTEND.COUNTERPARTY.COLUMN_NAME', 'Name')}</HeadCell>
            <HeadCell>{tr('FRONTEND.COUNTERPARTY.COLUMN_TOTAL_EXPENSES', 'Total Expenses')}</HeadCell>
            <HeadCell>{tr('FRONTEND.COUNTERPARTY.COLUMN_AWAITING_PAYMENT', 'Awaiting Payment')}</HeadCell>
            <HeadCell>{tr('FRONTEND.COUNTERPARTY.COLUMN_PAID', 'Paid')}</HeadCell>
            <HeadCell className='text-right'>{tr('FRONTEND.COUNTERPARTY.COLUMN_STATUS', 'Status')}</HeadCell>
          </Row>
        </Head>

        <Body className='main-tbody'>
          {isValidArray(data)
            ? data.map((counterparty: Counterparty) => {
                const { expenseMetrics } = counterparty
                return (
                  <Row key={counterparty.id} onClick={() => handleCounterpartyRowClick(counterparty)}>
                    <Cell caption={tr('FRONTEND.COUNTERPARTY.COLUMN_NAME', 'Name')} colSpan={2}>
                      {counterparty.name}
                    </Cell>
                    <Cell caption={tr('FRONTEND.COUNTERPARTY.COLUMN_TOTAL_EXPENSES', 'Total Expenses')}>
                      {expenseMetrics?.totalExpensesCount}
                    </Cell>
                    <Cell caption={tr('FRONTEND.COUNTERPARTY.COLUMN_AWAITING_PAYMENT', 'Awaiting Payment')}>
                      {expenseMetrics?.outstandingExpensesCount}
                    </Cell>
                    <Cell caption={tr('FRONTEND.COUNTERPARTY.COLUMN_PAID', 'Paid')}>
                      {expenseMetrics?.paidExpensesCount}
                    </Cell>
                    <Cell caption={tr('FRONTEND.COUNTERPARTY.COLUMN_STATUS', 'Status')}>
                      <ReportStatusBadge status={counterparty.state} />
                    </Cell>
                  </Row>
                )
              })
            : null}
        </Body>
      </Table>
    </CounterpartyTableWrapper>
  )
}

export default CounterpartyTable
