import { useEffect, useMemo } from 'react'
import toast from 'react-hot-toast'

import { QueryDefinition } from '@reduxjs/toolkit/dist/query'
import { UseLazyQuery } from '@reduxjs/toolkit/dist/query/react/buildHooks'
import { useAppSelector } from 'mmfintech-backend-api'
import isEqual from 'lodash.isequal'

export const isValidNotEmptyArray = (array: any[]): boolean => {
  return !!(array && array?.length && array?.length > 0)
}

export interface IListQueryResponse<T = any> {
  content: T[]
  totalPages: number
  number: number
  size: number
  totalElements: number
}

const makeSelectCombinedData = (endpointName: string, filter) => {
  return state => {
    const queryKeys = Object.keys(state.api.queries)
    let combinedData = []

    for (const key of queryKeys) {
      if (key.startsWith(`${endpointName}(`)) {
        let originalArgs = { ...state.api.queries[key].originalArgs }
        delete originalArgs.page
        delete originalArgs.size
        if (isEqual(filter, originalArgs)) {
          const pageContent = state.api.queries[key]?.data?.content || []
          combinedData = [...combinedData, ...pageContent]
        }
      }
    }
    return combinedData
  }
}
// If you copy this keep in mind it's not optimized for big data
export const useInfiniteScroll = <T extends QueryDefinition<any, any, any, any>>(
  useGetDataListQuery: UseLazyQuery<T>,
  endpointName: string,
  { size = 10, filter, ...queryParameters }
) => {
  const [getNext, { data, isLoading, isFetching }] = useGetDataListQuery({
    ...queryParameters
  })

  const finalFilter = {
    page: data ? data.number + 1 : 0,
    size,
    ...filter
  }

  const selectCombinedData = useMemo(() => makeSelectCombinedData(endpointName, filter), [endpointName, filter])
  const combinedData = useAppSelector(selectCombinedData)

  const readMore = async () => {
    try {
      await getNext(finalFilter).unwrap()
    } catch (error) {
      toast.error(error?.message)
    }
  }

  useEffect(() => {
    readMore()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    combinedData,
    readMore,
    isLoading: isLoading,
    isFetching: isFetching,
    totalElements: data?.totalElements
  }
}
