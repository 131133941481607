import styled from 'styled-components'

const DocumentWrapper = styled.div<{ $VIEWER_WIDTH: number }>`
  display: flex;
  align-items: center;

  overflow: hidden;
  position: relative;
  box-sizing: border-box;
  width: ${props => `${props.$VIEWER_WIDTH}px`};
  height: 100%;
`
const DocumentContainer = styled.div<{ $solidBorder?: boolean }>`
  width: 100%;
  height: 100%;
  max-height: 49rem;
  border-width: 1px;
  border-color: #85a0ad;
  border-radius: 4px;
  margin: auto;
  background-color: white;

  &.report-page {
    background-color: inherit;
  }

  display: flex;
  flex-direction: column;
  justify-content: center;
`
const PageFooter = styled.div`
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #e3eaed;

  padding: 1.8rem 1.5rem;

  .controls {
    display: flex;
    align-items: center;
    gap: 1.2rem;

    font-size: 1.2rem;
    font-weight: 400;

    svg {
      cursor: pointer;
    }

    .forward-icon {
      transform: rotate(180deg);
    }
  }

  .buttons-container {
    display: flex;
    align-items: center;
    gap: 1rem;

    .action-button {
      max-width: 7.4rem;
      padding: 0.45rem 0.4rem;
      min-width: auto;
      max-height: 2.4rem;
      color: white;
      font-size: 1.2rem;
      font-weight: 400;
      border-radius: 4px;
    }
  }
`

const PageWrapper = styled.div<{ $MIN_VIEWER_HEIGHT: number; $SCALE: number }>`
  height: 100%;
  display: flex;
  justify-content: center;
  max-height: ${props => `${props.$MIN_VIEWER_HEIGHT}px`};
  height: 100%;

  will-change: transform;

  position: relative;
  z-index: 1;
  cursor: ${props => (props.$MIN_VIEWER_HEIGHT > 100 ? 'crosshair' : 'pointer')};

  --zoom: 1;
  --x: 50%;
  --y: 50%;
  transform: scale(var(--zoom));
  transform-origin: var(--x) var(--y);
  overflow: hidden; // Hide overflow during zoom

  &:hover {
    --zoom: ${props => `${props.$SCALE}`}; // Zoom level when hovered
  }
`
export { DocumentContainer, DocumentWrapper, PageFooter, PageWrapper }
