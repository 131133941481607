import styled from 'styled-components'

export const NoAvailableAccountsWrapper = styled.div`
  border: 1px solid rgba(133, 160, 173, 0.2);
  border-radius: 4px;

  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem;

  .left {
    display: flex;
    align-items: center;
    gap: 2rem;
    color: #85a0ad;
    font-size: 1.4rem;
    line-height: 1.7rem;
  }

  .button {
    max-width: 15rem;
    background-color: rgba(0, 97, 232, 0.1);
    border: 1px solid rgba(133, 160, 173, 0.2);
    border-radius: 4px;

    color: #0061e8;
    font-size: 1.2rem;
    line-height: 1.5rem;

    &:hover {
      background-color: rgba(0, 97, 232, 0.1);
      border: 1px solid rgba(133, 160, 173, 0.2);
      color: #0061e8;
    }
  }
`
