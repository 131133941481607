import { useEffect, useState } from 'react'
import { Fade } from 'react-awesome-reveal'
import { TailSpin } from 'react-loader-spinner'

import { useGetVoucherStatisticsQuery } from 'mmfintech-backend-api'

import { ActiveVouchersList } from '../activeVouchers/ActiveVouchersList'
import { OldVoucher } from '../oldVoucher'
import { Tabs } from '../../../components'

import { MyVouchersWrapper, StyledMyVoucherTabsTitle } from './MyVouchersTab.styled'

type Tabs = 'active' | 'past'

export const MyVouchers = () => {
  const [tab, setTab] = useState<Tabs>('active')
  const tabConfig = [
    {
      title: (
        <MyVoucherTabsTitle
          text='Active'
          isActive={tab === 'active'}
          types={['activeCount', 'activeBulkVouchersCount']}
        />
      ),
      name: 'active'
    },

    {
      title: (
        <MyVoucherTabsTitle text='History' isActive={tab === 'past'} types={['pastCount', 'pastBulkVouchersCount']} />
      ),
      name: 'past'
    }
  ]
  return (
    <Fade className='full-width'>
      <MyVouchersWrapper>
        <Tabs config={tabConfig} setTab={setTab} currentTab={tab} />
        {tab === 'active' && <ActiveVouchersList />}
        {tab === 'past' && <OldVoucher />}
      </MyVouchersWrapper>
    </Fade>
  )
}

const MyVoucherTabsTitle = ({ text, isActive, types }) => {
  const { data, refetch } = useGetVoucherStatisticsQuery()

  useEffect(() => {
    refetch()
  }, [isActive])

  const totalCount = types.reduce((acc, type) => {
    if (data && data[type]) {
      acc += data[type]
    }
    return acc
  }, 0)
  return (
    <StyledMyVoucherTabsTitle $isActive={isActive}>
      {text}
      <span className='count-container'>{totalCount !== null ? totalCount : <TailSpin width={10} height={10} />}</span>
    </StyledMyVoucherTabsTitle>
  )
}
