import { FC } from 'react'
import styled from 'styled-components'
import { ReactComponent as InfoIcon } from '../../images/icons/info-circle-black.svg'
import { ReactComponent as TriangleIcon } from '../../images/icons/triangle-icon.svg'

export const Disclaimer: FC<{ text: string[] }> = ({ text }) => (
  <DisclaimerWrapper>
    <TriangleIcon className='arrow' />
    <div className='header'>
      <InfoIcon className='info-icon' />
      <div>Please Note</div>
    </div>
    {text.map((line, index) => (
      <span key={index}>{line}</span>
    ))}
  </DisclaimerWrapper>
)

const DisclaimerWrapper = styled.div`
  position: relative;
  margin: 1rem 0 2.5rem;

  border-radius: 8px;
  background: #f5f6f7;
  padding: 1.2rem 2rem;

  display: flex;
  flex-direction: column;
  gap: 7px;

  .arrow {
    position: absolute;
    top: -1rem;
  }
  .info-icon {
    width: 1.6rem;
    height: 1.6rem;
  }

  .header {
    display: flex;
    align-items: center;
    gap: 8px;

    font-size: 1.4rem;
    font-weight: 600;
  }
`
