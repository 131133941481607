import styled from 'styled-components'

export const SuccessPaneWrapper = styled.div`
  width: 92vw;
  max-width: 50rem;

  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0 0 25px rgba(46, 53, 56, 0.1);
`

export const SuccessPaneContent = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;

  padding: 3rem 5rem 3.5rem;

  button {
    max-width: 18rem;
    margin-top: 1rem;
  }
`

export const SuccessPaneMessage = styled.div`
  color: #000000;
  font-size: 1.8rem;
  font-weight: 400;
  line-height: 2.2rem;

  margin: 2rem 0;
`
