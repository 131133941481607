import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { Fade } from 'react-awesome-reveal'

import { BuyRadioGroup } from './BuyVouchersTab.styled'
import { BuyVoucherAmountLabel } from './BuyVoucherAmountLabel'
import { BuyVoucherInstructions } from './BuyVoucherInstructions'
import { VouchersPreparedAmounts } from './VouchersPreparedAmounts'
import { InputAmount, SelectAccount } from '../../../components'
import { Button, ErrorDisplay, RadioButtonGroup } from 'mmfintech-portal-commons'
import { StyledCashVoucherForm, StyledCashVoucherTitle } from '../styles/StyledCashVoucherForm'

import settings from '../../../settings'

import { OtpContext, isValidArray, useFormValues } from 'mmfintech-commons'
import {
  paths,
  useBuyVoucherMutation,
  useBuyVouchersBulkMutation,
  useUserState,
  useMerchantAccounts,
  useSelectedAccount
} from 'mmfintech-backend-api'

import { Currency, PaymentMethodEnum } from 'mmfintech-commons-types'

const paymentOption = {
  status: 'AVAILABLE',
  minAmount: settings.minVoucherAmount,
  maxAmount: settings.maxVoucherAmount,
  paymentMethod: PaymentMethodEnum.KINGDOM_CASH
}

export const BuyVouchersTab = () => {
  const { setOtpOnSuccess } = useContext(OtpContext)

  const { merchant } = useUserState()
  const { capabilities } = merchant || {}

  const [selectedType, setSelectedType] = useState<'single' | 'bulk'>('single')

  const history = useHistory()
  // const dispatch = useDispatch()

  const { activeAccounts } = useMerchantAccounts({
    validCurrency: (currency?: Currency) => !!currency.supportedForCashVoucher
  })
  const { selectedAccount, selectedAccountId, selectedAccountCurrency, setSelectedAccount } = useSelectedAccount()

  const [buyVoucher, { error, isLoading }] = useBuyVoucherMutation({ fixedCacheKey: 'buy-voucher-mutation' })
  const [buyBulkVoucher, { error: bulkError, isLoading: bulkLoading }] = useBuyVouchersBulkMutation({
    fixedCacheKey: 'buy-voucher-bulk-mutation'
  })

  const formValues = useFormValues({
    sourceAccountId: { required: true },
    amount: { required: true },
    currency: { required: true },
    count: { validation: 'numeric' }
  })

  const handlePurchase = async (e: React.MouseEvent<HTMLFormElement>) => {
    e.preventDefault()

    setOtpOnSuccess(() => (data: any) => {
      if (data && data?.refId) {
        history.push(paths.more.cashVoucherSuccess(data?.refId))
      }
    })

    if (formValues.areValid()) {
      try {
        selectedType === 'bulk'
          ? await buyBulkVoucher(formValues.prepare()).unwrap()
          : await buyVoucher(formValues.prepare()).unwrap()
      } catch (err) {
        formValues.handleErrors(err)
      }
    }
  }

  const handleChangeTab = (tab: 'single' | 'bulk') => {
    formValues.cleanup(['sourceAccountId', 'currency'], true)
    setSelectedType(tab)
  }

  useMemo(() => {
    if (isValidArray(activeAccounts) && !selectedAccount) {
      setSelectedAccount(activeAccounts[0])
    }
  }, [activeAccounts])

  useEffect(() => {
    if (selectedAccount) {
      formValues.setValue('currency', selectedAccountCurrency)
      formValues.setValue('sourceAccountId', selectedAccountId)
    }
  }, [selectedAccount])

  return (
    <Fade className='animation-container'>
      <StyledCashVoucherForm onSubmit={handlePurchase}>
        <StyledCashVoucherTitle>Buy New</StyledCashVoucherTitle>
        {capabilities?.enableBulkCashVouchers ? (
          <BuyRadioGroup
            className='default'
            options={[
              {
                value: 'single',
                label: 'Single Voucher',
                dataTest: 'email'
              },
              {
                value: 'bulk',
                label: 'Multiple Vouchers',
                dataTest: 'sms'
              }
            ]}
            selected={selectedType}
            setSelected={handleChangeTab}
            as={RadioButtonGroup}
          />
        ) : null}
        <SelectAccount
          accounts={activeAccounts}
          noShadow
          label='Account'
          setSelectedAccount={setSelectedAccount}
          selectedAccount={selectedAccount}
          error={formValues.getError('sourceAccountId')}
        />
        <InputAmount
          label={<BuyVoucherAmountLabel selectedType={selectedType} currencyCode={selectedAccountCurrency} />}
          account={selectedAccount}
          paymentOption={selectedType === 'bulk' ? null : paymentOption}
          setAmount={amount => {
            if (selectedType === 'bulk') {
              formValues.setValue('count', amount)
            } else {
              formValues.setValue('amount', amount)
            }
          }}
          amount={formValues.getValue(selectedType === 'bulk' ? 'count' : 'amount')}
          error={formValues.getError(selectedType === 'bulk' ? 'count' : 'amount')}
          hasFees={false}
        />
        <VouchersPreparedAmounts
          selectedType={selectedType}
          currency={selectedAccountCurrency}
          currentAmount={parseInt(formValues.getValue('amount'))}
          setAmount={(amount: number) => formValues.setValue('amount', amount)}
        />
        <BuyVoucherInstructions />
        <ErrorDisplay error={[error, bulkError]} />
        <Button
          loading={isLoading || bulkLoading}
          disabled={isLoading || bulkLoading || !formValues.getValue('amount')}
          type='submit'
          text='Buy'
        />
      </StyledCashVoucherForm>
    </Fade>
  )
}
