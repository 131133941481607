import { useState } from 'react'

import { isValidString } from 'mmfintech-commons'
import { Input } from 'mmfintech-portal-commons'

// Modify the path as necessary

export const formatVoucherCode = (value: string) => {
  // Remove all non-digit characters

  if (!isValidString(value)) {
    return
  }
  const cleanedValue = value.replace(/\D/g, '')

  // Break cleanedValue into groups of 4 using substring
  const parts = [
    cleanedValue.substring(0, 4),
    cleanedValue.substring(4, 8),
    cleanedValue.substring(8, 12),
    cleanedValue.substring(12, 16),
    cleanedValue.substring(16, 20)
  ]

  // Join the parts with hyphen and return
  return parts.filter(Boolean).join('-')
}

export const VoucherCodeInput = props => {
  const [internalValue, setInternalValue] = useState(formatVoucherCode(props.value))

  const handleChange = (name: string, value: string) => {
    const formatted = formatVoucherCode(value)
    setInternalValue(formatted)
    // Propagate the change to parent if onChange is provided
    if (props.onChange) {
      props.onChange(name, formatted?.toString())
    }
  }

  return (
    <Input
      {...props}
      value={internalValue}
      onChange={handleChange}
      onBlur={props?.onBlur}
      placeholder={`${'XXXX-'.repeat(4)}XXXX`}
    />
  )
}
