import { FC, useState } from 'react'
import toast from 'react-hot-toast'

import { ReportFilters } from '../../reports/components/ReportFilters'
import { ReportsWrapper } from './ExpensesDashboard.styled'
import { ExpenseTabs, TableLoader } from '../../../components'
import { ReportsViewHeader, AllReports } from '../../reports'

import { useGetReportListWithoutCacheMutation, useUserState } from 'mmfintech-backend-api'
import {
  fixDateOnly,
  tr,
  useDelayedExecute,
  useFilter,
  usePaginationQuery,
  UsePaginationReturnQuery
} from 'mmfintech-commons'

import { CustomerRole, CustomerRoleEnum, ExpenseManagementReport, ReportStateEnum } from 'mmfintech-commons-types'
import { TableWrapper } from '../../../components'

export const ExpensesDashboard = () => {
  const { merchantFetching, customerRole } = useUserState()
  const tabConfig = getTabConfig(customerRole)
  const [tab, setTab] = useState(tabConfig?.[0]?.name)
  const [getReportsWithoutCache, { data, isLoading }] = useGetReportListWithoutCacheMutation()

  const filterValues = useFilter(
    {
      isOwner: false,
      states: getState(tab)
    },
    ['search'],
    ['fromDate', 'toDate'],
    () => {
      delayedReload.execute()
    },
    true
  )

  const delayedReload = useDelayedExecute({
    name: 'delayedFiltersReload',
    onExecute: () => pagination.reload({ page: 0 })
  })

  const prepareFilter = (params = null) => ({
    ...params,
    ...filterValues.prepare(),
    fromDate: fixDateOnly(filterValues.get('from')),
    toDate: fixDateOnly(filterValues.get('to')),
    deleted: false,
    hideDraftReports: tab === 'all-finance'
  })

  const pagination = usePaginationQuery({
    rowsPerPage: 7,
    reload: async (params, onSuccess) => {
      try {
        const res = await getReportsWithoutCache(prepareFilter(params)).unwrap()

        if (res) {
          onSuccess(res)
        }
      } catch (err) {
        toast.error(err?.cause?.[0]?.cause || err.error)
      }
    }
  })

  const handleTabChange = (selectedTab: string) => {
    if (selectedTab !== tab) {
      setTab(selectedTab)
      filterValues.set('states', getState(selectedTab))
      filterValues.set('isOwner', selectedTab === 'your-reports')
      filterValues.reload()
    }
  }

  return (
    <ReportsWrapper>
      <ReportsViewHeader pagination={pagination} />
      <ReportFilters filter={filterValues} />
      <TableWrapper>
        <ExpenseTabs config={tabConfig} currentTab={tab} setTab={handleTabChange} />
        {isLoading || merchantFetching ? (
          <TableLoader />
        ) : (
          <RenderTable tab={tab} data={data?.content || []} pagination={pagination} />
        )}
      </TableWrapper>
    </ReportsWrapper>
  )
}

const RenderTable: FC<{ tab: string; data: ExpenseManagementReport[]; pagination: UsePaginationReturnQuery }> = ({
  tab,
  ...props
}) => {
  switch (tab) {
    case 'awaiting-approval':
    case 'awaiting-finance-approval':
    case 'awaiting-payment':
    case 'paid':
    case 'all-finance':
      return <AllReports {...props} isApproverTable={true} />
    default:
      return <AllReports {...props} />
  }
}

const getState = (tab: string) => {
  switch (tab) {
    case 'awaiting-approval':
      return [ReportStateEnum.WAITING_APPROVAL]
    case 'awaiting-finance-approval':
      return [ReportStateEnum.WAITING_FINANCE_APPROVAL]
    case 'awaiting-payment':
      return [ReportStateEnum.APPROVED]
    case 'paid':
      return [ReportStateEnum.COMPLETELY_PAID, ReportStateEnum.PARTIALLY_PAID, ReportStateEnum.UNPAID]
    case 'draft':
      return [ReportStateEnum.NEW]
    case 'pending':
      return [ReportStateEnum.WAITING_APPROVAL, ReportStateEnum.WAITING_FINANCE_APPROVAL, ReportStateEnum.APPROVED]
    default:
      return ''
  }
}

const getTabConfig = (role: CustomerRole) => {
  switch (role) {
    case CustomerRoleEnum.SUBMITTER:
      return [
        {
          title: tr('FRONTEND.REPORTS.TABS.ALL_REPORTS', 'All Reports'),
          name: 'all'
        },
        {
          title: tr('FRONTEND.REPORTS.TABS.PENDING_REPORTS', 'Pending Reports'),
          name: 'pending'
        },
        {
          title: tr('FRONTEND.REPORTS.TABS.DRAFT_REPORTS', 'Draft Reports'),
          name: 'draft'
        }
      ]
    case CustomerRoleEnum.APPROVER:
      return [
        {
          title: tr('FRONTEND.REPORTS.TABS.AWAITING_YOUR_APPROVAL', 'Awaiting your approval'),
          name: 'awaiting-approval'
        },
        {
          title: tr('FRONTEND.REPORTS.TABS.YOUR_REPORTS', 'Your reports'),
          name: 'your-reports'
        }
      ]
    case CustomerRoleEnum.OWNER:
    case CustomerRoleEnum.ADMINISTRATOR:
      return [
        {
          title: tr('FRONTEND.REPORTS.TABS.ALL_REPORTS', 'All Reports'),
          name: 'all-finance'
        },
        {
          title: tr('FRONTEND.REPORTS.TABS.AWAITING_YOUR_APPROVAL', 'Awaiting your approval'),
          name: 'awaiting-finance-approval'
        },
        {
          title: tr('FRONTEND.REPORTS.TABS.AWAITING_PAYMENT', 'Awaiting payment'),
          name: 'awaiting-payment'
        },
        {
          title: tr('FRONTEND.REPORTS.TABS.CONCLUDED', 'Concluded'),
          name: 'paid'
        }
      ]
    default:
      return []
  }
}
