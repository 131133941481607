import { useHistory } from 'react-router-dom'
import { shallowEqual, useSelector } from 'react-redux'

import { Button } from 'mmfintech-portal-commons'

import { tr, formatMoney } from 'mmfintech-commons'

import { paths } from 'mmfintech-backend-api'

import {
  AmountContainerWrapper,
  ButtonsContainer,
  CryptoDetailsWrapper,
  CryptoStepWrapper,
  PreviewContainer,
  PreviewWrapper,
  TitleWrapper
} from './CryptoApplicationFeePreview.styled'

import { ReactComponent as DepositIcon } from '../../../../images/icons/deposit.svg'
import { ReactComponent as ExchangeIcon } from '../../../../images/icons/exchange.svg'
import { ReactComponent as ArrowIcon } from '../../../../images/icons/arrow-right-small-grey.svg'

export const CryptoApplicationFeePreview = (props: any) => {
  const { merchant } = useSelector(
    ({ user: { merchant } }: any) => ({
      merchant
    }),
    shallowEqual
  )

  const history = useHistory()

  const { accountSetupFee } = merchant || {}

  const { processingAmount, processingCurrency } = props
  const amount = processingAmount || accountSetupFee?.amount
  const currency = processingCurrency || accountSetupFee?.currency

  return (
    <PreviewWrapper>
      <AmountContainerWrapper>
        {tr('FRONTEND.DEPOSIT.PREVIEW.AMOUNT', 'Amount')}
        <span className='amount' data-test='deposit-amount'>
          {formatMoney(amount, currency)}
        </span>
      </AmountContainerWrapper>
      <PreviewContainer className='double'>
        <CryptoDetails />
      </PreviewContainer>

      <ButtonsContainer>
        <Button
          type='button'
          color='primary'
          onClick={() => history.push(paths.dashboard())}
          text={tr('FRONTEND.DEPOSIT.PREVIEW.BUTTON_BACK', 'Back to Overview')}
          data-test='button-back'
        />
      </ButtonsContainer>
    </PreviewWrapper>
  )
}

const CryptoDetails = () => {
  const history = useHistory()

  return (
    <CryptoDetailsWrapper>
      <TitleWrapper className='pay-crypto'>
        {tr('FRONTEND.DEPOSIT.PREVIEW.CRYPTO.TITLE', 'Pay with Crypto')}
      </TitleWrapper>

      <CryptoStepWrapper>
        <span className='step-icon'>
          <DepositIcon />
        </span>
        <span className='head'>{tr('FRONTEND.DEPOSIT.PREVIEW.STEP1_LABEL', 'Step 1')}</span>
        <span className='main'>
          {tr(
            'FRONTEND.DEPOSIT.PREVIEW.STEP1',
            'Navigate to the Deposit Page, select the crypto you want to deposit in and follow the instructions to complete your transfer.'
          )}
        </span>
        <span className='foot'>
          <Button
            type='button'
            color='round-primary'
            onClick={() => history.push(paths.banking.deposit())}
            text={tr('FRONTEND.DEPOSIT.PREVIEW.BUTTON_DEPOSIT', 'Deposit')}
            data-test='button-deposit'
            icon={<ArrowIcon />}
            iconPosition='right'
          />
        </span>
      </CryptoStepWrapper>

      <CryptoStepWrapper>
        <span className='step-icon'>
          <ExchangeIcon />
        </span>
        <span className='head'>{tr('FRONTEND.DEPOSIT.PREVIEW.STEP2_LABEL', 'Step 2')}</span>
        <span className='main'>
          {tr(
            'FRONTEND.DEPOSIT.PREVIEW.STEP2',
            'Navigate to the Exchange Page and with your crypto buy a sufficient amount of Euros to cover your application fee.'
          )}
        </span>
        <span className='foot'>
          <Button
            type='button'
            color='round-primary'
            onClick={() => history.push(paths.banking.exchange())}
            text={tr('FRONTEND.DEPOSIT.PREVIEW.BUTTON_EXCHANGE', 'Exchange')}
            data-test='button-exchange'
            icon={<ArrowIcon />}
            iconPosition='right'
          />
        </span>
      </CryptoStepWrapper>
    </CryptoDetailsWrapper>
  )
}
