import styled from 'styled-components'

export const MyVouchersWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: center;

  overflow: hidden;
  box-sizing: border-box;

  padding-bottom: 2rem;

  @media (max-width: 768px) {
    padding: 20px 10px 0 10px;
  }
  justify-content: flex-start;

  .infinite-scroll-component {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 0 0.5rem 1rem;
  }
  @media (max-width: 768px) {
    width: 100%;
    .infinite-scroll-component__outerdiv {
      width: 100%;
    }
  }
`

export const StyledMyVoucherTabsTitle = styled.div<{ $isActive: boolean }>`
  display: flex;
  gap: 1rem;
  align-items: center;

  font-size: 1.4rem;
  font-weight: 500;
  color: #000;
  .count-container {
    font-size: 1rem;
    font-weight: 700;
    line-height: 0.5rem;
    padding: 0.7rem;
    border-radius: 8px;
    border: #ededed;

    background-color: #000;
    color: #fff;
  }

  ${({$isActive}) => $isActive ? 'font-weight: 700' : null}
`
