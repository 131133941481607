import type * as CSS from 'csstype'

import { useGetHints } from '../../hooks'
import { isValidArray } from 'mmfintech-commons'

import { CalloutWrapper } from './Callout.styled'
import { CalloutPropType, Hint } from './Callout.types'

// TODO: arrow color, arrow size and arrowPosition with values
export const Callout = ({
  children,
  className,
  hintKey,
  style,
  content,
  isOpen,
  zIndex,
  position,
  alignment,
  offset,
  arrowOptions,
  displayOverContent = true
}: CalloutPropType) => {
  const { isLoading, error, hints } = useGetHints()

  // TODO: error handling
  if (isLoading || error) {
    return <div>{children}</div>
  }

  if (isValidArray(hints)) {
    const hint = hints?.find((h: Hint) => h?.hintKey === hintKey)
    if (hint?.isSeen) return <div>{children}</div>
  }

  const direction = alignment === 'right' ? 'rtl' : 'ltr'
  const bottom = position === 'top' ? '100%' : 'auto'
  const transform = `translate3d(${offset?.x}, ${offset?.y}, 0)`

  const wrapperStyle: CSS.Properties<string | number> = Object.assign({}, style, {
    direction,
    position: displayOverContent ? 'relative' : 'static'
  })

  const triggerStyle: CSS.Properties<string | number> = {
    direction: 'ltr'
  }

  const contentStyle: CSS.Properties<string | number> = {
    display: isOpen ? 'block' : 'none',
    direction: 'ltr',
    position: displayOverContent ? 'absolute' : 'static',
    zIndex: zIndex,
    bottom,
    transform
  }

  const { showArrow = true, pointTowards = 'bottom', arrowPosition = 'left' } = arrowOptions || {}

  const arrowDirection = pointTowards === 'top' || pointTowards === 'bottom' ? 'horizontal' : 'vertical'
  const arrowStyles = showArrow ? `${pointTowards} align-${arrowDirection}-${arrowPosition}` : ''

  return (
    <CalloutWrapper className={className} data-test='callout-wrapper' style={wrapperStyle}>
      <div style={triggerStyle}>{children}</div>
      <div className={`callout ${arrowStyles}`} data-test='callout-content' style={contentStyle}>
        {content}
      </div>
    </CalloutWrapper>
  )
}
