import React, { useEffect, useRef, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'

import moment from 'moment'

import DatePicker from 'react-datepicker'

import { CloseIcon } from '../CloseIcon'
import { ActivityComponent } from './ActivityComponent'
import { IActivitiesMenuProps } from './ActivitiesMenu.types'
import { ActivitiesMenuWrapper } from './ActivitiesMenu.styled'
import { Preloader, ErrorDisplay } from 'mmfintech-portal-commons'

import { isValidArray, todayDate } from 'mmfintech-commons'
import { actions } from 'mmfintech-backend-api'

import { ReactComponent as ArrowIcon } from '../../images/icons/arrow-right.svg'
import { ReactComponent as NoActivitiesIcon } from '../../images/icons/no-transactions.svg'

export const ActivitiesMenu = ({ maxWidth, isMobile, onClose }: IActivitiesMenuProps) => {
  const { lastActivities, lastActivitiesError, infinityScrollParams, selectedLanguage } = useSelector(
    ({ user: { lastActivities, lastActivitiesError, infinityScrollParams, selectedLanguage } }: any) => ({
      lastActivities,
      infinityScrollParams,
      lastActivitiesError,
      selectedLanguage
    }),
    shallowEqual
  )

  const { totalPages } = infinityScrollParams || {}

  const [filter, setFilter] = useState(moment().format('YYYY-MM-DD'))
  const [loading, setIsLoading] = useState(true)
  const [localPage, setLocalPage] = useState(0)
  const [showDatePicker, setShowDatePicker] = useState(false)

  const dispatch = useDispatch()
  const containerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (localPage > totalPages) {
      return
    }
    if (localPage === 0) {
      dispatch(actions.merchant.activitiesInfinityScrollCleanup())
    }
    dispatch(
      actions.merchant.fetchActivitiesInfiniteScroll(
        {
          from: filter,
          to: filter,
          page: localPage,
          size: isMobile ? 15 : 6
        },
        () => setIsLoading(false)
      )
    )
    //eslint-disable-next-line
  }, [dispatch, localPage, filter])

  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const { scrollTop, clientHeight, scrollHeight } = event.currentTarget
    if (scrollTop + clientHeight >= scrollHeight && localPage < totalPages) {
      setLocalPage(prevPage => prevPage + 1)
      setIsLoading(true)
    }
  }

  return (
    <ActivitiesMenuWrapper maxWidth={maxWidth} calendarVisible={showDatePicker}>
      {isMobile && <CloseIcon onClick={onClose} />}
      <div className='header'>
        <ArrowIcon
          className={`alternative ${showDatePicker ? 'hidden' : ''}`}
          onClick={() => {
            setFilter(prevDate => moment(prevDate).subtract(1, 'd').format('YYYY-MM-DD'))
            setIsLoading(true)
            setLocalPage(0)
          }}
        />
        <div className='date-container' onClick={() => setShowDatePicker(currValue => !currValue)}>
          <div className='date'>{moment(filter).format('D')}</div>
          <div className='month-year'>{moment(filter).format('MMMM YYYY')}</div>{' '}
        </div>

        <ArrowIcon
          className={showDatePicker ? 'hidden' : ''}
          onClick={() => {
            if (moment(filter).add(1, 'd').isSameOrBefore(moment())) {
              setFilter(prevDate => moment(prevDate).add(1, 'd').format('YYYY-MM-DD'))
              setIsLoading(true)
              setLocalPage(0)
            }
          }}
        />
      </div>

      <ErrorDisplay error={lastActivitiesError} />

      {showDatePicker ? (
        <div className='activity-list'>
          <DatePicker
            calendarClassName='date-picker'
            locale={selectedLanguage}
            maxDate={todayDate()}
            selected={moment(filter).toDate()}
            onChange={(date: moment.MomentInput) => {
              setIsLoading(true)
              setLocalPage(0)
              setFilter(moment(date).format('YYYY-MM-DD'))
              setShowDatePicker(false)
            }}
            calendarStartDay={1}
            inline
          />
        </div>
      ) : (
        <div className='activity-list' onScroll={handleScroll} ref={containerRef}>
          {loading ? (
            <div className='preloader'>
              <Preloader />
            </div>
          ) : null}
          {isValidArray(lastActivities) ? (
            lastActivities.map((activity: any, index: number) => (
              <ActivityComponent key={index} activity={activity} onClose={onClose} />
            ))
          ) : !loading ? (
            <div className='no-activities'>
              <NoActivitiesIcon fill='black' />
              No activities for the selected period.
            </div>
          ) : null}
        </div>
      )}
    </ActivitiesMenuWrapper>
  )
}
