import styled from 'styled-components'

import { Button } from 'mmfintech-portal-commons'

export const BannerWrapper = styled.div`
  background-color: rgba(0, 97, 232, 0.1);
  border-radius: 1rem;
  box-shadow: none;
  outline: none;

  cursor: pointer;

  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;

  padding: 2.5rem;

  color: #0061e8;
  font-family: inherit;
  font-size: 1.4rem;
  font-style: normal;
  font-weight: normal;
  line-height: 1.7rem;

  @media (max-width: 900px) {
    display: flex;
    width: 100%;
  }

  img {
    max-width: 39px;
    max-height: 39px;
  }

  .button {
    color: #ffffff;
    font-size: 1.4rem;
    font-weight: 500;
    line-height: 0;

    height: 4rem;
    padding: 0 3rem;
    margin-left: 2rem;
  }

  &.dashboard {
    width: 100%;
    padding: 1.5rem 1.5rem 1.5rem 2rem;
    margin-bottom: 2rem;

    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    text-align: center;

    .icon {
      margin-right: 0;
      margin-bottom: 1rem;
    }

    .button {
      margin-top: 1rem;
      margin-left: 0;

      border-radius: 3px;
      padding: 0 3rem;
    }

    @media (min-width: 480px) {
      display: grid;
      grid-gap: 2rem;
      grid-template-columns: auto 1fr auto;
      text-align: left;

      .icon {
        margin-bottom: 0;
      }

      .button {
        margin-top: 0;
      }
    }
  }

  &.forex {
    justify-self: center;
  }

  &.banner-top {
    margin-top: 3rem;
    @media (min-width: 860px) {
      display: none;
    }
    @media (max-width: 399px) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
    }
  }

  button {
    font-size: 1.6rem;
    height: 1.2rem;
    padding: 2rem;
  }
`

export const Centered = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const TextBody = styled.div`
  display: flex;
  flex-direction: column;

  .onboarding-resume-header {
    font-size: 1.5rem;
    margin-bottom: 1rem;

    font-weight: 500;
  }
  &.centered {
    justify-content: center;
  }
`
export const LargeButton = styled(Button)`
  inline-size: max-content;
`

export const SuccessSubtitle = styled.div`
  width: 100%;

  color: #000000;
  font-family: inherit;
  font-size: 2.4rem;
  font-style: normal;
  font-weight: normal;
  line-height: 2.9rem;
  text-align: center;

  margin-bottom: 4rem;
`

export const SuceessContainer = styled.div`
  background: #ffffff;
  border-radius: 3px;
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.08);

  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;

  &.centered {
    align-items: center;

    img {
      margin-bottom: 2rem;
    }

    .message {
      margin-top: 1rem;
      margin-bottom: 4rem;
    }

    button {
      max-width: 20rem;
      background-color: #000000;
      text-transform: uppercase;
      &:hover {
        background-color: #1a1a1a;
      }
    }
  }

  width: 100%;
  max-width: 50rem;
  margin-bottom: 2rem;

  font-size: 1.4rem;

  padding: 2rem;

  @media (min-width: 762px) {
    padding: 4rem 5rem;
  }

  .info {
    margin-top: 2rem;
    margin-bottom: -1rem;
    span {
      margin-left: 1rem;

      :first-of-type {
        margin-left: 0;
      }
    }
  }
`
