import React, { useEffect, useRef, useState } from 'react'
import { NavLink, useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import { ActivitiesMenu } from '../ActivitiesMenu'
import { MerchantSwitchMenu } from '../MerchantSwitchMenu'
import { ActivitiesIcon, ProfileIcon, SettingsIcon } from './Icons'
import { DropDownMenuContainer, HeaderIconsWrapper } from './HeaderIcons.styled'

import { paths, actions } from 'mmfintech-backend-api'
import { tr, useWindowSize } from 'mmfintech-commons'

export interface HeaderIconsProps {
  UserMenu: React.FC<{ onClick: () => void; className: string }>
  maxWidth: number
  isLoggedInAndNotSubmitterOrApprover: () => boolean
}

export const HeaderIcons = ({ UserMenu, maxWidth, isLoggedInAndNotSubmitterOrApprover }: HeaderIconsProps) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const menuRef = useRef<HTMLInputElement>(null)

  const [visibleActivities, setVisibleActivities] = useState(false)
  const [visibleSettings, setVisibleSettings] = useState(false)
  const [width] = useWindowSize()

  const switchDropdowns = (type: 'settings' | 'activities'| "switchMerchants") => {
    switch (type) {
      case 'activities':
        setVisibleActivities(prevVisibility => !prevVisibility)
        setVisibleSettings(false)
        break
      case'switchMerchants':
        setVisibleActivities(false)
        setVisibleSettings(false)
        break
      default:
        setVisibleSettings(prevVisibility => !prevVisibility)
      setVisibleActivities(false)
        break
    }
  }

  const handleLogoutClick = (e: React.SyntheticEvent) => {
    e.preventDefault()
    localStorage.removeItem('cookieTimestamp')
    history.push(paths.login())
    dispatch(actions.auth.logout())
    return false
  }

  const handleProfileRedirect = () => {
    setVisibleSettings(false)
    setVisibleActivities(false)
    history.push(paths.profile())
  }

  useEffect(() => {
    const body = document.getElementsByTagName('body').item(0)
    if (body) {
      if (visibleActivities) {
        body.classList.add('menu-opened')
      } else {
        body.classList.remove('menu-opened')
      }
    }
    // eslint-disable-next-line
  }, [visibleActivities])

  useEffect(() => {
    const checkIfClickedOutside = (e: any) => {
      if (menuRef.current && !menuRef.current.contains(e.target)) {
        setVisibleSettings(false)
        setVisibleActivities(false)
      }
    }
    document.addEventListener('mousedown', checkIfClickedOutside)
    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside)
    }
    // eslint-disable-next-line
  }, [visibleActivities, visibleSettings])

  return (
    <HeaderIconsWrapper ref={menuRef}>
      <MerchantSwitchMenu switchDropdowns={switchDropdowns}/>

      {isLoggedInAndNotSubmitterOrApprover() && (
        <ActivitiesIcon data-test='activity-icon' onClick={() => switchDropdowns('activities')} />
      )}
      {isLoggedInAndNotSubmitterOrApprover() && (
        <ProfileIcon data-test='profile-icon' onClick={() => handleProfileRedirect()} />
      )}

      <SettingsIcon data-test='settings-icon' onClick={() => switchDropdowns('settings')} />

      {visibleActivities && (
        <ActivitiesMenu maxWidth={maxWidth} isMobile={width < maxWidth} onClose={() => setVisibleActivities(false)} />
      )}
      {visibleSettings && (
        <DropDownMenuContainer className='settings'>
          <UserMenu onClick={() => setVisibleSettings(false)} className='menu-item selector' />
          <NavLink to='#' className='menu-item selector' onClick={handleLogoutClick}>
            {tr('FRONTEND.HEADER.LOGOUT', 'Log Out')}
          </NavLink>
        </DropDownMenuContainer>
      )}
    </HeaderIconsWrapper>
  )
}
