import { useMemo, useState } from 'react'

import { Button } from 'mmfintech-portal-commons'
import { ModalDialog } from '../../components'
import { ReportEditModal } from './report/ReportEditModal'
import { AddEditExpenseButton } from './report/reportHeaders/AddEditExpenseButton'
import { HeaderContainer, VerticallyCentered } from './ReportsTableHeaderStyled'

import { tr } from 'mmfintech-commons'
import { useUserState } from 'mmfintech-backend-api'

import { CustomerRoleEnum } from 'mmfintech-commons-types'

import { ReactComponent as AddReportIcon } from '../../images/expenses/add-report.svg'

export const ReportsViewHeader = ({ pagination }) => {
  const [isReportCreateModalVisible, setIsReportCreateModalVisible] = useState<boolean>(false)
  const { customerRole } = useUserState()

  const isApproverOrSubmitter = useMemo(
    () => customerRole === CustomerRoleEnum.SUBMITTER || customerRole === CustomerRoleEnum.APPROVER,
    [customerRole]
  )

  const handleCreateModalOpen = () => setIsReportCreateModalVisible(true)
  const handleCreateModalClose = () => {
    setIsReportCreateModalVisible(false)
  }

  return (
    <HeaderContainer className={isApproverOrSubmitter ? 'padding-top-SA' : ''}>
      <div className='left-wrapper'></div>
      <div className='right-wrapper '>
        <AddEditExpenseButton reload={() => pagination.reload()} />
        <VerticallyCentered>
          <Button
            color='round-primary'
            text={tr('FRONTEND.EXPENSE_MANAGEMENT.ADD_REPORT', 'Add Report')}
            icon={<AddReportIcon />}
            data-test='button-add-report'
            onClick={handleCreateModalOpen}
          />
        </VerticallyCentered>
      </div>

      <ModalDialog
        options={{ size: 'auto', closeOnEscape: false, closeOnClickOutside: false }}
        visible={isReportCreateModalVisible}
        content={<ReportEditModal onClose={handleCreateModalClose} reload={() => pagination.reload()} />}
        onClose={handleCreateModalClose}
      />
    </HeaderContainer>
  )
}
