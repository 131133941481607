import { useEffect } from 'react'
import { apiConfig, configuration } from 'mmfintech-backend-api'

declare global {
  interface Window {
    dataLayer: any[]
  }
}

export const GoogleTagManagerCode = () => {
  // useEffect(() => {
  useEffect(() => {
    if (configuration.isProduction() && apiConfig.VITE_GTAG_CODE && !window.dataLayer) {
      const script1 = document.createElement('script')
      script1.async = true
      script1.src = `https://www.googletagmanager.com/gtag/js?id=${apiConfig.VITE_GTAG_CODE}`
      document.head.appendChild(script1)

      // Create a script element for inline gtag config
      const inlineScript = document.createElement('script')
      inlineScript.text = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments); console.log('GTAG:', arguments)}
      gtag('js', new Date());
      gtag('config', '${apiConfig.VITE_GTAG_CODE}');
      `
      document.head.appendChild(inlineScript)
    }
  }, [])

  return null
}
