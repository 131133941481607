import cn from 'classnames'

import { ICurrencyIconProps } from './CurrencyIcon.types'
import { CurrencyIconWrapper } from './CurrencyIcon.styled'

export const CurrencyIcon = ({
  currency,
  width = '3rem',
  height = '3rem',
  circle = false,
  className = ''
}: ICurrencyIconProps) => {
  return (
    <CurrencyIconWrapper
      width={width}
      height={height}
      className={cn(className, {
        circle,
        [currency?.replace(/\./g, '-')]: true
      })}
    />
  )
}
