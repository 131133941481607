import styled from 'styled-components'

interface LineProps {
  isAdminMessage: boolean
}

const ConversationModalWrapper = styled.div`
  width: 92vw;
  max-width: 60rem;
  position: relative;

  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 0 25px rgba(46, 53, 56, 0.1);

  .header {
    padding: 3rem 2rem;
    box-shadow: 0px 0px 25px rgba(46, 53, 56, 0.1);
    border-bottom: 3px solid #a7b3be;
    color: #ff3131;
    font-weight: 700;
    font-size: 1.6rem;
    line-height: 1.9rem;
  }
  .conversation-pane {
    background-color: rgba(163, 184, 194, 0.1);
    height: 35rem;
    padding: 3rem;

    display: flex;
    flex-direction: column-reverse;
    gap: 3rem;
    overflow-y: auto;

    @media (min-height: 720px) {
      height: 45rem;
    }

    @media (min-height: 900px) {
      height: 55rem;
    }

    @media (min-height: 1080px) {
      height: 67rem;
    }
  }
  .input-pane {
    padding: 1.5rem 2rem 2rem 1.5rem;
    .input-section {
      display: flex;
      flex-flow: column nowrap;
      gap: 1rem;
      .upload-section {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .files {
          display: flex;
          flex-flow: row wrap;
          align-items: center;
          gap: 1rem;
        }
      }
      .textarea {
        & > * {
          border: none;
        }
        .message-input {
          width: 100%;
          border: none;
          font-size: 1.4rem;
          line-height: 1.8rem;
        }
      }
    }
    .buttons-container {
      display: flex;
      flex-flow: row nowrap;
      justify-content: flex-end;
    }
  }
`

const Line = styled.div<LineProps>`
  box-shadow: 0px 1px 3px rgba(46, 53, 56, 0.15);
  border-radius: 8px;
  background-color: ${({ isAdminMessage }) => (isAdminMessage ? '#FFFFFF' : '#007AFF')};
  color: ${({ isAdminMessage }) => (isAdminMessage ? '#000000' : '#FFFFFF')};

  padding: 2rem;

  .line-title {
    font-weight: 600;
    font-size: 1.5rem;
    line-height: 1.7rem;
  }
  .content {
    margin-top: 8px;
    font-size: 1.4rem;
    line-height: 1.8rem;
    word-break: break-word;
    img {
      width: 10rem;
      height: 5rem;
      object-fit: contain;
    }
  }
`

const LineWrapper = styled.div<LineProps>`
  display: flex;
  flex-flow: ${({ isAdminMessage }) => (isAdminMessage ? 'row nowrap' : 'row-reverse nowrap')};
  gap: 1.5rem;

  width: 100%;
  .initials,
  .staff-icon {
    min-width: 4rem;
    height: 4rem;

    display: flex;
    align-items: center;
    justify-content: center;
  }
  .initials {
    background: #000000;
    color: #ffffff;
    font-weight: 600;
    font-size: 1.4rem;
    line-height: 1.7rem;

    border-radius: 50%;
  }
`

const ConversationConfirmWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(10px);
  background-color: rgba(177, 177, 177, 0.6);

  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;

  .inner {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;

    text-align: center;
    background: #ffffff;
    padding: 5rem 3rem;
    border-radius: 8px;
    max-width: 50rem;

    display: flex;
    flex-direction: column;
    justify-content: center;
    .content {
      margin: 3rem 0 4.5rem 0;
      .title {
        font-size: 2.4rem;
        line-height: 2.9rem;
      }
      .subtitle {
        font-size: 1.4rem;
        line-height: 1.7rem;
        margin-top: 1rem;
      }
    }

    button {
      max-width: 40rem;
    }
  }
`

const UploadedFileWrapper = styled.div`
  border: 1px solid rgba(163, 184, 194, 0.3);
  border-radius: 8px;
  position: relative;

  img {
    width: 10rem;
    height: 5rem;
    object-fit: contain;
  }
  .remove-icon {
    position: absolute;
    top: 5px;
    right: 5px;

    width: 2rem;
    height: 2rem;
    cursor: pointer;
  }
`

const FileComponentWrapper = styled.div`
  width: 10rem;
  height: 5rem;
  object-fit: contain;
  padding: 1rem;
  font-size: 1rem;
  line-height: 1.2rem;
`

const ConversationSuccessModalWrapper = styled.div`
  width: 92vw;
  max-width: 70rem;

  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  gap: 3.5rem;

  padding: 5rem 2rem;

  .modal-title {
    font-weight: 400;
    font-size: 2rem;
    line-height: 2.2rem;

    text-align: center;

    @media (min-width: 767px) {
      font-size: 2.4rem;
      line-height: 2.9rem;
    }
  }

  button {
    max-width: 30rem;
  }
`

export {
  ConversationModalWrapper,
  ConversationSuccessModalWrapper,
  UploadedFileWrapper,
  LineWrapper,
  Line,
  ConversationConfirmWrapper,
  FileComponentWrapper
}
