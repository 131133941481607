import React, { useEffect, useState } from 'react'

import { isValidArray, tr, useFormValues } from 'mmfintech-commons'
import {
  useDepositInitiateMutation,
  useMerchantAccounts,
  usePreviewVoucherDepositMutation,
  useSelectedAccount
} from 'mmfintech-backend-api'

import { DepositResult } from '../../banking/DepositResult'
import { SelectAccount } from '../../../components'
import { DepositPreview } from '../../banking/DepositPreview'
import { VoucherCodeInput } from '../voucherCommons/VoucherCodeInput'
import { RedeemVoucherWrapper } from './RedeemVoucher.styled'
import { Button, ErrorDisplay, Input } from 'mmfintech-portal-commons'
import { StyledCashVoucherForm, StyledCashVoucherTitle } from '../styles/StyledCashVoucherForm'

import { Currency, PaymentMethodEnum } from 'mmfintech-commons-types'

export const RedeemVoucherTab = () => {
  const [step, setStep] = useState<number>(1)

  const voucherValues = useFormValues({
    voucherNumber: { required: true },
    voucherSecurityCode: { required: true, maxLength: 4 }
  })

  const { selectedAccount, setSelectedAccount, selectedAccountId, selectedAccountCurrency } = useSelectedAccount()

  const { activeAccounts: accounts } = useMerchantAccounts({
    validCurrency: (currency: Currency) => !!currency.supportedForCashVoucher
  })

  const [
    voucherPreview,
    { data: voucherResponse, error: voucherError, isLoading: voucherLoading, reset: resetVoucher }
  ] = usePreviewVoucherDepositMutation({
    fixedCacheKey: 'redeem-voucher-preview'
  })

  const [depositInitiate, { data: initiateResponse, reset: resetInitiate }] = useDepositInitiateMutation({
    fixedCacheKey: 'deposit-initiate'
  })

  const prepareData = () => {
    const { voucherNumber, voucherSecurityCode } = voucherValues.prepare()
    return {
      paymentMethod: PaymentMethodEnum.KINGDOM_CASH,
      amount: voucherResponse?.processingAmount,
      currency: selectedAccountCurrency,
      destinationAccountId: selectedAccountId,
      voucherNumber: voucherNumber.replace(/-/g, ''),
      voucherSecurityCode
    }
  }

  const handlePreview = async (e?: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (voucherValues.areValid()) {
      try {
        await voucherPreview(prepareData()).unwrap()
        setStep(2)
      } catch (_error) {}
    }
  }

  const handleInitiate = async () => {
    try {
      await depositInitiate(prepareData()).unwrap()
      setStep(3)
    } catch (_error) {}
  }

  const handleCancel = () => {
    setStep(1)
    resetVoucher()
  }

  useEffect(() => {
    return () => {
      setStep(1)
      resetVoucher()
      resetInitiate()
      setSelectedAccount(null)
    }
  }, [])

  useEffect(() => {
    if (isValidArray(accounts) && !selectedAccount) {
      setSelectedAccount(accounts[0])
    }
  }, [accounts])

  return (
    <RedeemVoucherWrapper>
      {step === 1 && (
        <StyledCashVoucherForm className='full-width' onSubmit={handlePreview}>
          <StyledCashVoucherTitle>Redeem Voucher</StyledCashVoucherTitle>

          <SelectAccount
            accounts={accounts}
            label={tr('FRONTEND.TRANSACTIONS.ACCOUNT_LABEL', 'Account')}
            selectedAccount={selectedAccount}
            setSelectedAccount={setSelectedAccount}
            noShadow
          />

          <VoucherCodeInput label='Voucher Number' {...voucherValues.registerInput('voucherNumber')} />

          <Input
            type='password'
            label='PIN'
            maxLength={4}
            placeholder='XXXX'
            {...voucherValues.registerInput('voucherSecurityCode')}
          />

          <ErrorDisplay error={voucherError} />

          <Button text='Redeem' type='submit' loading={voucherLoading} />
        </StyledCashVoucherForm>
      )}

      {step === 2 && (
        <DepositPreview
          onSubmit={handleInitiate}
          onCancel={handleCancel}
          selectedAccount={selectedAccount}
          paymentMethod={PaymentMethodEnum.KINGDOM_CASH}
        />
      )}

      {step === 3 && <DepositResult response={initiateResponse} />}
    </RedeemVoucherWrapper>
  )
}
