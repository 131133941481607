import React, { useContext, useEffect } from 'react'
import toast from 'react-hot-toast'

import { SelectAccount } from '../../../components'
import { StyledRedeemModal } from './RedeemVoucher.styled'
import { Button, ErrorDisplay } from 'mmfintech-portal-commons'
import { StyledCashVoucherTitle } from '../styles/StyledCashVoucherForm'

import { useFormValues, tr, GlobalContext, isValidArray } from 'mmfintech-commons'
import { useMerchantAccounts, useRedeemVoucherByIdMutation, useSelectedAccount } from 'mmfintech-backend-api'

import { Currency } from 'mmfintech-commons-types'

interface RedeemModalProps {
  voucherId: string | number
  currency: string
}

export const RedeemModal: React.FC<RedeemModalProps> = ({ voucherId, currency }) => {
  const { modalHide } = useContext(GlobalContext)

  const { selectedAccount, setSelectedAccount } = useSelectedAccount()

  const { activeAccounts } = useMerchantAccounts({
    validCurrency: (c: Currency) => !!c.supportedForCashVoucher && c.currencyCode === currency
  })

  const [redeemVoucher, { error, isLoading, reset }] = useRedeemVoucherByIdMutation()

  const formValues = useFormValues({
    destinationAccountId: { required: true },
    uuid: { required: true, value: voucherId }
  })

  const handleRedeemVoucher = async () => {
    if (formValues.areValid()) {
      try {
        await redeemVoucher(formValues.prepare()).unwrap()
        toast.success('You have successfully redeemed your voucher.')

        reset()
        modalHide()
      } catch {
        toast.error('Unable to redeem voucher. Please check voucher details or contact support for assistance.')
      }
    }
  }

  useEffect(() => {
    if (isValidArray(activeAccounts)) {
      setSelectedAccount(activeAccounts[0])
    }
  }, [activeAccounts])

  useEffect(() => {
    formValues.setValue('destinationAccountId', selectedAccount?.id)
  }, [selectedAccount])

  return (
    <StyledRedeemModal>
      <StyledCashVoucherTitle>Redeem Voucher</StyledCashVoucherTitle>

      <SelectAccount
        accounts={activeAccounts}
        label={tr('FRONTEND.TRANSACTIONS.ACCOUNT_LABEL', 'Account')}
        selectedAccount={selectedAccount}
        setSelectedAccount={setSelectedAccount}
        noShadow
      />

      <ErrorDisplay error={error} />
      <Button disabled={isLoading} text='Redeem' onClick={handleRedeemVoucher}></Button>
    </StyledRedeemModal>
  )
}
