import { useState, useEffect } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'

import { CloseIcon } from '../../../components'
import { SurveyForm } from './SurveyModal.styled'
import { SurveyStars } from './SurveyStars'
import { Button, ErrorDisplay, Input } from 'mmfintech-portal-commons'

import { actions } from 'mmfintech-backend-api'
import { tr, useFormValues } from 'mmfintech-commons'

import { ReactComponent as SurveySuccess } from '../../../images/icons/survey-success.svg'

export const SurveyModal = () => {
  const { submitSurveyError } = useSelector(
    ({ survey: { submitSurveyError } }: any) => ({
      submitSurveyError
    }),
    shallowEqual
  )

  const [step, setStep] = useState(1)
  const [visible, setVisible] = useState(false)
  const [formError, setFormError] = useState('')

  const dispatch = useDispatch()

  const formValues = useFormValues({
    customerSupport: { required: true },
    easeOfUse: { required: true },
    transactionSpeed: { required: true },
    feedback: { validation: 'safe-string' }
  })

  const handleSubmit = () => {
    if (formValues.areValid()) {
      dispatch(actions.survey.submitSurvey(formValues.prepare(), () => setStep(2)))
    } else {
      setFormError('Please, select all required fields.')
    }
  }

  const handleOnChange = (name: string, rating: number) => {
    if (formError) {
      setFormError('')
    }
    formValues.setValue(name, rating)
  }

  useEffect(() => {
    dispatch(actions.survey.fetchShouldShowSurvey(({ data: shouldShow }) => setVisible(shouldShow)))
    //eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (step === 2) {
      setTimeout(() => {
        setVisible(false)
      }, 3000)
    }
  }, [step])

  useEffect(() => {
    formValues.handleErrors(submitSurveyError)
    // eslint-disable-next-line
  }, [submitSurveyError])

  return visible ? (
    <SurveyForm className={step === 1 ? 'visible' : 'not-visible'}>
      {step === 1 ? (
        <>
          <CloseIcon className='close-survey-icon' onClick={() => setVisible(false)} />

          <div>
            <h3>
              {tr('FRONTEND.SURVEY.MODAL.TITLE', 'How would you rate your overall experience with The Kingdom Bank?')}
            </h3>
          </div>

          <div className='mt-1'>
            <span>{tr('FRONTEND.SURVEY.MODAL.QUESTION_1', 'Ease of use *')}</span>
            <SurveyStars
              onStarClicked={(rating: number) => handleOnChange('easeOfUse', rating)}
              error={formValues.getError('easeOfUse')}
            />
          </div>

          <div className='mt-1'>
            <span>{tr('FRONTEND.SURVEY.MODAL.QUESTION_2', 'Transaction speed *')}</span>
            <SurveyStars
              onStarClicked={(rating: number) => handleOnChange('transactionSpeed', rating)}
              error={formValues.getError('transactionSpeed')}
            />
          </div>
          <div className='mt-1'>
            <span>{tr('FRONTEND.SURVEY.MODAL.QUESTION_3', 'Customer support *')}</span>
            <SurveyStars
              onStarClicked={(rating: number) => handleOnChange('customerSupport', rating)}
              error={formValues.getError('customerSupport')}
            />
          </div>

          <div>
            <p className='additional'>
              {tr(
                'FRONTEND.SURVEY.MODAL.ADDITIONAL',
                'Additionally, please provide us with any specific feedback or suggestions that could help us improve our digital wallet service.'
              )}
            </p>
            <Input
              type='textarea'
              rows={3}
              {...formValues.registerInput('feedback')}
              placeholder='Message (optional)'
              data-test='notes'
            />
          </div>

          <ErrorDisplay error={[formError, submitSurveyError]} />

          <Button
            type='button'
            color='primary'
            text={tr('FRONTEND.SURVEY.MODAL.BUTTON', 'Send')}
            data-test='button-submit'
            onClick={handleSubmit}
          />
        </>
      ) : (
        <>
          <SurveySuccess className='survey-success' />

          <h2>{tr('FRONTEND.SURVEY.MODAL.SUCCESS.TITLE', 'Thank You')}</h2>
          <span className='success-subtitle'>
            {tr('FRONTEND.SURVEY.MODAL.SUCCESS.SUBTITLE', 'For helping us improve our services')}
          </span>
        </>
      )}
    </SurveyForm>
  ) : null
}
