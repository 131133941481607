import styled from 'styled-components'

export const PreviewWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  padding-bottom: 4rem;

  width: 100%;
  max-width: 50rem;
  &.double {
    max-width: 100rem;
  }

  background: #ffffff;
  box-shadow: 0 0 25px rgba(46, 53, 56, 0.1);

  border-radius: 0 0 8px 8px;
`

export const PreviewContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  /* margin-top: 2rem; */
  @media (min-width: 1000px) {
    .double & {
      grid-template-columns: 1fr 1fr;
    }
    /* margin-top: 4rem; */
  }
`

export const AmountContainerWrapper = styled.div`
  width: 100%;

  height: 10rem;
  padding-left: 2rem;
  padding-right: 2rem;

  border-radius: 8px 8px 0 0;
  @media (min-width: 1000px) {
    height: 11rem;
    padding-left: 5rem;
    padding-right: 5rem;
  }

  background: rgba(163, 184, 194, 0.35);

  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  justify-content: center;

  color: #1a1a1a;
  font-family: inherit;
  font-size: 2rem;
  font-style: normal;
  font-weight: normal;
  line-height: 2.4rem;

  .amount {
    font-size: 3.2rem;
    font-weight: 600;
    line-height: 3.9rem;
  }
`

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 3rem;

  button {
    max-width: 40rem;
  }
`

export const TitleWrapper = styled.div`
  width: 100%;
  margin-bottom: 3rem;

  color: #000000;
  font-family: inherit;
  font-size: 2.4rem;
  font-style: normal;
  font-weight: normal;
  line-height: 2.9rem;

  &.pay-crypto {
    text-align: center;
    padding: 0 2rem 4rem;
    @media (min-width: 1000px) {
      padding: 0 5rem;
    }
  }
`

export const WarningMessageWrapper = styled.div`
  color: #000000;
  font-family: inherit;
  font-size: 1.2rem;
  font-style: italic;
  font-weight: 300;
  line-height: 1.5rem;
`

export const CryptoDetailsWrapper = styled.div`
  margin-top: 4rem;
`

export const CryptoStepWrapper = styled.div`
  display: grid;
  grid-template-columns: 50px 1fr;
  grid-template-areas: '- head' 'icon main' 'foot foot';

  color: #000000;
  font-family: inherit;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: normal;
  line-height: 1.5rem;

  padding: 0 2rem 2rem;
  margin-bottom: 3rem;
  :last-child {
    margin-bottom: 0;
  }

  @media (min-width: 1000px) {
    padding: 0 5rem 3rem;
    margin-bottom: 3rem;
  }

  border-bottom: 1px solid rgba(163, 184, 194, 0.3);
  :last-child {
    border-bottom: none;
  }

  .step-icon {
    grid-area: icon;

    background: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 25px rgba(46, 53, 56, 0.1);
    border-radius: 8px;

    width: 4rem;
    height: 4rem;
  }

  .head {
    grid-area: head;
    font-size: 1.6rem;
    font-weight: 600;
    line-height: 1.9rem;
    margin-bottom: 1rem;
  }

  .main {
    grid-area: main;
  }

  .foot {
    grid-area: foot;
    display: flex;
    justify-content: center;
    margin-top: 2rem;

    button {
      max-width: 14rem;
      border: 1px solid rgba(163, 184, 194, 0.3);
      border-radius: 6px;
      box-shadow: none;

      &:hover {
        border: 1px solid rgba(163, 184, 194, 0.3);
        border-radius: 6px;
        box-shadow: none;
      }

      .icon {
        width: 1.4rem;
      }
    }
  }
`
