import { useContext } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { Button } from 'mmfintech-portal-commons'
import { Callout } from '../../../../components'
import { DashboardTitle } from '../../Dashboard.styled'
import { UnlockedFeatureModal } from '../../UnlockedFeatureModal'
import { useHasWritePermissions } from '../../../../hooks'
import { AccountSectionWrapper, BalanceContainer } from './AccountSection.styled'

import { hints } from '../../../../constants'
import { actions, paths, useLazyGetPeerToPeerQrQuery } from 'mmfintech-backend-api'
import { tr, GlobalContext, useWindowSize, isValidArray, formatMoney, extractCurrencyCode } from 'mmfintech-commons'

import { ReactComponent as DepositIcon } from '../../../../images/icons/deposit.svg'
import { ReactComponent as SendIcon } from '../../../../images/icons/send.svg'
import { ReactComponent as ExchangeIcon } from '../../../../images/icons/exchange.svg'

import { ReactComponent as RedDotIcon } from '../../../../images/icons/new-dot-icon.svg'
import { ReactComponent as ScanAndPayIcon } from '../../../../images/icons/scan-pay-icon.svg'

const {
  unlocked: { deposit, exchange }
} = hints

export const AccountSection = ({ aggregatedBalance, enablePortalWalletCheckout }) => {
  const { modalShow } = useContext(GlobalContext)
  const [getPeerToPeerQr] = useLazyGetPeerToPeerQrQuery()

  const { hints: seenHints } = useSelector(
    ({ user: { hints } }: any) => ({
      hints
    }),
    shallowEqual
  )

  const history = useHistory()
  const dispatch = useDispatch()
  const hasAnyPermissions = useHasWritePermissions()
  const [width] = useWindowSize()

  const handleDepositClick = () => {
    actions.routing.setDepositOrigin('')
    history.push(paths.banking.deposit())

    if (seenHints && !seenHints?.find((hint: any) => hint?.hintKey === 'unlocked-deposits')?.isSeen) {
      dispatch(actions.merchant.setUserHints({ hintKey: deposit }, seenHints))
      setTimeout(() => {
        modalShow({
          content: <UnlockedFeatureModal type='deposit' />
        })
      }, 20)
    }
  }

  const handleSendMoneyClick = () => {
    actions.routing.setWithdrawOrigin('')
    history.push(paths.banking.send())
  }

  const handleExchangeClick = () => {
    actions.routing.setExchangeOrigin('')
    history.push(paths.banking.exchange())

    if (seenHints && !seenHints?.find((hint: any) => hint?.hintKey === 'unlocked-exchange')?.isSeen) {
      dispatch(actions.merchant.setUserHints({ hintKey: exchange }, seenHints))
      setTimeout(() => {
        modalShow({
          content: <UnlockedFeatureModal type='exchange' />
        })
      }, 20)
    }
  }

  const handleScanAndPayClick = () => {
    if (enablePortalWalletCheckout) {
      history.push(paths.scanAndPay())
    } else {
      getPeerToPeerQr()
        .unwrap()
        .then(data => {
          if (data?.redirectUrl) {
            history.push(paths.p2pQrCode())
          }
        })
    }
  }

  const calloutProps = {
    isOpen: true,
    position: 'top',
    alignment: 'right',
    arrowOptions: { showArrow: false },
    offset: { x: '1.5rem', y: '1.5rem' },
    content: <RedDotIcon />
  }

  return (
    <AccountSectionWrapper>
      <BalanceContainer>
        {width <= 767 && (
          <DashboardTitle className='title'>{tr('FRONTEND.DASHBOARD.TITLE', 'Overview')}</DashboardTitle>
        )}
        {isValidArray(aggregatedBalance) && (
          <>
            <div className='label'>{tr('FRONTEND.DASHBOARD.ACCOUNT_SELECT.TOTAL_LABEL', 'Total account value')}</div>
            <div className='balance' data-test='balance'>
              {formatMoney(
                aggregatedBalance[aggregatedBalance.length - 1].amount,
                extractCurrencyCode(aggregatedBalance[aggregatedBalance.length - 1])
              )}
            </div>
          </>
        )}
      </BalanceContainer>

      <div className='buttons-container'>
        {hasAnyPermissions && (
          <>
            <Button
              type='button'
              color='secondary'
              icon={<DepositIcon />}
              text={
                <Callout {...calloutProps} hintKey={deposit}>
                  {tr('FRONTEND.DASHBOARD.BUTTONS.DEPOSIT', 'Deposit')}
                </Callout>
              }
              onClick={handleDepositClick}
              data-test='deposit-button'
              data-for='button-tooltip'
            />

            <Button
              type='button'
              color='secondary'
              icon={<SendIcon />}
              text={tr('FRONTEND.DASHBOARD.BUTTONS.SEND', 'Send')}
              onClick={handleSendMoneyClick}
              data-test='send-money-button'
            />

            <Button
              type='button'
              color='secondary'
              icon={<ExchangeIcon />}
              text={
                <Callout {...calloutProps} hintKey={exchange}>
                  {tr('FRONTEND.DASHBOARD.BUTTONS.EXCHANGE', 'Exchange')}
                </Callout>
              }
              onClick={handleExchangeClick}
              data-test='exchange-button'
              data-for='button-tooltip'
            />

            <Button
              type='button'
              color='secondary'
              icon={<ScanAndPayIcon />}
              text={tr('FRONTEND.DASHBOARD.BUTTONS.SCAN_AND_PAY', 'Scan & Pay')}
              onClick={handleScanAndPayClick}
              data-test='scan-pay-button'
            />
          </>
        )}
      </div>
    </AccountSectionWrapper>
  )
}
