import styled from 'styled-components'

export const ReportDeleteIconColumn = styled.div`
  display: flex;
  align-items: center;
  gap: 2rem;
  width: 2.4rem;
  svg {
    display: ${() => {
      if (window.matchMedia('(pointer: coarse)').matches) {
        // If device is using touch screen as main input then we need to display the delete icon always
        return 'flex'
      }
      return 'none'
    }};
  }

  @media (max-width: 800px) {
    svg {
      display: flex;
    }
  }
`

export const ReportTableWrapper = styled.div`
  width: 100%;
  .text-right {
    text-align: right;
  }

  span,
  div {
    font-size: 1.2rem;
    font-weight: 400;
  }

  th {
    color: #85a0ad;
  }

  tr th:first-child {
    padding-left: 3.2rem !important;

    @media (max-width: 799px) {
      padding-left: 0 !important;
    }
  }

  tr th:last-child {
    padding-right: 3.2rem !important;
  }

  tr {
    padding: 0 10px !important;

    @media (max-width: 800px) {
      box-shadow: 0px 4px 25px 0px #2e35381a;
      padding: 12px !important;
    }

    td {
      @media (max-width: 800px) {
        max-width: 100% !important;
        white-space: normal !important;
      }
    }
  }

  tr td:last-child {
    padding-right: 3.2rem;

    span {
      display: flex;
      justify-content: flex-end;
    }
  }

  tr td:first-child {
    padding-left: 3.2rem;

    @media (max-width: 799px) {
      padding-left: 0 !important;
    }
  }

  .main-tbody {
    .image {
      @media (max-width: 800px) {
        display: none;
      }
    }

    tr {
      cursor: pointer;

      @media (max-width: 800px) {
        margin-top: 50px;

        td:last-child {
          border-bottom: none;
        }
      }
    }

    tr:hover {
      background-color: #e3eaed;

      @media (max-width: 800px) {
        background-color: inherit;
        opacity: 0.9;
      }

      svg {
        display: flex;
      }
    }
  }

  @media (max-width: 800px) {
    td:first-child {
      padding: 8px 0;
    }
  }
`
