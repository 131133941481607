import { FrameWrapper } from './IframePreview.styled'

import { isMobileDevice, redirectToExternalUrl } from 'mmfintech-commons'

export const IframePreview = ({ url }) => {
  if (isMobileDevice()) {
    redirectToExternalUrl(url)
    return null
  }

  return (
    <FrameWrapper>
      <iframe src={url} title='External' />
    </FrameWrapper>
  )
}
