import styled from 'styled-components'

export const NoReportsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  justify-content: center;
  align-items: center;
  padding-top: 8rem;
  gap: 2.5rem;

  font-size: 1.2rem;
  color: #808080;
`
