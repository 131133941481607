import styled from 'styled-components'
import { ReportsWrapper } from '../../dashboard/ExpensesDashboard/ExpensesDashboard.styled'
import { HeaderContainer } from '../ReportsTableHeaderStyled'
import { ExpenseModalHeader } from '../expenses/styles/expenseModal.styled'

export const ReportWrapper = styled(ReportsWrapper)`
  display: flex;
  gap: 1.5rem;
`

export const ReportHeaderContainer = styled(HeaderContainer)`
  padding: 4rem 0;
  position: relative;
  align-items: center;

  h1 {
    padding: 0 2rem 0 0;
    margin-bottom: 0;
    border-bottom: 1.5px solid transparent;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 50rem;
  }

  .report-input-wrapper > div {
    box-sizing: border-box;

    padding: 0;

    border: none;
    border-bottom: 1.5px solid #fff;
    border-radius: 0;
  }
  .report-input-wrapper {
    margin: 0;
    box-sizing: border-box;
  }
  .report-name-input {
    height: 100%;
    display: block;
    box-sizing: border-box;

    padding: 0 2rem 1rem 0;

    font-size: 5rem;
    font-weight: 500;
  }
`
export const ReportSubheaderWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-end;
  font-size: 1.4rem;
`

export const LeftReportSubheader = styled.div`
  display: flex;
  gap: 3.125rem;
  height: 60px;

  @media (max-width: 800px) {
    gap: 1.125rem;
    flex-direction: column;
    height: auto;
  }

  & > .row {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    color: #000000;

    .label {
      font-size: 1.4rem;
    }

    .value {
      font-size: 1.6rem;
      padding-bottom: 6px;
    }
  }
`
export const ReportSummaryWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 0.8rem;
  color: black;
  align-items: inherit;

  .label {
    display: block;
    font-size: 1.4rem;
    font-weight: 400;
    margin-bottom: 0.5rem;
    text-align: right;
  }
  .amount-value {
    display: block;
    font-size: 2.8rem;
    font-weight: 600;
  }
`
export const ReportEditModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 500px;
  max-width: 500px;

  @media (max-width: 520px) {
    min-width: 100%;
    max-width: auto;
  }

  padding: 4rem 5rem;

  gap: 3rem;
  border-radius: 0.6rem;

  .form {
    label {
      :first-child {
        color: black;
        font-size: 1.4rem;
      }
    }
  }

  .input-wrapper {
    margin-bottom: 1.5rem;

    &.calendar-input {
      max-width: 22.8rem;

      .has-icon {
        padding-left: 0;
      }
    }

    label {
      line-height: 120%;
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  .buttons-container {
    display: flex;
    flex-direction: column;
    gap: 1.6rem;

    .button-primary,
    .button-secondary {
      width: 100%;

      span {
        font-size: 1.6rem;
      }
    }

    .button-secondary {
      span {
        color: black;
        font-weight: 600;
      }
    }
  }
`
export const ReportEditModalHeader = styled(ExpenseModalHeader)`
  text-align: left;
  margin-bottom: 0;
`
