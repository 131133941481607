import styled from 'styled-components'
import { TabsWrapper } from '../../components/SpendingBar/styles/Requests.styled'

export const CashVoucherWrapper = styled.div`
  grid-area: main-content;

  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  padding: 6rem 0 6rem;

  font-size: 14px;

  margin: 0 auto;
  @media (max-width: 768px) {
    padding: 6rem 1rem 6rem;
  }

  span {
    &.security-title {
      color: #000000;
      font-size: 3.6rem;
      font-weight: 600;
      margin-bottom: 4rem;
    }
  }

  .animation-container {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .amount-wrapper {
    margin-bottom: 0px;
    .label {
      color: #000;
    }
    .value {
      color: #000;
      font-size: 24px;
      font-weight: 700;
      margin-top: 1rem;
    }
  }

  .my-vouchers-link {
    font-weight: 600;
  }
`

export const CashTabs = styled(TabsWrapper)`
  width: 100%;
  flex: 0;
  box-sizing: border-box;

  & > div {
    flex: 1;
    box-sizing: border-box;
  }
`
export const NoVouchersWrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  border-radius: 4px;
  padding: 3rem;

  h2 {
    margin: 0;
  }

  p {
    font-size: 14px;
  }

  background-color: #fff;
  box-shadow: 0px 4px 10px 0px rgba(188, 187, 187, 0.25);

  gap: 2rem;
`
export const VoucherSuccessHeader = styled.div`
  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: center;
  text-align: center;

  gap: 2rem;
  margin-bottom: 2rem;

  p {
    font-size: 14px;
  }
`
