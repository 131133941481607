import { useMemo } from 'react'

import { AmountValue } from './AmountValue'
import { Button, ErrorDisplay } from 'mmfintech-portal-commons'
import { LabelValue, SelectAccount } from '../../../components'
import { DepositPreviewContent, DepositPreviewTitle, DepositPreviewWrapper } from './DepositPreview.styled'

import { tr } from 'mmfintech-commons'
import {
  useDepositInitiateMutation,
  useDepositPreviewMutation,
  usePeerToPeerDepositInitiateMutation,
  usePeerToPeerDepositPreviewMutation,
  usePreviewVoucherDepositMutation
} from 'mmfintech-backend-api'

import { IDepositPreviewProps } from './DepositPreview.types'
import { WithdrawalSubFlowEnum } from 'mmfintech-commons-types'

export const DepositPreview = (props: IDepositPreviewProps) => {
  const { onSubmit, onCancel, selectedAccount, paymentMethod, isAmountRequired = true, styles } = props

  const [_, { data: p2pPreviewResponse }] = usePeerToPeerDepositPreviewMutation({
    fixedCacheKey: 'peer-to-peer-deposit-preview'
  })
  const [_2, { isLoading: p2pInitiateLoading, error: p2pInitiateError }] = usePeerToPeerDepositInitiateMutation({
    fixedCacheKey: 'peer-to-peer-deposit-initiate'
  })
  const [_3, { data: previewResponse }] = useDepositPreviewMutation({
    fixedCacheKey: 'deposit-preview'
  })
  const [_4, { data: voucherResponse }] = usePreviewVoucherDepositMutation({
    fixedCacheKey: 'redeem-voucher-preview'
  })
  const [_5, { error: initiateError, isLoading: initiateLoading }] = useDepositInitiateMutation({
    fixedCacheKey: 'deposit-initiate'
  })

  const isBankwireMethod = useMemo(() => paymentMethod === WithdrawalSubFlowEnum.BANKWIRE, [paymentMethod])

  const { processingAmount, processingCurrency } = p2pPreviewResponse ?? voucherResponse ?? previewResponse ?? {}
  const { fee, feeCurrency, walletAmount, walletCurrency } = p2pPreviewResponse ?? previewResponse ?? {}

  const onDeposit = async () => onSubmit(processingAmount)

  return (
    <DepositPreviewWrapper showBackground={isAmountRequired} halfBackground={!!fee} style={styles}>
      {isAmountRequired && (
        <AmountValue amount={processingAmount} currency={processingCurrency} fee={fee} feeCurrency={feeCurrency} />
      )}

      {!!fee && (
        <LabelValue
          label={tr('FRONTEND.DEPOSIT.PRELIMINARY_PREVIEW.NET_AMOUNT', 'Net amount')}
          amount={walletAmount}
          currency={walletCurrency}
          dataTest='netAmount'
          labelClass='depositPreviewLabel'
          valueClass='depositPreviewValue'
        />
      )}

      {selectedAccount && (
        <>
          <div className='mb-2' />
          <SelectAccount
            label={tr('FRONTEND.TRANSACTIONS.ACCOUNT_LABEL', 'Account')}
            selectedAccount={selectedAccount}
            staticDisplay
            noShadow
          />
        </>
      )}

      <DepositPreviewTitle>{tr('FRONTEND.DEPOSIT.DEPOSIT_DETAILS', 'Deposit details')}</DepositPreviewTitle>

      <DepositPreviewContent>
        <div className='paymentMethodLabel'>{tr('FRONTEND.DEPOSIT.SELECT_METHOD_TITLE', 'Payment Method')}</div>
        <div className='paymentMethod'>{tr(`METADATA.PAYMENT_OPTIONS.${paymentMethod}`, paymentMethod)}</div>
      </DepositPreviewContent>

      <ErrorDisplay error={[initiateError, p2pInitiateError]} />

      <Button
        type='button'
        color='primary'
        text={
          isBankwireMethod
            ? tr('FRONTEND.DEPOSIT.BUTTON_CONTINUE', 'Continue')
            : tr('FRONTEND.DEPOSIT.PRELIMINARY_PREVIEW.BUTTON_DEPOSIT', 'Deposit')
        }
        loading={initiateLoading || p2pInitiateLoading}
        onClick={onDeposit}
        className='mb-1'
        data-test='button-deposit'
      />
      <Button
        type='button'
        color='secondary'
        text={tr('FRONTEND.BUTTONS.CANCEL', 'Cancel')}
        disabled={initiateLoading || p2pInitiateLoading}
        onClick={onCancel}
        data-test='button-cancel'
      />
    </DepositPreviewWrapper>
  )
}
