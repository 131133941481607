import styled from 'styled-components'

export const WelcomeWrapper = styled.div`
  display: grid;
  grid-gap: 3rem;
  grid-template-columns: 1fr;
`

export const WelcomeContainer = styled.div`
  width: 100%;
  max-width: 64rem;
  padding: 4rem;

  border-radius: 1rem;
  background-color: #ffffff;

  @media (max-width: 480px) {
    margin: auto;
  }
`

export const WelcomeContent = styled.div`
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  text-align: center;

  .title {
    font-size: 2.4rem;
    font-weight: 400;
  }

  .welcome-text {
    padding: 1rem 0;
    font-size: 1.4rem;

    &.italic {
      font-style: italic;
    }
  }

  .buttons-wrapper {
    margin-top: 3rem;
    button:nth-of-type(2) {
      margin-top: 1rem;
    }
  }
`

export const WelcomeButtons = styled.div`
  display: flex;
  margin: 2rem 0;

  .round-square-container {
    margin: 0 0.5rem;
  }

  @media (max-width: 480px) {
    flex-flow: column nowrap;
    div:nth-of-type(2) {
      margin: 1rem 0;
    }
  }
`

export const BookMeetingWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;

  @media (min-width: 480px) {
    grid-template-columns: repeat(2, 1fr);
  }

  padding: 1rem 2rem 0;
  background-color: #000000;
  color: #ffffff;
  font-size: 1.6rem;
  font-weight: 700;

  border-radius: 1rem;

  .calendar {
    align-self: end;
    display: none;

    @media (min-width: 480px) {
      display: inline-block;
    }
  }

  .book-left-side {
    position: relative;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    gap: 1rem;

    padding: 2.5rem 0;

    @media (min-width: 480px) {
      align-items: flex-start;
    }
    .book-text {
      font-size: 1.6rem;
      font-weight: 700;
      line-height: 2rem;
    }
    .schedule-button {
      background-color: #676767;
      border-radius: 4px;
      font-size: 1.4rem;

      height: 4rem;
      width: 100%;

      @media (min-width: 480px) {
        max-width: 20rem;
      }
    }
    svg {
      position: absolute;
      bottom: 4px;
      right: 0;

      @media (min-width: 480px) {
        right: 75px;
      }
    }
  }
`
