import { KingdomBankLogo, LoginForm } from '../../../components'
import { LoginContainer, LoginWrapper } from './Login.styled'

import { useLogin } from 'mmfintech-backend-api'

const Login = () => {
  const { formValues, handleSubmit } = useLogin()

  return (
    <LoginWrapper>
      <LoginContainer>
        <KingdomBankLogo />
        <LoginForm formValues={formValues} handleSubmit={handleSubmit} />
        <span />
      </LoginContainer>
    </LoginWrapper>
  )
}

export default Login
