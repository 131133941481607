import styled from 'styled-components'

export const NoReferenceModalWrapper = styled.div`
  max-width: 50rem;
  text-align: center;

  @media (max-width: 565px) {
    max-width: 40rem;
    min-width: 40rem;
    padding: 2rem;
  }

  @media (min-width: 560px) {
    padding: 4rem 5.2rem 5rem;
  }

  .title {
    font-size: 2.4rem;
    line-height: 2.9rem;
  }

  .content-text {
    font-size: 1.4rem;
    line-height: 1.7rem;
    margin: 1rem 0 4rem 0;
  }

  button:first-of-type {
    margin-bottom: 1rem;
  }
`
