import { StatusBannerWrapper } from './StatusBanner.styled'

interface StatusBannerProps {
  status: string
}

export const StatusBanner = ({ status }: StatusBannerProps) => {
  return status ? (
    <StatusBannerWrapper className={`status-box ${status.toLowerCase()?.replace(' ', '-')}`}>
      {status}
    </StatusBannerWrapper>
  ) : null
}
