import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { shallowEqual, useSelector } from 'react-redux'

import styled from 'styled-components'

import { Input } from 'mmfintech-portal-commons'

import { paths, useGetMerchantsForSwitchQuery, useSwitchMerchantMutation } from 'mmfintech-backend-api'

import { ReactComponent as Icon } from '../../images/icons/subaccount.svg'

export const MerchantSwitchMenu = ({switchDropdowns}) => {
  const { merchant /*, merchantFetching*/ } = useSelector(
    ({ user: { merchant, merchantFetching } }: any) => ({
      merchant,
      merchantFetching
    }),
    shallowEqual
  )

  const [merchantId, setMerchantId] = useState<number>(null)
  const { data: merchants } = useGetMerchantsForSwitchQuery()

  const [switchMerchant, { isLoading: switchMerchantLoading }] = useSwitchMerchantMutation()


  const history = useHistory()

  const handleMerchantSwitch = async (merchantId: number) => {
    if (!switchMerchantLoading && merchant?.merchantId !== merchantId) {
      try {
        setMerchantId(merchantId)
        await switchMerchant({ merchantId }).unwrap()
        history.push(paths.dashboard())
        // dispatch(api.util.resetApiState())
        window.location.reload()
      } catch (_error) {
        setMerchantId(merchant?.merchantId)
      }
    }
  }

  const formatOptionLabel = ({ label }) => (
    <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
      <Icon />
      <div>{label}</div>
    </div>
  )

  useEffect(() => {
    setMerchantId(merchant?.merchantId)
  }, [merchant])

  if (Array.isArray(merchants) && merchants.length > 1) {
    return (
      <MenuWrapper onClick={() => switchDropdowns("switchMerchants")}>
        <Input
          type='select'
          value={merchantId || ''}
          options={merchants?.map(m => ({ value: m.merchantId, label: m.name }))}
          onChange={(_name: string, value: string) => handleMerchantSwitch(Number(value))}
          styles={merchantSwitchStyles}
          isSearchable={true}
          hideErrorLine
          formatOptionLabel={formatOptionLabel}
        />
      </MenuWrapper>
    )
  }

  return null
}

const MenuWrapper = styled.div`
  .input-wrapper {
    margin-bottom: 0;

    & > div {
      padding: 0 5px;
    }
  }
`

const merchantSwitchStyles = {
  container: (provided: any) => ({
    ...provided,
    width: 'auto',
    minWidth: '10rem',
    maxWidth: '25rem'
  }),
  control: (provided: any) => ({
    ...provided,
    //   background: 'transparent',
    border: 'none',
    boxShadow: 'none',
    //   cursor: 'pointer',
    minHeight: '3.5rem'
  }),
  valueContainer: (provided: any) => ({
    ...provided,
    fontSize: '1.4rem',
    lineHeight: '1.7rem',
    padding: 0
  }),
  dropdownIndicator: (provided: any) => ({
    ...provided,
    paddingRight: 0
  }),
  menu: (provided: any) => ({
    ...provided,
    width: 'auto'
  })
}
