import styled from 'styled-components'
import { CommonPageTitle } from '../../../components'

export const SendMoneyFlowsContainer = styled.div`
  display: inline-grid;
  grid-gap: 2rem;
  grid-template-columns: 28rem;

  margin: 0 auto;

  @media (min-width: 640px) {
    grid-template-columns: 28rem 28rem;
  }

  @media (min-width: 1024px) {
    grid-template-columns: 28rem 28rem 28rem;
  }
`

export const SendMoneyWrapper = styled.div`
  grid-area: main-content;

  display: flex;
  flex-flow: column nowrap;
  align-items: center;
`

export const SendMoneyTitle = styled(CommonPageTitle)`
  text-align: center;
  margin-top: 5rem;
  margin-bottom: 3rem;
`

export const SendMoneyContainer = styled.div`
  width: 92vw;
  max-width: 50rem;

  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0 0 25px rgba(46, 53, 56, 0.1);
`

export const SendMoneyContent = styled.div`
  padding: 3rem 5rem 3.5rem;

  .subtitle {
    color: #000000;
    font-size: 1.8rem;
    font-weight: 400;
    line-height: 2.2rem;

    &.no-padding {
      margin-bottom: 0;
    }
  }

  .asterisk {
    color: #ff3131;
  }

  [data-test='select-input-destinationPaymentInstrumentId'] {
    margin-bottom: 1rem;
  }

  .label-value,
  .row {
    display: flex;
    flex-flow: column nowrap;
    margin-bottom: 1.5rem;

    color: #000000;
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 1.7rem;

    .label {
      color: #a3b8c2;
      font-size: 1.2rem;
      line-height: 1.5rem;
      margin-bottom: 0;
      padding-right: 2rem;
    }
    .value {
      white-space: normal !important;
      word-break: break-all !important;
      overflow-wrap: anywhere !important;
      max-width: 100%;
    }
  }

  &.final {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;

    img {
      margin-bottom: 2rem;
    }

    button {
      max-width: 18rem;
      margin-top: 1rem;
    }
  }

  .radio-group-wrapper {
    margin: 3rem 0;
    .radio-group-title {
      font-weight: 400;
      font-size: 1.4rem;
      line-height: 1.7rem;
    }
  }

  .recipient-description-wrapper {
    margin: 2rem 0;
    .description-label {
      color: #a3b8c2;
      font-weight: 400;
      font-size: 1.2rem;
      line-height: 1.5rem;
    }
    .description {
      font-size: 1.4rem;
      line-height: 2.1rem;

      overflow-wrap: anywhere;
      word-break: break-all;
    }
  }
  .file-operation-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .download-batch-payment-instructions {
    color: #85a0ad;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
      color: #0061E8;
      svg{ 
        filter: brightness(0) saturate(100%) invert(27%) sepia(87%) saturate(1782%) hue-rotate(201deg) brightness(91%) contrast(116%);
      }
    }
    span {
      font-family: Inter;
      font-size: 14px;
      font-weight: 400;
      line-height: 16.94px;
      text-align: left;
    }
  }
`

export const SendMoneyDisclaimer = styled.div`
  font-size: 1.4rem;
  font-style: italic;
  line-height: 1.7rem;
  margin-bottom: 2rem;
`

export const SendMoneyAddRecipient = styled.div`
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 2rem;
  cursor: pointer;

  color: #000000;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 15px;

  img,
  svg {
    width: 8px;
    height: 8px;
    margin-right: 0.5rem;
  }
`

export const SendMoneyPreviewHead1 = styled.div`
  background: rgba(163, 184, 194, 0.35);
  border-radius: 8px 8px 0 0;
  padding: 2rem 5rem 1.5rem;

  color: #1a1a1a;
  font-family: inherit;
  font-size: 2.4rem;
  font-style: normal;
  font-weight: 600;
  line-height: 2.9rem;

  .label {
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 1.9rem;
  }

  .value {
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;

    .fee {
      color: #678899;
      font-size: 1.2rem;
      font-style: italic;
      font-weight: 400;
      line-height: 1.5rem;
    }
  }
`

export const SendMoneyPreviewHead2 = styled.div`
  background: rgba(163, 184, 194, 0.6);
  border-radius: 0;
  padding: 0.7rem 5rem;

  color: #000000;
  font-family: inherit;
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.9rem;

  .label {
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 1.5rem;
  }
`

export const InputAmountWrapper = styled.div`
  width: 100%;
  margin-top: 3rem;

  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 2rem;

  .all-balance-button {
    min-height: auto;
    height: 3.2rem;
    width: 9rem;
    border: 1px solid rgba(133, 160, 173, 0.2);
    border-radius: 5px;
    padding: 0.7rem 1.2rem;

    font-size: 1.2rem;
    line-height: 1.5rem;
    font-weight: 400;
    color: #000000;
    &:hover,
    &:focus {
      color: #000000;
      border: 1px solid rgba(133, 160, 173, 0.2);
      box-shadow: none;
    }
  }
`
