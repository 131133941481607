import styled from 'styled-components'

export const LabelValueWrapper = styled.div`
  margin-bottom: 2rem;
  width: 100%;

  .label {
    color: #a3b8c2;
    font-family: inherit;
    font-size: 1.2rem;
    font-style: normal;
    font-weight: normal;
    line-height: 1.5rem;
    text-transform: uppercase;
    margin-bottom: 0.5rem;
  }

  .value {
    color: #000000;
    font-family: inherit;
    font-size: 1.8rem;
    font-style: normal;
    font-weight: normal;
    line-height: 2.2rem;
  }

  .copy-box {
    border: 1px solid rgba(163, 184, 194, 0.3);
    box-sizing: border-box;
    border-radius: 3px;
    padding: 0.5rem;
    width: 100%;

    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;

    span {
      margin: 0 1rem;
      font-size: 1.4rem;
      overflow-wrap: anywhere;
      word-break: break-all;
    }

    button {
      width: auto;
      margin: 0;
      padding: 0 1.5rem;
    }
  }
`
