import { useContext, useEffect, useState } from 'react'

import { useHistory } from 'react-router-dom'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'

import { Button } from 'mmfintech-portal-commons'

import ApplicationFeeInfoModal from '../../views/onboarding/elements/ApplicationFeeInfoModal'
import ApplicationFeeDetailsModal from '../../views/onboarding/elements/ApplicationFeeDetailsModal'

import { SuccessImage } from '../../icons'
import { IbanRequestModal } from '../../views/account/IbanRequestModal'
import { SuccessSubtitle, SuccessContainer } from './useOnboarding.styled'

import { actions, paths, useMerchantAccounts, useOnboardingBanner } from 'mmfintech-backend-api'
import { GlobalContext, tr, OtpContext, isValidString, extractCurrencyCode, isValidObject } from 'mmfintech-commons'

import { AccountBalanceOut, MerchantAccountTypeEnum, OnboardingStatusEnum } from 'mmfintech-commons-types'

const errorTimeout = 1000 * 3 // 3 seconds

interface UseOnboardingReturn {
  showError: boolean
  requestIban: () => void
  shouldRequestIban: () => boolean
  payAccountSetupFee: () => void
  shouldPayAccountSetupFee: () => boolean
  startOrContinueOnboarding: (status?: string) => void
}

export const useOnboarding = (): UseOnboardingReturn => {
  const { modalHide, modalShow } = useContext(GlobalContext)
  const { setOtpOnSuccess } = useContext(OtpContext)

  const { merchant, applicationFeeInitiateError } = useSelector(
    ({ user: { merchant }, otp: { applicationFeeInitiateError } }: any) => ({
      merchant,
      applicationFeeInitiateError
    }),
    shallowEqual
  )

  const [showError, setShowError] = useState(true)

  const history = useHistory()
  const dispatch = useDispatch()

  const { accountSetupFee, capabilities, entityType, accountType, onboardingStatus } = merchant || {}
  const { ibanEligible } = capabilities || {}

  const { activeAccounts: accounts } = useMerchantAccounts()

  const startSumSubOnboarding = () => {
    if (isValidString(entityType)) {
      if (accountType === MerchantAccountTypeEnum.PROSPECT && onboardingStatus === OnboardingStatusEnum.NOT_STARTED) {
        history.push(paths.onboarding.selectType())
      }
    } else {
      history.push(paths.onboarding.selectType())
    }
  }

  const { startOrContinueOnboarding } = useOnboardingBanner({
    startOnboarding: startSumSubOnboarding
  })

  const SuccessMessage = () => {
    const handleBack = () => {
      modalHide()
    }

    return (
      <SuccessContainer className='centered' data-test='application-fee-paid-success'>
        <SuccessImage />
        <SuccessSubtitle className='title'>
          {tr('FRONTEND.PAYMENT.SUCCESS', 'Your Application Fee has been paid successfully')}
        </SuccessSubtitle>
        <Button
          type='button'
          color='primary'
          text={tr('FRONTEND.EXCHANGE.BUTTON_OK', 'Ok')}
          onClick={handleBack}
          data-test='button-ok'
        />
      </SuccessContainer>
    )
  }

  const showFeePaymentSuccessModal = () => {
    modalShow({
      options: {
        size: 'auto',
        transparent: true,
        closeOnClickOutside: false,
        closeOnEscape: false
      },
      content: <SuccessMessage />
    })
  }

  const payAccountSetupFee = () => {
    modalHide()
    dispatch(actions.banking.depositCleanup())
    setShowError(true)

    const { amount, currency } = accountSetupFee || {}
    const euroAccountWithFunds = accounts?.find(
      (account: AccountBalanceOut) => extractCurrencyCode(account) === 'EUR' && account.availableBalance >= amount
    )

    if (euroAccountWithFunds) {
      const handleSubmit = () => {
        setOtpOnSuccess(() => () => {
          dispatch(actions.merchant.fetch(showFeePaymentSuccessModal))
        })
        dispatch(actions.banking.applicationFeeInitiate(euroAccountWithFunds?.id))
      }
      modalShow({
        options: {
          size: 'auto',
          transparent: true,
          closeOnClickOutside: false,
          closeOnEscape: false
        },
        content: (
          <ApplicationFeeInfoModal amount={amount} currency={currency} onClose={modalHide} onSubmit={handleSubmit} />
        )
      })
    } else {
      modalShow({
        options: {
          size: 'auto',
          transparent: true,
          closeOnClickOutside: true,
          closeOnEscape: true,
          onClose: () => dispatch(actions.banking.depositCleanup())
        },
        content: <ApplicationFeeDetailsModal amount={amount} currency={currency} />
      })
    }
  }

  const shouldPayAccountSetupFee = () => isValidObject(accountSetupFee)

  const shouldRequestIban = () => ibanEligible

  const requestIban = () => {
    modalShow({
      options: {
        closeOnClickOutside: false,
        size: 'auto'
      },
      content: <IbanRequestModal />
    })
  }

  useEffect(() => {
    if (applicationFeeInitiateError) {
      setTimeout(() => {
        setShowError(false)
      }, errorTimeout)
    }
  }, [applicationFeeInitiateError])

  return {
    showError,
    requestIban,
    shouldRequestIban,
    payAccountSetupFee,
    shouldPayAccountSetupFee,
    startOrContinueOnboarding
  }
}
