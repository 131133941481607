import { CounterpartyStatusEnum, ReportStateEnum } from 'mmfintech-commons-types'
import styled from 'styled-components'

const getStatusColor = (status: ReportStateEnum | CounterpartyStatusEnum) => {
  switch (status) {
    // Counterparties status colors
    case CounterpartyStatusEnum.ACTIVE:
      return '#00B451'
    case CounterpartyStatusEnum.ARCHIVED:
      return '#85A0AD'
    // End Counterparties status labels

    case ReportStateEnum.NEW:
      return '#85A0AD'

    case ReportStateEnum.WAITING_APPROVAL:
    case ReportStateEnum.WAITING_FINANCE_APPROVAL:
    case ReportStateEnum.PARTIALLY_PAID:
      return 'orange'

    case ReportStateEnum.REJECTED:
    case ReportStateEnum.UNPAID:
      return '#FF3131'

    case ReportStateEnum.APPROVED:
      return '#44CC00'
    case ReportStateEnum.EDIT:
      return '#009BCC'
    case ReportStateEnum.COMPLETELY_PAID:
      return '#00B451'

    default:
      return '#000000'
  }
}

const getBackgroundColor = (status: ReportStateEnum | CounterpartyStatusEnum) => {
  switch (status) {
    // Counterparties status colors
    case CounterpartyStatusEnum.ACTIVE:
      return '#E3F2DE'
    case CounterpartyStatusEnum.ARCHIVED:
      return '#EDF0F1'
    // End Counterparties status labels

    case ReportStateEnum.NEW:
      return '#EDF0F1'

    case ReportStateEnum.WAITING_APPROVAL:
    case ReportStateEnum.WAITING_FINANCE_APPROVAL:
    case ReportStateEnum.PARTIALLY_PAID:
      return '#FFFBEB'

    case ReportStateEnum.REJECTED:
    case ReportStateEnum.UNPAID:
      return '#F5E3E3'

    case ReportStateEnum.APPROVED:
      return '#E3F2DE'
    case ReportStateEnum.EDIT:
      return '#E3F8FF'

    case ReportStateEnum.COMPLETELY_PAID:
      return '#E3F2DE'

    default:
      return '#000000'
  }
}

export const ReportStatusBadgeContainer = styled.div<{
  $status: ReportStateEnum | CounterpartyStatusEnum
}>`
  display: inline-flex;
  padding: 0.8rem 1.6rem;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  width: fit-content;

  border-radius: 0.4rem;
  background-color: ${props => getBackgroundColor(props.$status)};
  border: 1px solid ${props => getStatusColor(props.$status)};
  color: ${props => getStatusColor(props.$status)}; // Set the text color
`

export const ReportActionBadgeContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 1.3rem;

  color: black;
  font-size: 1.6rem;
  font-weight: 400;
`
