import { Slide } from 'react-awesome-reveal'
import toast from 'react-hot-toast'

import { isValidArray, usePagination } from 'mmfintech-commons'
import { ErrorDisplay, Pagination, Preloader } from 'mmfintech-portal-commons'
import { useLazySearchVouchersQuery } from 'mmfintech-backend-api'

import { OldVoucherItem } from './OldVoucherItem'

import { OldVoucherWrapper } from './OldVoucher.styled'
import { NoVouchersWrapper } from '../CashVoucher.styled'

import { CashVoucherStatusEnum } from 'mmfintech-commons-types'

const filter = {
  statuses: [
    CashVoucherStatusEnum.REDEMPTION_IN_PROGRESS,
    CashVoucherStatusEnum.REDEEMED,
    CashVoucherStatusEnum.CANCELLED
  ]
}

export const OldVoucher = () => {
  const pagination = usePagination({
    rowsPerPage: 5,
    reload: async (params, onSuccess) => {
      try {
        const response = await fetchData({ ...params, ...filter })
        onSuccess(response)
      } catch (error) {
        toast.error(<ErrorDisplay error={error} hideIcon />)
      }
    }
  })
  const [fetchData, { data: oldVouchers, isLoading }] = useLazySearchVouchersQuery()

  if (isLoading) {
    return <Preloader />
  }
  return (
    <OldVoucherWrapper>
      <Slide direction='left' cascade damping={0.05} triggerOnce duration={500} className='animation-container'>
        {isValidArray(oldVouchers?.content)
          ? oldVouchers.content.map(voucher => {
              return <OldVoucherItem key={voucher.uuid} {...voucher} />
            })
          : null}
      </Slide>
      {oldVouchers?.totalElements > 0 ? (
        <Pagination {...pagination.register()} />
      ) : (
        <NoVouchersWrapper>
          <h2>Awaiting Your Redeemed Vouchers...</h2>
          <p>
            You currently don't have any redeemed or expired vouchers. Once redeemed or expired, vouchers will become
            available to view here.
          </p>
        </NoVouchersWrapper>
      )}
    </OldVoucherWrapper>
  )
}
