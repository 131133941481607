import { useLazyExportVouchersCsvQuery } from 'mmfintech-backend-api'
import fileDownload from 'js-file-download'
import { useEffect } from 'react'
import toast from 'react-hot-toast'
import { ErrorDisplay } from 'mmfintech-portal-commons'
import { extractFilenameFromHeaders } from 'mmfintech-commons'

export const useDownloadVoucherCsv = ({ slipId }) => {
  const [exportCsv, { isLoading, data }] = useLazyExportVouchersCsvQuery()

  const handleExportAsCsv = async () => {
    try {
      await exportCsv({ slipId }).unwrap()
    } catch (err) {
      toast.remove()
      toast.error(<ErrorDisplay error={err} hideIcon />)
    }
  }

  useEffect(() => {
    if (data && 'csvResource' in data) {
      const { contentType, csvData, headers } = data.csvResource
      const content = headers['Content-Disposition']
      const filename = extractFilenameFromHeaders({
        'content-disposition': Array.isArray(content) ? content[0] : content
      })
      fileDownload(atob(csvData), decodeURIComponent(filename).replace(/[\/:]/g, '-'), contentType)
    }
  }, [data])

  return {
    handleExportAsCsv,
    transactionsExportFetching: isLoading
  }
}
