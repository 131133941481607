
import { Button } from 'mmfintech-portal-commons'

import { tr } from 'mmfintech-commons'

import { ConversationConfirmWrapper } from '../styled/conversationModal.styled'

import { ReactComponent as ConversationAttentionIcon } from '../../../../images/icons/conversation-attention-icon.svg'

const ConversationConfirmModal = ({
  handleConfirm,
  hideConfirmation
}: {
  handleConfirm: () => void
  hideConfirmation: () => void
}) => (
  <ConversationConfirmWrapper>
    <div className='inner'>
      <ConversationAttentionIcon />
      <div className='content'>
        <div className='title'>
          {tr('FRONTEND.CONVERSATION.MODAL.CONFIRM.TITLE', 'You are about to submit your message.')}
        </div>
        <div className='subtitle'>
          {tr(
            'FRONTEND.CONVERSATION.MODAL.CONFIRM.SUBTITLE',
            'Please be sure you describe and attach all the needed information'
          )}
        </div>
      </div>
      <Button
        color='primary'
        text={tr('FRONTEND.BUTTONS.CONFIRM', 'Confirm')}
        onClick={handleConfirm}
        data-test='button-confirm'
      />
      <Button
        color='secondary'
        text={tr('FRONTEND.BUTTONS.BACK', 'Back')}
        onClick={hideConfirmation}
        data-test='button-cancel'
      />
    </div>
  </ConversationConfirmWrapper>
)

export default ConversationConfirmModal
