import { useHistory } from 'react-router'

import { CurrencyIcon } from '../../../../components'
import { AccountLabelWrapper } from './AccountLabel.styled'

import { paths, useCurrencies } from 'mmfintech-backend-api'
import { cutString, formatMoney } from 'mmfintech-commons'

export const AccountLabel = ({ account }) => {
  const history = useHistory()
  const { currencyCode, balance, name, id } = account

  const { getCurrencyPrecision } = useCurrencies()

  return (
    <AccountLabelWrapper onClick={() => history.push(paths.banking.transactions.listByAccountId(id))}>
      <div className='left'>
        <CurrencyIcon currency={currencyCode} />
        <div className='name-wrapper'>
          <div className='label'>{currencyCode}</div>
          <div className='name'>{cutString(name, 15)}</div>
        </div>
      </div>
      <div className='balance'>{formatMoney(balance, currencyCode, getCurrencyPrecision(currencyCode))}</div>
    </AccountLabelWrapper>
  )
}
