import styled from 'styled-components'

export const ChartWrapper = styled.div`
  height: 100%;

  canvas {
    width: 100%;
    max-width: 100%;
  }
`
