import styled from 'styled-components'

export const ConfirmationWrapper = styled.div`
  padding: 2rem 2rem 1.6rem;

  display: flex;
  flex-direction: column;
  gap: 3rem;

  @media (min-width: 450px) {
    padding: 4rem 5rem 1.6rem;
  }
`

export const ConfirmationTitle = styled.div`
  text-align: center;

  font-style: normal;
  font-weight: normal;
  font-size: 2.4rem;
  line-height: 3.6rem;
`

export const ConfirmationContent = styled.div`
  text-align: center;
`

export const ConfirmationButtons = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  width: 100%;

  font-size: 1.5rem;
  font-family: inherit;

  .button {
    :first-of-type {
      margin-bottom: 1rem;
    }
  }
`