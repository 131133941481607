import styled from 'styled-components'

export const UploadWrapper = styled.div`
  height: 10rem;

  border: 1px dashed rgba(133, 160, 173, 0.5);
  border-radius: 8px;
  padding: 2.6rem;
  min-height: 13rem;

  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  text-align: center;

  color: #85a0ad;
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 1.7rem;

  span {
    margin-bottom: 0.5rem;
    &.capitalize {
      text-transform: capitalize;
    }
    .blue {
      color: rgba(0, 97, 232, 0.7);
    }
  }

  .button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 10rem;
    height: 3.2rem;

    background: #ff3131;
    border-radius: 4px;
    box-shadow: 0 0 25px rgba(46, 53, 56, 0.1);

    color: #ffffff;
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    span {
      margin: 0;
    }

    &:hover {
      color: #ffffff;
      background: #ff3131;
    }

    &:disabled {
      background: rgba(163, 184, 194, 0.5);
      color: #ffffff;
    }
  }
`

export const UploadFileHint = styled.div`
  color: #85a0ad;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  margin-top: 8px;
`

export const UploadedFileWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  position: relative;

  background: rgba(68, 204, 0, 0.04);
  border: 1px dashed rgba(68, 204, 0, 0.5);
  border-radius: 8px;
  min-height: 13rem;
  padding: 2.6rem;

  .delete-icon-wrapper {
    cursor: pointer;
    position: absolute;
    top: -12px;
    right: -12px;
  }
`

export const UploadedFileContent = styled.div`
  display: inline-flex;
  align-items: center;
  font-size: 1.2rem;
  line-height: 1.5rem;

  .content {
    display: flex;
    flex-flow: column nowrap;

    margin-left: 0.5rem;

    .file-label {
      color: #44cc00;
    }
    .file-name {
      color: #85a0ad;
      white-space: normal;
      word-break: break-all;
      overflow-wrap: anywhere;
    }
  }
`
