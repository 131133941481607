import styled from 'styled-components'

const ActivitiesMenuWrapper = styled.div<{ maxWidth: number; calendarVisible: boolean }>`
  position: absolute;
  top: 5rem;
  right: 0;
  width: 35rem;
  z-index: 99;

  background: #ffffff;
  box-shadow: 0 0 25px rgba(46, 53, 56, 0.1);
  border-radius: 8px;

  padding: ${({ calendarVisible }) => (calendarVisible ? '1.5rem 0' : '1.5rem')};
  overflow-y: hidden;

  .date-picker {
    width: 100%;
    box-shadow: none !important;
    border: none;
    padding: 0;
  }

  .react-datepicker__month-container {
    width: 100%;
  }

  .preloader {
    height: 150px;
  }

  .header {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 5rem;

    @media (max-width: ${({ maxWidth }) => `${maxWidth}px`}) {
      margin-top: 5rem;
    }

    svg {
      cursor: pointer;
    }

    .alternative {
      transform: rotate(180deg);
    }

    .date-container {
      display: flex;
      flex-flow: column nowrap;
      text-align: center;
      cursor: pointer;
      .date {
        color: #000000;
        font-weight: 600;
        font-size: 1.8rem;
        line-height: 2.2rem;
      }
      .month-year {
        color: #808080;
        font-weight: 400;
        font-size: 1.2rem;
        line-height: 1.5rem;
      }
    }
  }

  .activity-list {
    overflow-y: auto;
    max-height: 30rem;
  }

  .no-activities {
    margin: 3rem 0;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    gap: 2rem;

    color: #000000;
    font-weight: 500;
    font-size: 1.4rem;
    line-height: 1.7rem;
  }

  .hidden {
    visibility: hidden;
  }

  @media (max-width: ${({ maxWidth }) => `${maxWidth}px`}) {
    width: 100vw;
    height: 101vh;
    top: -3rem;
    right: -50%;
    margin: auto;
    border-radius: 0;

    .activity-list {
      max-height: none;
      height: 90%;
      padding-bottom: 4rem;
    }
  }
`

const ActivityWrapper = styled.div`
  padding: 1.5rem 0;
  border-bottom: 1px solid #e9eded;
  cursor: pointer;

  &:last-of-type {
    border-bottom: none;
  }

  .inner {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    .left {
      display: flex;
      flex-flow: row nowrap;
      gap: 1.2rem;

      .activity-info {
        display: flex;
        flex-flow: column nowrap;
        gap: 0.5rem;
        .activity-title {
          color: #000000;
          font-weight: 500;
          font-size: 1.4rem;
          line-height: 1.7rem;
        }
        .activity-subtitle {
          color: #808080;
          font-weight: 400;
          font-size: 1.2rem;
          line-height: 1.5rem;
        }
        .timestamp {
          max-width: 5rem;
          text-align: center;
          border: 1px solid #d3d9de;
          border-radius: 4px;
          color: #8595a3;
          font-weight: 400;
          font-size: 8px;
          line-height: 1rem;
          padding: 0.5rem 0;
        }
      }
    }
    .amount {
      font-weight: 400;
      font-size: 1.6rem;
      line-height: 1.9rem;

      color: black;
      white-space: nowrap;
    }
  }
`

export { ActivitiesMenuWrapper, ActivityWrapper }
