import { useState } from 'react'
import { ErrorDisplay } from 'mmfintech-portal-commons'

import { ReactComponent as ReviewStar } from '../../../images/icons/survey-star.svg'

export const SurveyStars = ({ onStarClicked, error }) => {
  const [rating, setRating] = useState(0)

  const isStarFilled = (index: number) => index <= rating

  const handleSetRating = (index: number) => {
    if (rating === index) {
      typeof onStarClicked === 'function' && onStarClicked(rating - 1)
      setRating(currRating => currRating - 1)
    } else {
      typeof onStarClicked === 'function' && onStarClicked(index)
      setRating(index)
    }
  }

  return (
    <div>
      {new Array(5).fill(null).map((_, index) => (
        <ReviewStar
          key={index}
          className='mt-1'
          fill={isStarFilled(index + 1) ? '#FFC132' : 'grey'}
          onClick={() => handleSetRating(index + 1)}
          width={25}
        />
      ))}

      <ErrorDisplay error={errorsMap.hasOwnProperty(error) ? errorsMap[error] : error} />
    </div>
  )
}

const errorsMap = {
  'must be greater than 0': 'Please provide a valid rating.'
}
