import parse from 'html-react-parser'

import { AmountValueWrapper, FeeWrapper } from './AmountValue.styled'

import { tr, formatMoney } from 'mmfintech-commons'
import { useCurrencies } from 'mmfintech-backend-api'

export const AmountValue = ({ amount, currency, fee, feeCurrency }) => {
  const { getCurrencyPrecision } = useCurrencies()

  const formatFee = () =>
    '<span data-test="fee-amount">' + formatMoney(fee, feeCurrency, getCurrencyPrecision(feeCurrency)) + '</span>'

  return (
    <AmountValueWrapper>
      <div className='amountTitle'>{tr('FRONTEND.BANKING.COMMON.AMOUNT_LABEL', 'Amount')}</div>
      <span data-test='amount' className='amount'>
        {formatMoney(amount, currency, getCurrencyPrecision(currency))}
      </span>
      {fee > 0 && (
        <FeeWrapper>
          {parse(tr('FRONTEND.BANKING.COMMON.FEE_INCLUDED', 'Fee %FEE% included').replace('%FEE%', formatFee()))}
        </FeeWrapper>
      )}
    </AmountValueWrapper>
  )
}
