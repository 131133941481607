import styled from 'styled-components'

export const RedeemVoucherWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: center;

  overflow: hidden;
  box-sizing: border-box;

  padding: 0.2rem 1rem 1rem 1rem;

  @media (max-width: 768px) {
    padding: 20px 10px 0 10px;
  }
  justify-content: flex-start;
`
export const StyledRedeemModal = styled.div`
  display: flex;
  flex-direction: column;

  padding: 5rem;
  gap: 3rem;
`
export const RedeemVoucherSuccessContainer = styled.div`
  display: flex;
  flex-direction: column;

  min-width: 70rem;

  border-radius: 0.4rem;
  background: var(--Blixcard-White, #fff);
  box-shadow: 0px 4px 10px 0px rgba(188, 187, 187, 0.25);

  justify-content: center;
  align-items: center;

  padding: 4rem 5rem;
  gap: 3rem;

  font-size: 2.4rem;
  font-weight: 400;

  @media (max-width: 768px) {
    min-width: auto;
  }

  .back-to-vouchers-button {
    max-width: 30rem;
  }
`
