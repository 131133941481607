export const ProfileIcon = ({ ...rest }) => (
  <span {...rest}>
    <svg width='30' height='30' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g opacity='0.6'>
        <circle cx='15' cy='15' r='14.5' stroke='#A7B3BE' />
        <circle cx='15' cy='12' r='3' stroke='#D13737' />
        <path
          d='M21 21C20.6461 20.1393 19.8662 19.3788 18.7814 18.8364C17.6966 18.294 16.3674 18 15 18C13.6326 18 12.3034 18.294 11.2186 18.8364C10.1338 19.3788 9.35391 20.1393 9 21'
          stroke='#D13737'
        />
      </g>
    </svg>
  </span>
)
