
import { Button } from 'mmfintech-portal-commons'

import { tr } from 'mmfintech-commons'

import { ConversationSuccessModalWrapper } from '../styled/conversationModal.styled'

import { ReactComponent as SuccessIcon } from '../../../../images/icons/payment-success-icon.svg'

const ConversationSuccessModal = ({ modalHide }: { modalHide: () => void }) => {
  return (
    <ConversationSuccessModalWrapper>
      <SuccessIcon />
      <div className='modal-title'>
        {tr('FRONTEND.CONVERSATION.MODAL.SUCCESS', 'Your Message Was Successfully Submitted')}
      </div>
      <Button text={tr('FRONTEND.BUTTONS.OK', 'Ok')} onClick={modalHide} />
    </ConversationSuccessModalWrapper>
  )
}

export default ConversationSuccessModal
