// noinspection CssUnusedSymbol,DuplicatedCode

import { useState, useEffect, useCallback, Suspense } from 'react'
import { Route, Switch, Redirect, RouteProps, useHistory, useLocation } from 'react-router-dom'
import { useSelector, shallowEqual, useDispatch } from 'react-redux'
import { CookiesProvider, useCookies } from 'react-cookie'
import styled, { ThemeProvider } from 'styled-components'
import { withTranslation } from 'react-i18next'
import { ErrorBoundary } from 'react-error-boundary'

import { GlobalContextProvider, OtpContext, isValidObject, isValidString, isMobileDevice } from 'mmfintech-commons'
import { actions, paths, useAppInitializer, useAppSelector, useLazyErrorLoggingQuery } from 'mmfintech-backend-api'

import { Preloader } from 'mmfintech-portal-commons'
import {
  ContentWrapper,
  CookieConsent,
  CustomToaster,
  Header,
  ModalDialog,
  Otp,
  OtpLegacy,
  TrackingComponent
} from './components'

import theme from './theme'
import { approverRoutes, loggedOutRoutes, logoutAnd404Routes, routesMain, submitterRoutes } from './routes'
import settings from './settings'

import { ReactComponent as ErrorIcon } from './images/icons/error.svg'
import { CustomerRoleEnum, LoginStatusEnum } from 'mmfintech-commons-types'

function pathExistsInLoggedOutRoutes(path: string): boolean {
  return [...loggedOutRoutes, ...logoutAnd404Routes].some((route: any) => {
    const test = route.check ? new RegExp(route.check).test(path) : false
    return route.path === path || test
  })
}

const AppInner = () => {
  const [cookies, setCookie] = useCookies(['cookie.consent'])
  const { pathname } = useLocation()

  const [cookieConsent, setCookieConsent] = useState(null)
  const [mobileMenuToggle, setMobileMenuToggle] = useState(false)
  const [screenWidth, setScreenWidth] = useState(window.innerWidth)

  const history = useHistory()

  useAppInitializer(settings.languages, (response: any) => {
    if (response?.data?.userStatus === LoginStatusEnum.LOGGED_IN) {
      history.push(paths.dashboard())
    }
  })

  const { show, customerRole } = useSelector(
    ({ user: { selectedLanguage, customerRole }, otp: { show } }: any) => ({
      selectedLanguage,
      customerRole,
      show
    }),
    shallowEqual
  )

  const routes = () => {
    switch (customerRole) {
      case CustomerRoleEnum.SUBMITTER:
        return submitterRoutes
      case CustomerRoleEnum.APPROVER:
        return approverRoutes
      default:
        return routesMain
    }
  }

  const queryChallenge = useAppSelector(state => state.challenge)

  const toggle = useCallback(() => {
    if (window.innerWidth <= 1024) {
      setMobileMenuToggle(!mobileMenuToggle)
      document.getElementsByTagName('body')[0].classList.toggle('locked')
    }
  }, [mobileMenuToggle])

  const dispatch = useDispatch()

  useEffect(() => {
    setCookieConsent(cookies['cookie.consent'])
  }, [cookies])

  const reportWindowSize = () => {
    dispatch(actions.common.setScreenWidth(window.innerWidth))
    setScreenWidth(window.innerWidth)

    if (screenWidth > 1024 && mobileMenuToggle) {
      toggle()
    }

    document.getElementsByTagName('body')[0].classList.remove('locked')
  }

  const [modalContent, setModalContent] = useState(null)
  const [modalOptions, setModalOptions] = useState(null)
  const [modalVisible, setModalVisible] = useState(false)

  const [otpOnError, setOtpOnError] = useState(null)
  const [otpOnSuccess, setOtpOnSuccess] = useState(null)

  const modalHide = () => {
    setModalVisible(false)
    setModalContent(null)
    setModalOptions(null)
  }

  const modalShow = ({ options, content }) => {
    setModalContent(content)
    setModalOptions(options)
    setModalVisible(true)
  }

  const globalContext = { modalHide, modalShow }

  useEffect(() => {
    window.addEventListener('load', reportWindowSize)
    window.addEventListener('resize', reportWindowSize)
    window.addEventListener('orientationchange', reportWindowSize)
    reportWindowSize()

    return () => {
      window.removeEventListener('load', reportWindowSize)
      window.removeEventListener('resize', reportWindowSize)
      window.removeEventListener('orientationchange', reportWindowSize)
    }
    // eslint-disable-next-line
  }, [dispatch, toggle, screenWidth, mobileMenuToggle])

  useEffect(() => {
    if (show || (typeof queryChallenge === 'object' && queryChallenge.hasOwnProperty('challengeId'))) {
      modalShow({
        options: {
          size: 'medium',
          transparent: true,
          closeOnClickOutside: false,
          closeOnEscape: false,
          hideCloseIcon: true,
          onClose: () => {
            dispatch(actions.otp.cleanup())
          }
        },
        content: !queryChallenge?.challengeId ? <OtpLegacy /> : <Otp />
      })
    } else {
      modalVisible && modalHide()
    }
    // eslint-disable-next-line
  }, [show, queryChallenge])

  useEffect(() => {
    window.addEventListener('storage', e => {
      if (e.key === 'cookieTimestamp' && e.newValue !== e.oldValue) {
        history.go(0)
      }
    })
  }, [])

  if (!customerRole && !pathExistsInLoggedOutRoutes(pathname))
    return (
      <PreloaderWrapper>
        <Preloader />
      </PreloaderWrapper>
    )

  return (
    <CookiesProvider>
      <ThemeProvider theme={theme}>
        <GlobalContextProvider context={globalContext}>
          <OtpContext.Provider value={{ otpOnSuccess, setOtpOnSuccess, otpOnError, setOtpOnError }}>
            <TrackingComponent />
            <ContentWrapper>
              <Header />

              <Switch>
                {Array.isArray(routes()) &&
                  routes().map((route, index) => {
                    const { component, exact, path, redirect } = route || {}

                    if (redirect) {
                      return (
                        <Route key={index} exact={exact} path={path}>
                          <Redirect to={{ pathname: redirect }} />
                        </Route>
                      )
                    }

                    if (component) {
                      return <CustomRoute key={index} path={path} exact={exact} component={component} />
                    }

                    return null
                  })}
              </Switch>

              {isValidObject(cookieConsent) || isMobileDevice() ? null : <CookieConsent setCookie={setCookie} />}

              <ModalDialog content={modalContent} options={modalOptions} visible={modalVisible} onClose={modalHide} />
            </ContentWrapper>

            <CustomToaster />
          </OtpContext.Provider>
        </GlobalContextProvider>
      </ThemeProvider>
    </CookiesProvider>
  )
}

function ErrorFallback({ error, resetErrorBoundary }) {
  const isDynamicModuleError = isValidString(error?.message)
    ? error.message.includes('Failed to fetch dynamically imported module')
    : false
  return (
    <AlertWrapper>
      <Alert>
        <ErrorIcon />
        <p>
          {isDynamicModuleError ? 'There might be a new version of the application' : 'An unexpected error occurred'}
        </p>
        <TryAgainButton type='button' onClick={() => resetErrorBoundary(isDynamicModuleError)}>
          {isDynamicModuleError ? 'Reload page' : 'Go back to the home page'}
        </TryAgainButton>
      </Alert>
    </AlertWrapper>
  )
}

const CustomRoute = ({ component: Component, ...rest }: RouteProps) => (
  <Route {...rest} render={props => <Component {...props} />} />
)

const ThisApp = withTranslation()(AppInner)

const App = () => {
  const [logError] = useLazyErrorLoggingQuery()

  return (
    <Suspense
      fallback={
        <PreloaderWrapper>
          <Preloader />
        </PreloaderWrapper>
      }>
      <ErrorBoundary
        FallbackComponent={ErrorFallback}
        onError={(err, componentStack) => {
          return logError({ level: 'ERROR', componentStack, message: err.toString() })
        }}
        onReset={isDynamicModuleError => {
          if (isDynamicModuleError) {
            window.location.reload()
          } else {
            window.location.replace('/')
          }
        }}>
        <ThisApp />
      </ErrorBoundary>
    </Suspense>
  )
}

export default App

const TryAgainButton = styled.button`
  cursor: pointer;
  outline: none;
  width: 100%;
  padding: 1.5rem;

  color: #ffffff;
  background: #ff4c4d;
  box-shadow: 0 5px 17px rgba(255, 165, 159, 0.5);

  border-radius: 5px;
  border: none;
`

const PreloaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100vw;
  height: 100vh;
`

const AlertWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  margin-top: 8rem;
`

const Alert = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;

  max-width: 50rem;
  width: 100%;
  border-radius: 1rem;
  padding: 3rem;

  text-align: center;
  font-size: 16px;

  img {
    width: 66px;
    height: 38px;
  }
  .button {
    width: 100%;
    max-width: 30rem;
  }
`
