import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'

import { StatusBanner } from '../../../components'
import { DataTable, NoElementsMessage, ErrorDisplay, Pagination } from 'mmfintech-portal-commons'
import { IconPreview, TransactionsTableContainer, TransactionsTableWrapper } from './TransactionsTable.styled'

import { tr, usePagination, fetchLastChunk, isValidArray, formatDate, formatMoney } from 'mmfintech-commons'
import {
  actions,
  paths,
  formatTransactionDescription,
  getTransactionStatusName,
  useCurrencies
} from 'mmfintech-backend-api'

export const TransactionsTable = ({ filter, showPagination, forceReload }) => {
  const { transactions, transactionsError, transactionsFetching } = useSelector(
    ({ banking: { transactions, transactionsFetching, transactionsError } }: any) => ({
      transactions,
      transactionsError,
      transactionsFetching
    }),
    shallowEqual
  )

  const history = useHistory()
  const dispatch = useDispatch()

  const { getCurrencyPrecision } = useCurrencies()

  const pagination = usePagination({
    rowsPerPage: 5,
    reload: (params = {}, onSuccess) => {
      const transactionFilter = {
        ...filter,
        ...params,
        sort: 'reqTime-desc,id-desc'
      }
      dispatch(actions.banking.listTransactions(transactionFilter, onSuccess))
    }
  })

  useEffect(() => {
    if (forceReload) {
      pagination.reload()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forceReload])

  return (
    <TransactionsTableWrapper>
      {transactionsFetching ? null : transactionsError ? (
        <ErrorDisplay error={transactionsError} />
      ) : (
        <TransactionsTableContainer>
          {isValidArray(transactions) ? (
            <>
              <DataTable.Table scale2='1000px' skipHover={true}>
                <DataTable.Head>
                  <DataTable.Row>
                    <DataTable.HeadCell>{tr('FRONTEND.TRANSACTIONS.COLUMNS.ID', 'ID')}</DataTable.HeadCell>
                    <DataTable.HeadCell>{tr('FRONTEND.TRANSACTIONS.COLUMNS.DATE', 'Date')}</DataTable.HeadCell>
                    <DataTable.HeadCell>{tr('FRONTEND.TRANSACTIONS.COLUMNS.TYPE', 'Type')}</DataTable.HeadCell>
                    <DataTable.HeadCell>
                      {tr('FRONTEND.TRANSACTIONS.COLUMNS.DESCRIPTION', 'Description')}
                    </DataTable.HeadCell>
                    <DataTable.HeadCell>{tr('FRONTEND.TRANSACTIONS.COLUMNS.STATUS', 'Status')}</DataTable.HeadCell>
                    <DataTable.HeadCell>{tr('FRONTEND.TRANSACTIONS.COLUMNS.AMOUNT', 'Amount')}</DataTable.HeadCell>
                  </DataTable.Row>
                </DataTable.Head>

                <DataTable.Body>
                  {transactions.map((data, index) => {
                    const { id, reqTime, amount, currency, direction, status, trnTypeLocalizationKey } = data

                    return (
                      <DataTable.Row key={index} data-test='table-row'>
                        <DataTable.Cell caption={tr('FRONTEND.TRANSACTIONS.COLUMNS.ID', 'ID')} data-test='id'>
                          {id}
                        </DataTable.Cell>

                        <DataTable.Cell
                          caption={tr('FRONTEND.TRANSACTIONS.COLUMNS.DATE', 'Date')}
                          className='no-wrap'
                          data-test='date'>
                          {formatDate(reqTime)}
                        </DataTable.Cell>

                        <DataTable.Cell caption={tr('FRONTEND.TRANSACTIONS.COLUMNS.TYPE', 'Type')} data-test='type'>
                          {tr(trnTypeLocalizationKey, fetchLastChunk(trnTypeLocalizationKey))}
                        </DataTable.Cell>

                        <DataTable.Cell
                          caption={tr('FRONTEND.TRANSACTIONS.COLUMNS.DESCRIPTION', 'Description')}
                          data-test='description'>
                          {formatTransactionDescription(data)}
                        </DataTable.Cell>

                        <DataTable.Cell
                          caption={tr('FRONTEND.TRANSACTIONS.COLUMNS.STATUS', 'Status')}
                          className={'text-upper status-' + status.toLowerCase()}
                          data-test='status'>
                          <StatusBanner status={getTransactionStatusName(status)} />
                        </DataTable.Cell>

                        <DataTable.Cell caption={tr('FRONTEND.TRANSACTIONS.COLUMNS.AMOUNT', 'Amount')}>
                          <span data-test='amount'>
                            {formatMoney(
                              amount * (direction === 'WITHDRAW' ? -1 : 1) || 0,
                              currency || '',
                              getCurrencyPrecision(currency)
                            )}
                          </span>
                        </DataTable.Cell>

                        <DataTable.Cell className='details-cell no-border' data-test='details-button'>
                          <span
                            className='details-button'
                            onClick={e => {
                              e && e.stopPropagation()
                              history.push(paths.banking.transactions.details(id))
                            }}>
                            <IconPreview title={'Transaction details' /*todo*/} />
                            <span className='details-text'>{'Transaction details' /*todo*/}</span>
                          </span>
                        </DataTable.Cell>
                      </DataTable.Row>
                    )
                  })}
                  {showPagination && (
                    <DataTable.Row>
                      <DataTable.Cell colSpan={6} className='pagination-cell'>
                        {<Pagination className='customers-pagination' {...pagination.register()} />}
                      </DataTable.Cell>
                    </DataTable.Row>
                  )}
                </DataTable.Body>
              </DataTable.Table>
            </>
          ) : (
            <NoElementsMessage
              message={tr('FRONTEND.DASHBOARD.NO_TRANSACTIONS', "You don't have any transactions yet")}
            />
          )}
        </TransactionsTableContainer>
      )}
    </TransactionsTableWrapper>
  )
}
