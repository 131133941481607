import styled from 'styled-components'

export const GraphPaneWrapper = styled.div`
  min-height: 30rem;
  margin-bottom: 4rem;

  display: grid;
  grid-gap: 3rem;
  grid-template-columns: 1fr;

  .graph {
    width: 100%;
    height: 30rem;
  }

  @media (min-width: 1024px) {
    grid-gap: 1rem;
    grid-template-columns: 1fr 30rem;
  }
`

export const AccountsPaneWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  gap: 1rem;

  .your-accounts-text {
    color: #707881;
    font-weight: 400;
    font-size: 1.2rem;
    line-height: 1.5rem;
  }
  .view-all-link {
    text-align: center;
    text-decoration: none;
    color: #707881;
    font-weight: 400;
    font-size: 1.2rem;
    line-height: 1.5rem;
  }
`
