import { useSelector, shallowEqual } from 'react-redux'

import cn from 'classnames'

import { Button, ErrorDisplay, Spinner } from 'mmfintech-portal-commons'
import { Countdown, ResendMessage, VerificationContainer } from './Otp.styled'

import { useOtpQry } from 'mmfintech-backend-api'
import { tr, useValidUntilTimer, formatPhoneNumber, isValidString } from 'mmfintech-commons'

import { LoginStatusEnum } from 'mmfintech-commons-types'

import { ReactComponent as SmartIDImage } from '../../images/smartid-image.svg'

//   const { setOtpOnSuccess } = useContext(OtpContext)
//   setOtpOnSuccess(() => () => {
//     what you want to happen on successful OTP
//   })

export const Otp = () => {
  const { userStatus } = useSelector(
    ({ user: { userStatus } }: any) => ({
      userStatus
    }),
    shallowEqual
  )

  const {
    code,
    error,
    attempts,
    challenge,
    handleCodeChanged,
    handleCancel,
    // handleSubmit,
    handleResend,
    handleReissueSmartId,
    verifyOtpError,
    verifyOtpFetching,
    // resendOtpError,
    resendOtpFetching,
    resendTimer
  } = useOtpQry({ autoSubmit: true })

  const timer = useValidUntilTimer()

  const { sentTo, twoFactorType, smartId } = challenge || {}

  const prepareTitle = () => {
    if (twoFactorType === 'SMS') {
      if (userStatus === LoginStatusEnum.LOGGED_IN) {
        return tr('FRONTEND.VERIFY_OTP.TITLE_VERIFY_SMS', 'SMS Verification')
      }
      return tr('FRONTEND.VERIFY_OTP.TITLE_SMS', 'SMS Confirmation')
    }

    if (smartId) {
      return tr('FRONTEND.VERIFY_OTP.SMART_ID.TITLE', "Verify it's you")
    }

    if (userStatus === LoginStatusEnum.SECOND_FACTOR || userStatus === LoginStatusEnum.LOGGED_IN) {
      return tr('FRONTEND.VERIFY_OTP.TITLE_VERIFY_EMAIL', 'E-mail Verification')
    }

    return tr('FRONTEND.VERIFY_OTP.TITLE_EMAIL', 'E-mail Confirmation')
  }

  return (
    <VerificationContainer data-test='otp-verification-dialog'>
      <div className='title'>{prepareTitle()}</div>
      <div className='subtitle'>
        {smartId ? (
          tr('FRONTEND.VERIFY_OTP.SMART_ID.TEXT', 'For your security we want to make sure it’s really you.')
        ) : (
          <div>
            {tr('FRONTEND.VERIFY_OTP.TARGET_LABEL', "Please, enter the verification code that we've sent to:")}
            <span>{twoFactorType === 'SMS' ? formatPhoneNumber(sentTo) : sentTo}</span>
          </div>
        )}
      </div>

      <ErrorDisplay error={[verifyOtpError]} />

      {verifyOtpFetching || resendOtpFetching ? (
        <Spinner />
      ) : smartId ? (
        <div className='smart-id-container'>
          <SmartIDImage />
          <div className='subtitle'>
            {tr(
              'FRONTEND.VERIFY_OTP.SMART_ID.SUBTITLE',
              'We’ve sent a notification to your device. Please check and confirm to verify it’s you.'
            )}
          </div>
          <ErrorDisplay error={error} />

          {!isValidString(error) ? (
            <ResendMessage>
              {/* <span className='no-underline' onClick={handleReset}>
                {tr('FRONTEND.VERIFY_OTP.SMART_ID.SEND_AGAIN', 'Send Again')}
              </span> */}
              <span className='no-underline' onClick={handleReissueSmartId}>
                {tr('FRONTEND.VERIFY_OTP.SMART_ID.MORE_OPTIONS', 'More options to verify')}
              </span>
            </ResendMessage>
          ) : null}
        </div>
      ) : (
        <>
          <input
            id='otp'
            type='tel'
            className={`${cn({
              complete: code?.length === 4,
              wrong: code?.length === 4 && verifyOtpError != null
            })} input`}
            autoComplete='off'
            onChange={e => handleCodeChanged(e.target.value)}
            value={code}
            maxLength={4}
            autoFocus
            placeholder={tr('FRONTEND.VERIFY_OTP.ENTER_CODE', 'Enter Code')}
            disabled={timer.expired || attempts === 3}
            data-test='tel-input'
          />

          <ResendMessage>
            {attempts < 3 ? (
              <>
                {tr('FRONTEND.VERIFY_OTP.NOT_RECEIVED', "Haven't received it?")}
                <span onClick={handleResend} className={cn({ disabled: !resendTimer.expired })}>
                  {resendTimer.expired
                    ? tr('FRONTEND.VERIFY_OTP.RESEND_CODE', 'Re-send code')
                    : resendTimer.remainingTime}
                </span>
              </>
            ) : (
              tr('FRONTEND.VERIFY_OTP.NO_MORE_ATTEMPTS', 'No more verification attempts.')
            )}
          </ResendMessage>
        </>
      )}

      <div className='buttons-wrap'>
        <Button
          type='button'
          color='secondary'
          text={tr('FRONTEND.BUTTONS.CANCEL', 'Cancel')}
          disabled={verifyOtpFetching || resendOtpFetching}
          onClick={handleCancel}
          data-test='button-cancel'
        />
      </div>

      {attempts > 2 || verifyOtpFetching || resendOtpFetching ? null : (
        <Countdown isExpired={timer.expired} data-test='expire-time'>
          {timer.formattedTime}
        </Countdown>
      )}
    </VerificationContainer>
  )
}
