import styled from 'styled-components'

export const HeaderIconsWrapper = styled.div`
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 1.5rem;
  margin: 0 1.5rem;

  svg {
    cursor: pointer;
  }
`

export const DropDownMenuContainer = styled.div`
  position: absolute;
  top: 4rem;
  width: 20rem;
  z-index: 1001;

  background: #ffffff;
  box-shadow: 0 0 25px rgba(46, 53, 56, 0.1);
  border-radius: 8px;

  padding: 1.5rem;

  &.settings {
    right: 0px;
  }

  .menu-item {
    height: 4.5rem;

    color: #8a99a8;
    font-family: inherit;
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 1.7rem;

    justify-content: flex-start;

    :hover {
      color: #000000;
    }

    &.selector {
      padding: 0 1rem;
    }
  }
`
