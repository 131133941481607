import { Fragment, useEffect } from 'react'

import { Highlighter, NavigationButton, NavigationContainer } from './styles/NavigationSwitch.styled'
import { paths } from 'mmfintech-backend-api'
import { tr } from 'mmfintech-commons'
import { useHistory } from 'react-router-dom'

interface ConfigItem {
  name: string
  displayName: string
  path: string
}

interface IViewNavigation {
  selected: string
  setSelected: (arg: unknown) => void
}

const NavigationSwitch: React.FC<IViewNavigation> = ({ selected, setSelected }) => {
  const history = useHistory()
  const { pathname } = history?.location

  const navigationConfig: ConfigItem[] = [
    {
      displayName: tr('FRONTEND.HEADER.REPORTS', 'Reports'),
      name: 'reports',
      path: paths.expenseManagement.reports()
    },
    {
      displayName: tr('FRONTEND.HEADER.COUNTERPARTY', 'Counterparties'),
      name: 'counterparty',
      path: paths.expenseManagement.counterParty()
    }
    // {
    //   displayName: tr('FRONTEND.EXPENSES.UPLOAD_FILE.DETAILS_VIEW', 'Analytics'),
    //   name: 'test',
    //   path: paths.banking.expenses()
    // }
  ]

  useEffect(() => {
    const endPath = pathname.split('/')[2]
    if (endPath) {
      setSelected(endPath)
    }
  }, [pathname])

  const handleViewChange = view => {
    setSelected(view)
  }

  const onChangeTab = (name: string, path: string) => {
    handleViewChange(name)
    history.push(path)
  }

  return (
    <NavigationContainer navigationCount={navigationConfig?.length}>
      <Highlighter
        activeIndex={navigationConfig.map(e => e.name).indexOf(selected)}
        navigationCount={navigationConfig?.length}
      />
      {navigationConfig.map(({ name, displayName, path }, index) => (
        <Fragment key={index}>
          <NavigationButton isActive={selected === name} onClick={() => onChangeTab(name, path)}>
            <span>{displayName}</span>
          </NavigationButton>
        </Fragment>
      ))}
    </NavigationContainer>
  )
}

export default NavigationSwitch
