import { IJumpingButtonProps } from './JumpingButton.types'
import { JumpingButtonStyled, JumpingButtonWrapper } from './JumpingButton.styled'

export const JumpingButton = ({ icon, label, onClick, ...rest }: IJumpingButtonProps) => (
  <JumpingButtonWrapper>
    <JumpingButtonStyled className='round-square-container button' onClick={onClick} {...rest}>
      {icon}
      {label}
    </JumpingButtonStyled>
  </JumpingButtonWrapper>
)
