import styled from 'styled-components'

export const CryptoPreviewTitle = styled.div`
  width: 100%;
  color: #000000;
  font-family: inherit;
  font-size: 2.4rem;
  font-style: normal;
  font-weight: normal;
  line-height: 2.9rem;
  text-align: center;

  margin-bottom: 4rem;
`

export const CryptoPreviewWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;

  width: 100%;
  max-width: 50rem;
  margin-bottom: 2rem;

  padding: 2rem 5rem;

  background: #ffffff;
  border-radius: 3px;
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.08);

  font-size: 1.4rem;

  .depositPreviewLabel {
    color: black !important;
    text-transform: capitalize !important;
    margin-top: 0.5rem;
    margin-bottom: 0 !important;
  }

  .depositPreviewValue {
    font-weight: 600 !important;
  }
  .code {
    margin: 5rem auto;
  }
  .bordered {
    border: 1px solid #dee3e4;
    border-radius: 15px;
    padding: 15px;
  }

  .control {
    width: 100%;
    margin-bottom: 2rem;

    .label {
      color: black;
      font-family: inherit;
      font-size: 1.2rem;
      font-style: normal;
      font-weight: normal;
      line-height: 1.5rem;
      text-transform: uppercase;
      margin-bottom: 0.5rem;
    }

    .value {
      color: #000000;
      font-family: inherit;
      font-size: 1.8rem;
      font-style: normal;
      font-weight: normal;
      line-height: 2.2rem;
    }

    &.amount {
      .label {
        color: #000000;
      }
      .value {
        font-size: 2.4rem;
        font-weight: 600;
        line-height: 2.9rem;
      }
    }
  }

  .amount {
    color: #000000;
    font-family: inherit;
    font-size: 2.4rem;
    font-style: normal;
    font-weight: 600;
    line-height: 2.9rem;
  }

  .fee {
    color: #a3b8c2;
    font-family: inherit;
    font-size: 1.2rem;
    font-style: italic;
    font-weight: normal;
    line-height: 1.5rem;
  }
`

export const FeeWrapper = styled.div`
  color: #678898;
  font-family: inherit;
  font-size: 1.2rem;
  font-style: italic;
  font-weight: normal;
  margin-top: 0.3rem;
`
