import { isValidElement } from 'react'
import { Slide } from 'react-awesome-reveal'
import InfiniteScroll from 'react-infinite-scroll-component'

import { v4 as uuid } from 'uuid'

import { useWindowSize, isValidArray } from 'mmfintech-commons'
import { Preloader } from 'mmfintech-portal-commons'

import { DefaultNoElementsComponent } from './NoElementsComponent'
import { useInfiniteScroll } from '../../hooks'
import { CashVoucherResponse } from 'mmfintech-commons-types'

export const CustomInfiniteScroll = ({
  rtkQueryHook,
  filter,
  noElementsComponent = null,
  Item,
  itemAction,
  endpointName
}) => {
  const { combinedData, readMore, isFetching, isLoading, totalElements } = useInfiniteScroll(
    rtkQueryHook,
    endpointName,
    { filter }
  )
  const [width] = useWindowSize()
  const isMobile = width <= 768

  if (isLoading) {
    return <Preloader />
  }
  const noElementsC = isValidElement(noElementsComponent) ? noElementsComponent : DefaultNoElementsComponent

  return (
    <>
      {isValidArray(combinedData) ? (
        <InfiniteScroll
          dataLength={combinedData.length}
          next={readMore}
          hasMore={combinedData.length < totalElements}
          loader={isFetching ? <h4>Loading...</h4> : null}
          scrollThreshold={0.8}
          style={{ width: '100%' }}>
          <Slide
            direction={isMobile ? 'up' : 'left'}
            cascade
            damping={0.05}
            triggerOnce
            duration={500}
            className='full-width'>
            {combinedData.map((item: CashVoucherResponse) => {
              return <Item key={item.uuid || uuid()} {...item} action={itemAction} />
            })}
          </Slide>
        </InfiniteScroll>
      ) : null}
      {totalElements > 0 && !isLoading ? null : noElementsC}
    </>
  )
}
