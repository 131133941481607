const errorOptions = {
  iconTheme: { primary: 'red', secondary: 'white' },
  style: {
    color: '#ffffff',
    fontSize: '1.6rem',
    lineHeight: '120%',
    background: '#141414',
    borderRadius: '4rem'
  }
}

const successOptions = {
  iconTheme: { primary: 'green', secondary: 'white' },
  style: {
    color: '#ffffff', //'rgba(255, 255, 255, 0.4)',
    fontSize: '1.6rem',
    lineHeight: '120%',
    background: '#141414',
    borderRadius: '4rem'
  }
}

export const toastOptions = {
  success: successOptions,

  error: errorOptions
}
