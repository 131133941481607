import { useContext, useEffect } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'

import { NewsModal } from './NewsModal'
import { SurveyModal } from './SurveyModal'
import { WelcomeModal } from './WelcomeModal'
import { AdminDashboard } from './AdminDashboard'
import { StaffDashboard } from './StaffDashboard'
import { InvoicePreview } from '../../components'
import { DashboardWrapper } from './Dashboard.styled'

import { actions } from 'mmfintech-backend-api'
import { GlobalContext, isValidArray } from 'mmfintech-commons'

import { CustomerRoleEnum, LoginStatusEnum } from 'mmfintech-commons-types'

export const Dashboard = () => {
  const { modalHide, modalShow } = useContext(GlobalContext)

  const { availableNews, customerRole, invoice, invoicePreview, initialEmailVerification, userStatus } = useSelector(
    ({
      user: { availableNews, customerRole, initialEmailVerification, userStatus },
      invoices: { invoice, invoicePreview }
    }: any) => ({
      availableNews,
      customerRole,
      invoice,
      invoicePreview,
      initialEmailVerification,
      userStatus
    }),
    shallowEqual
  )

  const dispatch = useDispatch()

  useEffect(() => {
    if (userStatus === LoginStatusEnum.LOGGED_IN) {
      dispatch(actions.merchant.getNews())
      dispatch(actions.kyb.getSumsubOnboardingStatus())
    }
    // eslint-disable-next-line
  }, [userStatus])

  useEffect(() => {
    if (invoice && invoicePreview) {
      modalShow({
        options: { size: 'extra-large', hideCloseIcon: true },
        content: <InvoicePreview status={invoice.status} content={invoicePreview} onClose={modalHide} />
      })
      dispatch(actions.invoice.resetPreview())
    }
    // eslint-disable-next-line
  }, [invoice, invoicePreview])

  useEffect(() => {
    if (initialEmailVerification) {
      modalShow({
        options: {
          size: 'medium',
          transparent: true,
          closeOnClickOutside: false,
          closeOnEscape: false
        },
        content: <WelcomeModal />
      })
      dispatch(actions.auth.verifyEmailCleanup())
    } else if (isValidArray(availableNews)) {
      modalShow({
        options: {
          size: 'custom',
          transparent: true,
          closeOnClickOutside: true,
          closeOnEscape: true,
          customSize: 48
        },
        content: <NewsModal />
      })
    }
    // eslint-disable-next-line
  }, [initialEmailVerification, availableNews])

  return (
    <DashboardWrapper>
      {customerRole === CustomerRoleEnum.OWNER || customerRole === CustomerRoleEnum.ADMINISTRATOR ? (
        <AdminDashboard />
      ) : customerRole === CustomerRoleEnum.STAFF ? (
        <StaffDashboard />
      ) : null}

      <SurveyModal />
    </DashboardWrapper>
  )
}
