import styled from 'styled-components'

export const AccountButtonStyled = styled.div`
  background: #ffffff;
  border: 1px solid rgba(133, 160, 173, 0.2);
  border-radius: 4px;
  box-shadow: 0 0 25px rgba(46, 53, 56, 0.1);

  width: 100%;
  height: 4.6rem;
  padding-left: 1rem;
  padding-right: 2rem;
  cursor: pointer;

  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;

  color: #85a0ad;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;

  &.error {
    border-color: rgba(255, 49, 49, 0.7);
  }
  &.static {
    cursor: default;
  }

  &.no-shadow {
    box-shadow: none;
  }

  &.no-border {
    border: none;
    padding-left: 0;
  }
  &.is-selected {
    background-color: rgba(68, 204, 0, 0.1);
    border: 1px solid rgba(68, 204, 0, 0.5);
  }

  .account-info {
    display: flex;
    align-items: center;

    .icon {
      width: 26px;
      height: 26px;
      margin-right: 1rem;
    }

    .content {
      display: flex;
      flex-flow: column nowrap;

      .balance {
        color: #000000;
        font-size: 1.2rem;
        font-style: normal;
        font-weight: 500;
        line-height: 120%;

        b {
          font-weight: 700;
        }
      }
    }
  }

  .no-account {
    color: #000000;
    font-size: 1.4rem;
    font-weight: 500;
    line-height: 1.7rem;
    margin-left: 2rem;
  }
`
