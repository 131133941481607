import parse from 'html-react-parser'
import DOMPurify from 'dompurify'

import { IInvoicePreviewProps } from './InvoicePreview.types'
import {
  CloseIcon,
  CloseIconRow,
  CloseIconWrap,
  InvoicePreviewContent,
  InvoicePreviewWrapper
} from './InvoicePreview.styled'

import { tr } from 'mmfintech-commons'
import { getInvoiceStatusName } from 'mmfintech-backend-api'

export const InvoicePreview = ({ status, content, onClose }: IInvoicePreviewProps) => (
  <InvoicePreviewWrapper data-test='invoice-preview-modal'>
    <CloseIconRow className={'status-' + status?.toLowerCase()}>
      <span>
        <span className='status-title'>{tr('FRONTEND.INVOICES.PREVIEW.STATUS', 'Invoice Status')}: </span>
        <span>{getInvoiceStatusName(status)}</span>
      </span>

      <CloseIconWrap>
        <CloseIcon onClick={onClose} />
      </CloseIconWrap>
    </CloseIconRow>

    <InvoicePreviewContent>{parse(DOMPurify.sanitize(content))}</InvoicePreviewContent>
  </InvoicePreviewWrapper>
)
