import styled from 'styled-components'

export const AccountCreateWrapper = styled.div`
  width: 92vw;
  max-width: 50rem;
  position: relative;

  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0 0 25px rgba(46, 53, 56, 0.1);

  padding: 3rem 2rem;

  @media (min-width: 940px) {
    padding: 4rem 5rem;
  }
`

export const AccountCreateTitle = styled.div`
  color: #000000;
  font-family: inherit;
  font-size: 2.4rem;
  font-style: normal;
  font-weight: 400;
  line-height: 2.9rem;

  text-align: center;
`

export const AccountCreateForm = styled.form`
  margin: 0;

  .row {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    gap: 2rem;
  }
`
export const AccountCreateButtons = styled.div`
  margin-top: 3rem;

  display: flex;
  flex-flow: column nowrap;

  button:first-of-type {
    margin-bottom: 1rem;
  }
`
