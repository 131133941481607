import { useMemo } from 'react'

import {
  VouchersPreparedAmountsContainer,
  VouchersPreparedAmountsItem,
  VouchersPreparedAmountsTitle,
  VouchersPreparedAmountsWrapper
} from './BuyVouchersTab.styled'

import { formatMoney } from 'mmfintech-commons'
import { useVoucherAmounts } from 'mmfintech-backend-api'

export const VouchersPreparedAmounts = ({ currentAmount, setAmount, currency, selectedType }) => {
  const { bulkAmounts, singleAmounts } = useVoucherAmounts()

  const amounts = useMemo(() => {
    const res = selectedType === 'single' ? singleAmounts : bulkAmounts
    return Array.isArray(res) ? res : []
  }, [selectedType, bulkAmounts, singleAmounts])

  return (
    <VouchersPreparedAmountsWrapper>
      <VouchersPreparedAmountsTitle>Select Voucher Value</VouchersPreparedAmountsTitle>
      <VouchersPreparedAmountsContainer>
        {amounts.map(amount => (
          <VouchersPreparedAmountsItem
            key={amount}
            $isActive={currentAmount === amount}
            onClick={() => setAmount(amount)}
            data-test={`buy-voucher-${amount}`}>
            {formatMoney(amount, currency, 0)}
          </VouchersPreparedAmountsItem>
        ))}
      </VouchersPreparedAmountsContainer>
    </VouchersPreparedAmountsWrapper>
  )
}
