import styled from 'styled-components'

const CounterpartyModalWrapper = styled.div`
  background: white;
  border-radius: 0.8rem;
  min-width: 500px;
  padding: 4rem 5rem;

  @media (max-width: 530px) {
    min-width: auto;
  }

  form {
    margin-top: 5.4rem;
    display: flex;
    flex-direction: column;
    gap: 8.4rem;

    .input-wrapper {
      margin: 0;
    }

    .button-container {
      width: 100%;
      display: grid;
      gap: 0.8rem;
    }
  }
`

const CounterpartyModalHeader = styled.h3`
  margin: 0;
  font-size: 2.4rem;
  font-weight: 400;
  margin-bottom: 5.4rem;
`

export { CounterpartyModalWrapper, CounterpartyModalHeader }
