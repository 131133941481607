import React, { useContext, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import { GlobalContext, isValidObject } from 'mmfintech-commons'

import { ConfirmationModal } from '../../../../components'
import { paths, useWalletTransferPreviewQry } from 'mmfintech-backend-api'

export const PerfectMoneyPreview: React.FC<{ trnId: number }> = ({ trnId }) => {
  const { modalShow } = useContext(GlobalContext)
  const history = useHistory()

  const { transferPreview } = useWalletTransferPreviewQry()
  const { perfectMoneyFormData, processingAmount, processingCurrency } = transferPreview || {}

  const handleSubmit = () => {
    if (perfectMoneyFormData) {
      document.getElementById('perfect-money-form-submit').click()
    }
  }

  useEffect(() => {
    modalShow({
      content: (
        <ConfirmationModal
          question="By clicking proceed you will be redirected to Perfect Money's website to complete the deposit."
          confirmCaption='Proceed'
          hasCancel={false}
          onConfirm={handleSubmit}
        />
      ),
      options: {
        onClose: () => {
          history.push(paths.dashboard())
        }
      }
    })
  }, [])

  return isValidObject(perfectMoneyFormData) ? (
    <form action={perfectMoneyFormData.submitUrl} method='POST' hidden>
      <input type='hidden' name='PAYEE_ACCOUNT' value={perfectMoneyFormData.payeeAccount} />
      <input type='hidden' name='PAYEE_NAME' value='The Kingdom Bank' />
      <input type='hidden' name='PAYMENT_AMOUNT' value={processingAmount} />
      <input type='hidden' name='PAYMENT_UNITS' value={processingCurrency} />
      <input type='hidden' name='PAYMENT_URL' value={perfectMoneyFormData.successUrl} />
      <input type='hidden' name='NOPAYMENT_URL' value={perfectMoneyFormData.failUrl} />
      <input type='hidden' name='STATUS_URL' value={perfectMoneyFormData.statusUrl} />
      <input type='hidden' name='PAYMENT_ID' value={trnId} />
      <input type='hidden' name='PAYMENT_URL_METHOD' value={perfectMoneyFormData.paymentUrlMethod} />
      <input type='hidden' name='BAGGAGE_FIELDS' value='' />
      <button id='perfect-money-form-submit' type='submit'>
        Submit
      </button>
    </form>
  ) : null
}
