import styled from 'styled-components'

export const TableCustomizeWrapper = styled.div`
  width: 92vw;
  max-width: 50rem;

  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0 0 25px rgba(46, 53, 56, 0.1);

  padding: 3rem 2rem;
  @media (min-width: 640px) {
    padding: 4rem 5rem;
  }

  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
`

export const TableCustomizeTitle = styled.div`
  color: #000000;
  font-size: 2.4rem;
  font-style: normal;
  font-weight: 400;
  line-height: 2.9rem;
  margin-bottom: 2rem;

  width: 100%;
  text-align: center;
`

export const TableCustomizeButtons = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  margin-top: 4rem;

  .button {
    font-size: 1.6rem;
    font-weight: 600;
    line-height: 1.9rem;
    margin-top: 1rem;
    :first-child {
      margin-top: 0;
    }
  }
`
