import styled from 'styled-components'
import { CommonPageTitle } from '../../components'

export const DashboardWrapper = styled.div`
  grid-area: main-content;
  width: 92vw;
  max-width: 140rem;
  padding: 3rem 0;

  height: calc(100vh - 9rem);
  margin: 0 auto;

  .no-data {
    color: #a3b8c2;
  }

  @media (min-width: 767px) {
    padding: 5rem 0 3rem 0;
  }

  @media (max-width: 1400px) {
    height: auto;
    flex-flow: column nowrap;
  }
`

export const DashboardTitle = styled(CommonPageTitle)`
  margin-bottom: 1.5rem;
`
