import { Preloader } from 'mmfintech-portal-commons'
import { AccountsOverviewRow } from './AccountsOverviewRow'
import { AccountOverviewListWrapper } from './AccountsOverview.styled'

import { useUserPermissions } from '../../../hooks'
import { useMerchantAccounts } from 'mmfintech-backend-api'

export const AccountsOverview = () => {
  const { isLoading, permissions } = useUserPermissions()
  const { activeAccounts, accountsFetching } = useMerchantAccounts()

  if (accountsFetching || isLoading) {
    return <Preloader />
  }

  const accounts = activeAccounts?.map((a: any) => {
    const permission = permissions?.find((perm: any) => a?.id === perm?.accountId)
    return { ...a, permission: permission?.accessLevel }
  })

  return (
    <AccountOverviewListWrapper>
      {Array.isArray(accounts) &&
        accounts.map((account: any, i: number) => <AccountsOverviewRow account={account} key={i} />)}
    </AccountOverviewListWrapper>
  )
}
