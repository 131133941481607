import { Dispatch, SetStateAction, memo, useEffect, useState } from 'react'

import { AnimatedBg, StyledTab, StyledTabs, TabsWrapper } from './TopTabs.styled'

const spring = {
  type: 'slide',
  damping: 10,
  stiffness: 100
}

const padding = '0.4rem'

interface ITab {
  title: string | React.ReactElement
  name: string
  activeIndex?: number
  handleClick?: () => void
}

interface ITabs {
  config: ITab[]
  setTab: Dispatch<SetStateAction<string>>
  currentTab: string
}

const Tab = memo<ITab>(({ title, handleClick, name }) => {
  return (
    <span  data-test={`${name}-voucher-tab`} style={{ flex: 1 }} onClick={handleClick}>
      <StyledTab>{title}</StyledTab>
    </span>
  )
})

export const Tabs = memo<ITabs>(({ config, setTab, currentTab }) => {
  const getActiveIndex = () => {
    const foundIndex = config.findIndex(tab => tab.name === currentTab)
    return foundIndex !== -1 ? foundIndex : 0
  }
  const [activeIndex, setActiveIndex] = useState(getActiveIndex())
  useEffect(() => {
    setActiveIndex(getActiveIndex())
  }, [currentTab])

  return (
    <TabsWrapper>
      <StyledTabs>
        <AnimatedBg
          style={{ width: `calc(100% / ${config.length} - ${padding})` }}
          transition={spring}
          animate={{ x: `calc(${activeIndex} * 100%)` }}
        />
        {config.map((item, index) => {
          const handleClick = () => {
            setTab(item.name)
          }
          return (
            <Tab
              title={item.title}
              name={item.name}
              key={`${index}-${item.title}`}
              activeIndex={activeIndex}
              handleClick={handleClick}
            />
          )
        })}
      </StyledTabs>
    </TabsWrapper>
  )
})
