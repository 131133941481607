import React, { useEffect, useRef } from 'react'
import { NavLink } from 'react-router-dom'

import { IDropDownMenuProps } from './Header.types'
import { DropDownMenuIcon, DropDownMenuWrapper, DropDownMenuContainer } from './Header.styled'

import { tr } from 'mmfintech-commons'
import { paths } from 'mmfintech-backend-api'

import { ReactComponent as ArrowDownIcon } from '../../images/icons/arrow-down-white.svg'
import { ReactComponent as IBIcon } from '../../images/icons/ib-icon.svg'
import { ReactComponent as ReferralIcon } from '../../images/icons/referral-icon.svg'

export const InviteAndEarnMenu = ({ isOpen, setIsOpen }: IDropDownMenuProps) => {
  const menuRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    const checkIfClickedOutside = (e: any) => {
      if (menuRef.current && !menuRef.current.contains(e.target)) {
        setIsOpen(false)
      }
    }
    document.addEventListener('mousedown', checkIfClickedOutside)
    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside)
    }
    // eslint-disable-next-line
  }, [isOpen])

  const hideMenu = () => isOpen && setIsOpen(false)

  const handleOnClick = (e: React.SyntheticEvent) => {
    e.stopPropagation()
    hideMenu()
  }

  return (
    <DropDownMenuWrapper ref={menuRef}>
      <DropDownMenuIcon className={`arrow-down ${isOpen ? 'active' : ''}`}>
        <ArrowDownIcon />
      </DropDownMenuIcon>

      {isOpen && (
        <DropDownMenuContainer>
          <NavLink to={paths.referralProgram()} className='menu-item selector'>
            <div onClick={handleOnClick}>
              <ReferralIcon />
              {tr('FRONTEND.REFERRAL.PROGRAM.TITLE', 'Referral Program')}
            </div>
          </NavLink>
          <NavLink to={paths.introducingBrokers()} className='menu-item selector'>
            <div onClick={handleOnClick}>
              <IBIcon />
              {tr('FRONTEND.HEADER.INTRODUCING_BROKER', 'Introducing Broker')}
            </div>
          </NavLink>
        </DropDownMenuContainer>
      )}
    </DropDownMenuWrapper>
  )
}
