import { useEffect } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'

import { fixFloat, useFormValues } from 'mmfintech-commons'
import { actions, isOwnerOrAdministrator } from 'mmfintech-backend-api'

// const READ_PERMISSION = 'READER'
const WRITE_PERMISSION = 'WRITER'

export const useAddUser = ({ handleReload }: { handleReload: (userId?: number) => void }) => {
  const { invitedCustomerError } = useSelector(
    ({ customer: { invitedCustomerError } }: any) => ({ invitedCustomerError }),
    shallowEqual
  )

  const formValues = useFormValues({
    firstName: { required: true, validation: 'name' },
    lastName: { required: true, validation: 'name' },
    email: { required: true, validation: 'email' },
    customerRole: { required: true }
  })

  const dispatch = useDispatch()

  const handleCreate = () => {
    dispatch(
      actions.customer.inviteCustomer(
        { ...formValues.prepare(), monthlyVolume: fixFloat(formValues.getValue('monthlyVolume')) },
        handleReload
      )
    )
  }

  const handleEdit = (userId: number) => {
    dispatch(
      actions.customer.updateInvitedCustomer(
        userId,
        { ...formValues.prepare(), monthlyVolume: fixFloat(formValues.getValue('monthlyVolume')) },
        () => handleReload(userId)
      )
    )
  }

  useEffect(() => {
    return () => {
      dispatch(actions.customer.createUserCleanup())
    }
  }, [dispatch])

  useEffect(() => {
    if (invitedCustomerError) {
      formValues.handleErrors(invitedCustomerError)
    }
    // eslint-disable-next-line
  }, [invitedCustomerError])

  return {
    formValues,
    handleCreate,
    handleEdit
  }
}

export const useUserPermissions = (props?: { customerId?: number }) => {
  const dispatch = useDispatch()
  const { customerId } = props || {}
  const { permissionsInvitedCustomersFetching, permissionsInvitedCustomersError, permissionsInvitedCustomer, userId } =
    useSelector(
      ({
        customer: { permissionsInvitedCustomersFetching, permissionsInvitedCustomersError, permissionsInvitedCustomer },
        user: { userId }
      }: any) => ({
        permissionsInvitedCustomersFetching,
        permissionsInvitedCustomersError,
        permissionsInvitedCustomer,
        userId
      })
    )

  useEffect(() => {
    if (!permissionsInvitedCustomersFetching) {
      if (customerId) {
        dispatch(actions.customer.invitedCustomersPermissions(customerId))
      } else if (userId) {
        dispatch(actions.customer.myCustomersPermissions(userId))
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerId, userId])

  return {
    isLoading: permissionsInvitedCustomersFetching,
    error: permissionsInvitedCustomersError,
    permissions: permissionsInvitedCustomer
  }
}

export const useGetHints = () => {
  const dispatch = useDispatch()
  const { getHintsFetching, hints, getHintsError } = useSelector(
    ({ user: { getHintsFetching, hints, getHintsError } }: any) => ({
      getHintsFetching,
      hints,
      getHintsError
    })
  )

  if (!hints && !getHintsFetching && !getHintsError) {
    dispatch(actions.merchant.getUserHints())
  }

  return {
    isLoading: getHintsFetching,
    error: getHintsError,
    hints
  }
}

export const useHasWritePermissions = (accountId?: string) => {
  const { isLoading, error, permissions } = useUserPermissions()
  const { customerRole } = useSelector(({ user: { customerRole } }: any) => ({
    customerRole
  }))

  if (isOwnerOrAdministrator(customerRole)) return true
  if (isLoading || error) return false

  if (accountId) {
    const permission = permissions?.find(
      (perm: any) => perm.accountId === accountId && perm.accessLevel === WRITE_PERMISSION
    )
    return !!permission
  }

  const permission = permissions?.find((perm: any) => perm.accessLevel === WRITE_PERMISSION)
  return !!permission
}
