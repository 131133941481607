import styled from 'styled-components'

import { DragAndDropContainer } from './expenseList.styled'

export const ExpenseModalContainer = styled.div`
  display: flex;
  flex-direction: column;

  gap: 2.5rem;
  border-radius: 0.8rem;

  .reimbursement-checkbox {
    margin-bottom: 2.4rem;
  }

  .drop-target {
    display: flex;
    flex: 1;
    height: auto;
  }

  @media (max-width: 768px) {
    min-width: auto;
  }

  .react-datepicker-popper {
    z-index: 3;
  }
`

export const ExpenseModalBody = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`
export const ExpenseModalLeftSection = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2.4rem;

  .reimbursement-checkbox {
    .checkbox-label {
      font-size: 1.4rem;
      color: black;
      margin-left: 0.8rem;
      font-weight: 400;
    }
  }

  .input-wrapper {
    margin-bottom: 1.2rem;
    width: 100%;

    label {
      color: black;
      font-size: 1.4rem;
      line-height: 120%;
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  }
`

export const ExpenseModalRightSection = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #edf0f1;
  padding: 2.4rem;
`

export const ExpenseModalHeader = styled.h3`
  margin: 0;
  font-size: 2.4rem;
  font-weight: 400;
  margin-bottom: 4.6rem;
`

export const ExpenseModalFooter = styled.div`
  margin-top: 4.6rem;
  width: 100%;
  grid-template-columns: 1fr 1fr;
  gap: 1.5rem;

  &.footer-desktop {
    display: grid;

    @media (max-width: 768px) {
      display: none;
    }
  }

  &.footer-mobile {
    display: none;

    @media (max-width: 768px) {
      display: grid;
    }
  }

  button {
    span {
      font-weight: 400;
    }
  }

  .button-secondary {
    span {
      color: black;
    }
  }
`

export const GroupInputs = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1.5rem;

  .input-wrapper {
    max-width: 16.5rem;

    &.calendar-input {
      .has-icon {
        padding-left: 0;
      }
    }
  }
`
export const DragAndDropContainerInModal = styled(DragAndDropContainer)<{ $isFileLoaded?: boolean; $isError: boolean }>`
  grid-area: pdf-viewer;

  flex-direction: column;
  flex: 1;
  padding: ${({ $isFileLoaded }) => ($isFileLoaded ? '0' : '6rem')};
  gap: 0;

  font-size: 1.6rem;
  font-weight: 400;
  color: black;
  background-color: white;
  text-align: center;
  border: none;

  border: ${({ $isError }) => {
    return $isError ? '1px dashed red' : '1px dashed #85A0AD'
  }};

  .drop-receipt-text {
    font-size: 1.2rem;
    font-weight: 400;
    color: #707881;
  }
`
export const DragAndDropImageContainer = styled.div`
  margin-bottom: 2.5rem;
`
