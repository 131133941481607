import styled from 'styled-components'

export const HamburgerMenuWrapper = styled.nav<{ open: boolean; maxMobileWidth: number }>`
  padding: 5rem;

  position: absolute;
  top: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  z-index: 99;

  background: #ffffff;
  transform: ${({ open }) => (open ? 'translateX(0)' : 'translateX(100%)')};

  .items-container {
    display: flex;
    flex-direction: column;
    align-items: center;

    a,
    div.title {
      font-weight: 400;
      font-size: 1.8rem;
      line-height: 2.2rem;
      height: auto;
    }
  }

  .action-button {
    border-top: 1px solid #d3d9de;
    padding: 2rem 0;
  }
`
