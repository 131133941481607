import toast from 'react-hot-toast'

import { copyTextToClipboard, tr } from 'mmfintech-commons'

import { VoucherDetailText, VoucherDetailWrapper } from './VoucherDetails.styled'

export const VoucherDetails = ({ icon, label, data = '----', allowCopy = false }) => {
  const handleCopyClick = () => {
    if (allowCopy) {
      copyTextToClipboard(data)
      toast.remove()
      toast.success(tr('FRONTEND.BANKING.COMMON.COPY_SUCCESS', 'Value copied to clipboard.'))
    }
  }
  return (
    <VoucherDetailWrapper>
      {icon}
      <VoucherDetailText>
        <span className='details-label'>{label}</span>
        <span
          className='details-value'
          style={{ cursor: allowCopy ? 'pointer' : 'default' }}
          onClick={handleCopyClick}
          data-test={`voucher-${label}`}>
          {data}
        </span>
      </VoucherDetailText>
    </VoucherDetailWrapper>
  )
}
