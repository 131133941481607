import toast from 'react-hot-toast'

import { ExpenseManagementReport } from 'mmfintech-commons-types'
import { useDeleteReportMutation } from 'mmfintech-backend-api'
import { Button, ErrorDisplay } from 'mmfintech-portal-commons'
import { tr } from 'mmfintech-commons'

import { ReportModalInner } from './modals.styled'
import { DeleteModalHeader, DeleteModalWrapper } from './DeleteReportModal.styled'

interface IDeleteReport {
  report: ExpenseManagementReport
  onClose: () => void
  reloadPagination: () => void
}

export const DeleteReportModal = ({ report, onClose, reloadPagination }: IDeleteReport) => {
  const [deleteReport, { error, isLoading }] = useDeleteReportMutation()

  const onDeleteReport = async (e: React.MouseEvent) => {
    e.stopPropagation()

    try {
      await deleteReport({ id: report.id }).unwrap()
      toast.success('Report deleted successfully')
      reloadPagination()
      onClose()
    } catch (error) {}
  }

  const onClickClose = (e: React.MouseEvent) => {
    e.stopPropagation()
    onClose()
  }

  return (
    <DeleteModalWrapper onClick={e => e.stopPropagation()} onMouseDown={e => e.stopPropagation()}>
      <DeleteModalHeader>Delete Report</DeleteModalHeader>
      <ReportModalInner>
        <div className='confirmation'>
          Are you sure you want to delete report: <p>{report.name}</p>
        </div>
      </ReportModalInner>
      <ErrorDisplay error={error} />
      <div className='buttons-container'>
        <Button
          color='primary'
          text={tr('FRONTEND.BUTTONS.DELETE', 'Delete')}
          onClick={onDeleteReport}
          data-test='delete-report-button'
          loading={isLoading}
        />
        <Button
          color='secondary'
          text={tr('FRONTEND.BUTTONS.CANCEL', 'Cancel')}
          onClick={onClickClose}
          data-test='close-delete-report-button'
          loading={isLoading}
        />
      </div>
    </DeleteModalWrapper>
  )
}
