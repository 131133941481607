import styled from 'styled-components'

export const CalloutWrapper = styled.div`
  .callout::before {
    position: absolute;
    width: 0;
    height: 0;
    content: ' ';
  }

  .top::before {
    bottom: 100%;
    border-left: 1rem solid transparent;
    border-right: 1rem solid transparent;
    border-bottom: 1rem solid #f7e5bf;
  }

  .bottom::before {
    top: 100%;
    border-left: 1rem solid transparent;
    border-right: 1rem solid transparent;
    border-top: 1rem solid #f7e5bf;
  }

  .left::before {
    right: 100%;

    border-top: 1rem solid transparent;
    border-bottom: 1rem solid transparent;
    border-right: 1rem solid #f7e5bf;
  }

  .right::before {
    left: 100%;
    border-top: 1rem solid transparent;
    border-bottom: 1rem solid transparent;
    border-left: 1rem solid #f7e5bf;
  }

  .align-horizontal-left::before {
    left: 5%;
  }

  .align-horizontal-right::before {
    right: 5%;
  }

  .align-horizontal-center::before {
    left: 50%;
  }

  .align-vertical-center::before {
    top: 50%;
    margin-top: -1rem;
  }

  .align-vertical-top::before {
    top: 5%;
  }

  .align-vertical-bottom::before {
    bottom: 5%;
    margin-top: -1rem;
  }
`
