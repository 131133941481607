import styled from 'styled-components'

export const InputAmountWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  width: 100%;
`

export const InputAmountError = styled.div`
  color: #ff3131;
  font-family: inherit;
  font-size: 1.2rem;
  font-style: italic;
  font-weight: normal;
  line-height: 1.5rem;

  margin-top: 1rem;
`

export const InputAmountLabel = styled.label`
  color: #000000;
  font-family: inherit;
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.9rem;
`

export const InputAmountElement = styled.input`
  width: 100%;
  height: 4.5rem;
  border: none;
  border-bottom: 1px solid rgba(163, 184, 194, 0.3);
  outline: none;

  font-family: inherit;
  font-size: 2.4rem;
  font-style: normal;
  font-weight: 400;
  line-height: 2.9rem;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &[type='number'] {
    -moz-appearance: textfield;
  }

  &::-webkit-input-placeholder {
    font-size: 2.4rem;
    font-weight: 400;
    line-height: 2.9rem;
  }
  &:-ms-input-placeholder {
    font-size: 2.4rem;
    font-weight: 400;
    line-height: 2.9rem;
  }
  &::placeholder {
    font-size: 2.4rem;
    font-weight: 400;
    line-height: 2.9rem;
  }
`

export const InputAmountStatic = styled.div`
  width: 100%;
  height: 4.5rem;
  border-bottom: 1px solid rgba(163, 184, 194, 0.3);

  display: flex;
  align-items: center;

  font-family: inherit;
  font-size: 2.4rem;
  font-style: normal;
  font-weight: 400;
  line-height: 2.9rem;
`

export const InputAmountLimits = styled.div`
  color: #85a0ad;
  font-family: inherit;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;
  margin-top: 1rem;
`
