import styled from 'styled-components'

export const StyledCashVoucherForm = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 52rem;

  background-color: #fff;
  box-shadow: 0px 4px 10px 0px rgba(188, 187, 187, 0.25);

  border-radius: 4px;

  padding: 3rem 5rem;
  gap: 3rem;
  box-sizing: border-box;

  .input-wrapper {
    margin-bottom: 0;
  }
`
export const StyledCashVoucherTitle = styled.h2`
  margin: 0;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: center;
`
