import { useHistory } from 'react-router-dom'

import { useLazySearchVouchersQuery, paths, api } from 'mmfintech-backend-api'

import { CustomInfiniteScroll } from '../../../components'
import { ActiveVouchersItem } from './SingleActiveVoucher'
import { ActiveVouchersWrapper } from './SingleVoucher.styled'
import { NoVouchersComponent } from '../voucherCommons/NoVoucherComponent'

import { CashVoucherResponse, CashVoucherStatusEnum } from 'mmfintech-commons-types'

const filter = {
  statuses: [CashVoucherStatusEnum.ACTIVE]
}

export const ActiveVouchersList = () => {
  const history = useHistory()
  const onRedeemClicked = (voucher: CashVoucherResponse) => {
    history.push(paths.more.cashVoucher('redeem'), { voucher })
  }
  return (
    <ActiveVouchersWrapper>
      <CustomInfiniteScroll
        filter={filter}
        Item={ActiveVouchersItem}
        rtkQueryHook={useLazySearchVouchersQuery}
        // @ts-ignore - Unfortunately when using injectEndpoints in the backend-api types are not available
        endpointName={api.endpoints.searchVouchers.name}
        itemAction={onRedeemClicked}
        noElementsComponent={<NoVouchersComponent />}
      />
    </ActiveVouchersWrapper>
  )
}
