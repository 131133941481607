import styled from 'styled-components'

export const AccountLabelWrapper = styled.div`
  box-shadow: 0 0 25px rgba(46, 53, 56, 0.1);
  border-radius: 8px;

  max-height: 5.4rem;
  cursor: pointer;

  padding: 1.2rem;

  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;

  .left {
    display: flex;
    flex-flow: row nowrap;
    gap: 1rem;
    .name-wrapper {
      display: flex;
      flex-flow: column nowrap;
      .label {
        max-width: 2.6rem;
        text-align: center;
        padding: 2px;
        background: #b9bec6;
        border-radius: 2px;
        color: #ffffff;
        font-weight: 600;
        font-size: 8px;
        line-height: 1rem;
      }
      .name {
        font-weight: 400;
        font-size: 1.2rem;
        line-height: 1.5rem;
        color: #000000;

        line-break: anywhere;
      }
    }
  }
  .balance {
    font-weight: 600;
    font-size: 1.4rem;
    line-height: 1.7rem;
    white-space: nowrap;

    color: #000000;
  }
`
