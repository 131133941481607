const NewsBullet = ({ index, active, handleTransition }) => (
  <span
    aria-label={`Go to news ${index + 1}`}
    role='button'
    tabIndex={0}
    className={`bullet ${active ? 'active' : ''}`}
    onClick={() => handleTransition(index)}
  />
)

export default NewsBullet
