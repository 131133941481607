import styled from 'styled-components'

export const ExpenseListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2.6rem;
`
export const ExpenseListHeader = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  background: white;
  box-shadow: 0px 4px 25px 0px #2e35381a;

  padding: 1.6rem;
  border-radius: 0.6rem;

  .view {
    display: flex;
    justify-content: center;
    color: #707881;
    align-items: center;
    gap: 8px;

    span {
      font-weight: 400;
      font-size: 12px;
    }
  }

  h6 {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-size: 2rem;
    font-weight: 600;
    color: black;
    margin: 0;
  }
`
export const ExpenseListItem = styled.div`
  display: flex;
  width: 100%;
  border-radius: 0.4rem;
  border: 1px solid #e3eaed;
  background: white;
  padding: 1.6rem;
  color: black;
  justify-content: space-between;
  align-items: center;

  cursor: pointer;

  &:hover {
    background: #f3f5f6;

    svg {
      display: flex;
    }
  }

  @media (max-width: 768px) {
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    gap: 2rem;
    overflow: hidden;

    &:hover {
      background: #f3f5f6;
    }
  }
`

export const ExpenseListItemColumn = styled.div<{ position?: 'left' | 'right' }>`
  font-size: 1.4rem;
  display: flex;
  align-items: center;
  min-width: 0;
  flex: 1;
  max-width: 100%;

  &.title {
    font-weight: 600;
  }
  .row {
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  .label {
    font-size: 1.2rem;
    color: #707881;
  }

  .value {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  justify-content: ${({ position }) => {
    if (position === 'left') return 'flex-start'
    if (position === 'right') return 'flex-end'
  }};
`
export const ExpenseListItemGroup = styled.div<{ position: 'left' | 'center' | 'right' }>`
  display: flex;
  flex: 1;
  min-width: 0;
  max-width: 100%;
  gap: 2rem;
  align-items: center;

  @media (max-width: 768px) {
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
  }

  @media (max-width: 768px) {
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
  }

  justify-content: ${({ position }) => {
    if (position === 'left') return 'flex-start'
    if (position === 'center') return 'space-between'
    if (position === 'right') return 'flex-end'
  }};
`
export const ExpenseListCount = styled.span`
  display: flex;

  min-width: 2rem;
  min-height: 2rem;
  box-sizing: border-box;
  aspect-ratio: 1 / 1;

  align-items: center;
  justify-content: center;

  background-color: rgb(133, 160, 173);
  border-radius: 50%;

  color: #fff;
  font-size: 1.4rem;
  font-weight: 400;
`
export const DragAndDropContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  gap: 0.75rem;
  cursor: pointer;

  font-size: 1.4rem;
  font-weight: 400;
  background-color: #edf0f1;
  color: #707881;

  .new-expense-text {
    color: black;
  }

  border-radius: 0.6rem;
  border: 1px dashed #85a0ad;
  min-height: 8rem;
  padding: 2rem;

  &:hover {
    svg {
      opacity: 0.8;
    }
  }
`

export const NoReportsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`
