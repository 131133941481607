import styled from 'styled-components'

export const DashboardContainer = styled.div``

export const DashboardHeader = styled.div`
  display: flex;
  flex-flow: column-reverse nowrap;
  justify-content: space-between;
  align-items: center;

  margin-bottom: 2.3rem;

  @media (min-width: 767px) {
    gap: 8rem;
    flex-flow: row nowrap;
  }
`

export const DashboardBanners = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column-reverse nowrap;
  gap: 1rem;

  @media (min-width: 767px) {
    flex-flow: column nowrap;
    align-items: flex-end;
  }
`
