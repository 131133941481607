import { useState, useEffect } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { Link, NavLink, useLocation } from 'react-router-dom'

import cn from 'classnames'

import { HeaderIcons } from '../HeaderIcons'
import { SavingsMenu } from './SavingsMenu'
import { HamburgerMenu } from '../HamburgerMenu'
import { InviteAndEarnMenu } from './InviteAndEarnMenu'
import { Hamburger, HamburgerIcon, HeaderContainer, HeaderInnerContainer, HeaderWrapper } from './Header.styled'

import settings from '../../settings'

import { isMerchantProspect, isOwnerOrAdministrator, paths } from 'mmfintech-backend-api'
import { tr, useWindowSize, isMobileDevice } from 'mmfintech-commons'

import { LoginStatusEnum } from 'mmfintech-commons-types'

import LogoImage from '../../images/icons/kingdom-bank-logo-black.svg'

const MAX_MOBILE_WIDTH = 1176

export const Header = () => {
  const { userStatus, merchant, customerRole } = useSelector(
    ({ user: { userStatus, merchant, customerRole } }: any) => ({
      userStatus,
      merchant,
      customerRole
    }),
    shallowEqual
  )

  const [opened, setOpened] = useState(false)
  const [visible, setVisible] = useState(false)

  const [inviteAndEarnOpen, setInviteAndEarnOpen] = useState(false)
  const [savingsOpen, setSavingsOpen] = useState(false)

  const toggleSavingsMenu = () => setSavingsOpen(prevState => !prevState)

  const location = useLocation()

  const { apiEnabled, capabilities } = merchant || {}
  const { allowReferrals, forexAccountRequestStatus, enableCashVouchers } = capabilities || {}

  const [width] = useWindowSize()

  const isForexEnabled = () => forexAccountRequestStatus !== 'NOT_REQUESTED' && isOwnerOrAdministrator(customerRole)
  const isHamburgerVisible = () => width < MAX_MOBILE_WIDTH
  const isLoggedIn = () => userStatus === LoginStatusEnum.LOGGED_IN
  const isLoggedInAndNotSubmitterOrApprover = () =>
    userStatus === LoginStatusEnum.LOGGED_IN && !(customerRole === 'SUBMITTER' || customerRole === 'APPROVER')
  const isDevelopersMenuVisible = () => !!apiEnabled && isOwnerOrAdministrator(customerRole)
  const isReferralVisible = () => isOwnerOrAdministrator(customerRole) && allowReferrals
  const isStakingMenuVisible = () => !isMerchantProspect(merchant) && isOwnerOrAdministrator(customerRole)

  const hideMenu = () => opened && setOpened(false)

  const BrandLogoImage = () => <img src={LogoImage} alt='Logo' />

  const BrandLogo = () => {
    return isMobileDevice() ? (
      <BrandLogoImage />
    ) : isLoggedIn() ? (
      <Link className='brand-logo' to={paths.dashboard()}>
        <BrandLogoImage />
      </Link>
    ) : (
      <a className='brand-logo' href={settings.landingPageUrl}>
        <BrandLogoImage />
      </a>
    )
  }

  const MainMenuItems = () => (
    <>
      <NavLink activeClassName='active-tab' to={paths.dashboard()} onClick={hideMenu}>
        {tr('FRONTEND.HEADER.OVERVIEW', 'Overview')}
      </NavLink>
      <NavLink activeClassName='active-tab' to={paths.banking.accounts.list()} onClick={hideMenu}>
        {tr('FRONTEND.HEADER.ACCOUNTS', 'Accounts')}
      </NavLink>
      <NavLink activeClassName='active-tab' to={paths.banking.transactions.list()} onClick={hideMenu}>
        {tr('FRONTEND.HEADER.TRANSACTIONS', 'Transactions')}
      </NavLink>
      <NavLink
        activeClassName='active-tab'
        to={paths.expenseManagement.expenseManagementDashboard()}
        onClick={hideMenu}>
        {tr('FRONTEND.HEADER.EXPENSES', 'Expenses')}
      </NavLink>
      <NavLink activeClassName='active-tab' to={paths.invoices.list()} onClick={hideMenu}>
        {tr('FRONTEND.HEADER.INVOICES', 'Invoices')}
      </NavLink>
      {isReferralVisible() ? (
        width >= MAX_MOBILE_WIDTH ? (
          <div
            className={`invite-and-earn 
            ${
              location.pathname === paths.referralProgram() || location.pathname === paths.introducingBrokers()
                ? 'active-tab'
                : ''
            }`}
            onClick={() => {
              setInviteAndEarnOpen(prevState => !prevState)
            }}>
            <span className='active-tab'>{tr('FRONTEND.HEADER.REFERRAL', 'Invite & Earn')}</span>
            <InviteAndEarnMenu isOpen={inviteAndEarnOpen} setIsOpen={setInviteAndEarnOpen} />
          </div>
        ) : (
          <>
            <NavLink to={paths.referralProgram()} onClick={hideMenu} activeClassName='active-tab'>
              {tr('FRONTEND.REFERRAL.PROGRAM.TITLE', 'Referral Program')}
            </NavLink>
            <NavLink to={paths.introducingBrokers()} onClick={hideMenu} activeClassName='active-tab'>
              {tr('FRONTEND.HEADER.INTRODUCING_BROKER', 'Introducing Broker')}
            </NavLink>
          </>
        )
      ) : null}
      {isForexEnabled() && (
        <NavLink activeClassName='active-tab' to={paths.forex()} onClick={hideMenu}>
          {tr('FRONTEND.HEADER.FOREX', 'Forex')}
        </NavLink>
      )}
      {isStakingMenuVisible() ? (
        width >= MAX_MOBILE_WIDTH ? (
          <div
            className={cn('savings-menu', {
              'active-tab': location.pathname === paths.more.crypto() || location.pathname === paths.more.fiat()
            })}
            onClick={toggleSavingsMenu}>
            <span className='active-tab'>{tr('FRONTEND.HEADER.EARN', 'More')}</span>
            <SavingsMenu isOpen={savingsOpen} setIsOpen={setSavingsOpen} />
          </div>
        ) : (
          <>
            <NavLink activeClassName='active-tab' to={paths.more.crypto()} onClick={() => hideMenu()}>
              {tr('FRONTEND.HEADER.CRYPTO_SAVINGS', 'Crypto Savings')}
            </NavLink>
            <NavLink activeClassName='active-tab' to={paths.more.fiat()} onClick={() => hideMenu()}>
              {tr('FRONTEND.HEADER.FIAT_INVESTMENT', 'Money Savings')}
            </NavLink>
            {enableCashVouchers ? (
              <NavLink activeClassName='active-tab' to={paths.more.cashVoucher('buy')} onClick={() => hideMenu()}>
                {tr('FRONTEND.HEADER.KINGDOM_CASH', 'Kingdom Cash')}
              </NavLink>
            ) : null}
          </>
        )
      ) : null}
    </>
  )

  const UserMenuItems = ({ className, onClick }) => (
    <>
      <NavLink to={paths.security()} onClick={onClick} className={className}>
        {tr('FRONTEND.HEADER.SECURITY', 'Security')}
      </NavLink>
      <NavLink to={paths.expenseManagement.paymentInfo()} onClick={onClick} className={className}>
        {tr('FRONTEND.HEADER.PAYMENT', 'Payment Details')}
      </NavLink>
      {isOwnerOrAdministrator(customerRole) && (
        <NavLink to={paths.userManagement()} onClick={onClick} className={className}>
          {tr('FRONTEND.HEADER.USERS', 'Users')}
        </NavLink>
      )}
      <a
        href={settings.customerServiceUrl}
        rel='noopener noreferrer'
        target='_blank'
        onClick={onClick}
        className={className}>
        {tr('FRONTEND.HEADER.CUSTOMER_SERVICE', 'Customer Service')}
      </a>
      {isDevelopersMenuVisible() && (
        <NavLink to={paths.developer()} onClick={hideMenu} className={className}>
          {tr('FRONTEND.HEADER.DEVELOPERS', 'Developers')}
        </NavLink>
      )}
    </>
  )

  useEffect(() => {
    width > MAX_MOBILE_WIDTH && hideMenu()
    // eslint-disable-next-line
  }, [width])

  useEffect(() => {
    const pathname = location.pathname
    // noinspection DuplicatedCode
    const state =
      pathname !== paths.login() &&
      pathname !== paths.checkEmail() &&
      !Object.values(paths.expos).includes(pathname) &&
      pathname !== '/money-transfer_FC' &&
      pathname !== '/b2b-payment_FC' &&
      pathname !== '/business-account_FC' &&
      pathname !== '/best-offshore-bank-account_FC' &&
      pathname !== '/crypto-friendly-banks_FC' &&
      pathname !== '/money-transfer_TC' &&
      pathname !== '/b2b-payment_TC' &&
      pathname !== '/business-account_TC' &&
      pathname !== '/best-offshore-bank-account_TC' &&
      pathname !== '/crypto-friendly-banks_TC' &&
      pathname !== '/multicurrency-account_FC' &&
      pathname !== '/multicurrency-account_TC' &&
      pathname !== paths.passwordReset() &&
      pathname !== paths.forgotPassword() &&
      pathname !== paths.changePasswordEmail() &&
      pathname !== paths.handlePasswordReset() &&
      pathname !== paths.handlePasswordResetInvited() &&
      pathname !== '/overseas-bank-account_FC' &&
      pathname !== '/overseas-bank-account_TC' &&
      pathname !== '/international-bank-account_FC' &&
      pathname !== '/international-bank-account_TC' &&
      pathname.substring(0, 7) !== '/signup' &&
      pathname.substring(0, 15) !== '/transfer-funds' &&
      pathname.substring(0, 9) !== '/invoice/'

    if (state !== visible) {
      setVisible(state)
    }
    // eslint-disable-next-line
  }, [location])

  useEffect(() => {
    const body = document.getElementsByTagName('body').item(0)
    if (body) {
      if (opened) {
        body.classList.add('menu-opened')
      } else {
        body.classList.remove('menu-opened')
      }
    }
    // eslint-disable-next-line
  }, [opened])

  return visible ? (
    <HeaderWrapper visible={visible}>
      {opened && <div className='overlay' onClick={hideMenu} />}

      <HeaderContainer maxMobileWidth={MAX_MOBILE_WIDTH}>
        <BrandLogo />

        {!isHamburgerVisible() && isLoggedInAndNotSubmitterOrApprover() && (
          <HeaderInnerContainer>
            <MainMenuItems />
          </HeaderInnerContainer>
        )}

        {isHamburgerVisible() ? (
          <HeaderInnerContainer>
            <HeaderIcons
              UserMenu={UserMenuItems}
              maxWidth={MAX_MOBILE_WIDTH}
              isLoggedInAndNotSubmitterOrApprover={isLoggedInAndNotSubmitterOrApprover}
            />
            <Hamburger>
              {isLoggedInAndNotSubmitterOrApprover() && (
                <>
                  <HamburgerIcon onClick={() => setOpened(prevState => !prevState)}>
                    <span className='menu-icon' />
                  </HamburgerIcon>
                  <HamburgerMenu
                    opened={opened}
                    maxWidth={MAX_MOBILE_WIDTH}
                    hideMenu={() => setOpened(false)}
                    Items={MainMenuItems}
                  />
                </>
              )}
            </Hamburger>
          </HeaderInnerContainer>
        ) : (
          <HeaderInnerContainer>
            {isLoggedIn() && (
              <HeaderIcons
                UserMenu={UserMenuItems}
                maxWidth={MAX_MOBILE_WIDTH}
                isLoggedInAndNotSubmitterOrApprover={isLoggedInAndNotSubmitterOrApprover}
              />
            )}
          </HeaderInnerContainer>
        )}
      </HeaderContainer>
    </HeaderWrapper>
  ) : null
}
