import { useContext } from 'react'

import { Button } from 'mmfintech-portal-commons'
import { GlobalContext, tr } from 'mmfintech-commons'
import { IConfirmationModalProps } from './ConfirmationModal.types'
import {
  ConfirmationButtons,
  ConfirmationContent,
  ConfirmationTitle,
  ConfirmationWrapper
} from './ConfirmationModal.styled'

export const ConfirmationModal = ({
  title,
  question,
  onConfirm,
  className,
  confirmCaption,
  buttonColor = 'secondary',
  hasCancel = true
}: IConfirmationModalProps) => {
  const { modalHide } = useContext(GlobalContext)
  const handleConfirm = async () => {
    modalHide()
    onConfirm && onConfirm()
  }

  return (
    <div data-test='confirmation-dialog-modal' className={className}>
      <ConfirmationWrapper>
        <ConfirmationTitle className='title'>
          {title || tr('FRONTEND.MODAL.CONFIRMATION', 'Confirmation')}
        </ConfirmationTitle>
        <ConfirmationContent>{question}</ConfirmationContent>
        <ConfirmationButtons>
          <Button
            type='button'
            color='primary'
            onClick={handleConfirm}
            data-test='button-confirm'
            text={confirmCaption || tr('FRONTEND.BUTTONS.CONFIRM', 'Confirm')}
          />
          {hasCancel && (
            <Button
              type='button'
              color={buttonColor}
              onClick={modalHide}
              data-test='button-cancel'
              text={tr('FRONTEND.BUTTONS.CANCEL', 'Cancel')}
            />
          )}
        </ConfirmationButtons>
      </ConfirmationWrapper>
    </div>
  )
}
