import { ReactChildren } from 'react'

import { NoReportsWrapper } from './NoReportsWrapper'

import { ReactComponent as NoReportImage } from '../../images/expenses/no-reports-icon.svg'

type TNoReportsComponentProps = {
  title?: string
  children?: ReactChildren
}

export const NoReportsComponent = ({ title, children }: TNoReportsComponentProps) => {
  return (
    <tbody>
      <tr>
        <td colSpan={10}>
          <NoReportsWrapper>
            <>
              <NoReportImage />
              {title && <p>{title}</p>}
              {children && { children }}
            </>
          </NoReportsWrapper>
        </td>
      </tr>
    </tbody>
  )
}
