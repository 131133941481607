import styled from 'styled-components'

export const StatusBannerWrapper = styled.span`
  border-radius: 4px;

  font-size: 1.2rem;
  font-weight: 400;
  line-height: 1.5rem;
  text-transform: capitalize;
  text-align: center;

  max-width: 12rem;
  padding: 0.7rem 1rem;

  &.pending,
  &.scheduled,
  &.in-progress,
  &.initial,
  &.buy {
    background: #fffbeb;
    border: 1px solid #ffce32;
    color: #ffce32;
  }

  &.processed,
  &.onboarded,
  &.active,
  &.paid,
  &.sell {
    background: #e3f2de;
    border: 1px solid #44cc00;
    color: #44cc00;
  }

  &.failed,
  &.cancelled,
  &.rejected,
  &.pending-cancellation {
    background: #f5e3e3;
    border: 1px solid #ff3131;
    color: #ff3131;
  }

  &.not-verified,
  &.not-started,
  &.under-review {
    background: #edf0f1;
    border: 1px solid #a7b3be;
    color: #85a0ad;
  }
`
