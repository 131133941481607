import { TailSpin } from 'react-loader-spinner'
import { useTheme } from 'styled-components'

export const TableLoader = () => {
  const theme = useTheme()

  return (
    <TailSpin
      // @ts-ignore
      color={theme?.loader.loaderColor ?? '#828282'}
      // @ts-ignore
      height={theme?.loader.loaderSize ?? 75}
      // @ts-ignore
      width={theme?.loader.loaderSize ?? 75}
      wrapperClass='loader'
    />
  )
}
