import { RadioButtonGroup } from 'mmfintech-portal-commons'
import styled from 'styled-components'

export const BuyVoucherAmountLabelWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;

  .tooltip {
    max-width: 50rem;
    text-align: justify;
  }

  @media (max-width: 480px) {
    .tooltip {
      max-width: 30rem;
    }
  }
`
export const VouchersPreparedAmountsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  gap: 1rem;
`

export const VouchersPreparedAmountsTitle = styled.div`
  font-size: 14px;
  font-weight: 400;
`

export const VouchersPreparedAmountsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
`

export const VouchersPreparedAmountsItem = styled.div<{ $isActive: boolean }>`
  display: flex;

  font-size: 14px;
  font-weight: 400;

  cursor: pointer;

  padding: 1rem;
  border-radius: 4px;
  border: 1px solid rgba(133, 160, 173, 0.3);

  justify-content: center;
  align-items: center;

  ${({ $isActive }) => {
    return $isActive ? `color: #fff; background-color: #000` : null
  }}
`
export const BuyVoucherInstructionsWrapper = styled.div`
  display: flex;
  flex-direction: column;

  gap: 1.5rem;

  padding-bottom: 3rem;

  border-bottom: 1px dashed #ccc;
`

export const BuyVoucherInstructionsItem = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;
  gap: 0.5rem;
`

export const BuyRadioGroup = styled(RadioButtonGroup)`
  width: 100%;
  gap: 2rem;
  margin: 0;
  .radio-button {
    flex-flow: row-reverse nowrap;
    border-radius: 4px;
    border-width: 1px;
    border-style: solid;
    border-color: rgba(133, 160, 173, 0.3);
    padding: 1.2rem;

    img {
      margin-right: 0;
      margin-left: 1.6rem;
    }

    &.selected {
      border-color: rgba(167, 178, 190, 1);
      box-shadow: 0px 5px 10px 0px rgba(46, 53, 56, 0.05);
    }
  }
`
