import { useHistory } from 'react-router-dom'
import { shallowEqual, useSelector } from 'react-redux'

import { Button } from 'mmfintech-portal-commons'
import { LabelValue } from '../../../../components'
import {
  AmountContainerWrapper,
  BankwireDetailsWrapper,
  ButtonsContainer,
  DetailsWrapper,
  PreviewContainer,
  PreviewWrapper,
  TitleWrapper,
  WarningMessageWrapper
} from './BankwirePreview.styled'

import { tr, isValidObject, formatMoney, isNotEmptyString } from 'mmfintech-commons'
import { paths } from 'mmfintech-backend-api'
import { useEffect, useState } from 'react'
import { BankTransferWarning1 } from './BankTransferWarning1'
import { BankTransferWarning2 } from './BankTransferWarning2'
import { PaymentMethodEnum } from 'mmfintech-commons-types'

export const BankwirePreview = ({ result, isAmountRequired = true }) => {
  const { merchant } = useSelector(
    ({ user: { merchant } }: any) => ({
      merchant
    }),
    shallowEqual
  )

  const [step, setStep] = useState<number>(1)

  const history = useHistory()

  const { accountSetupFee, entityType } = merchant || {}

  const { processingAmount, processingCurrency } = result || {}
  const amount = processingCurrency ? processingAmount : accountSetupFee?.amount
  const currency = processingCurrency ? processingCurrency : accountSetupFee?.currency

  const hideBankwire = entityType === 'INDIVIDUAL' && isValidObject(accountSetupFee)

  useEffect(() => {
    return () => {
      setStep(1)
    }
  }, [])

  if (step === 1) {
    return (
      <PreviewWrapper className='no-header'>
        <BankTransferWarning1 onContinue={() => setStep(2)} />
      </PreviewWrapper>
    )
  }

  if (step === 2) {
    return (
      <PreviewWrapper className='no-header'>
        <BankTransferWarning2 onContinue={() => setStep(3)} />
      </PreviewWrapper>
    )
  }

  return (
    <PreviewWrapper>
      {isAmountRequired && (
        <AmountContainerWrapper>
          {tr('FRONTEND.DEPOSIT.PREVIEW.AMOUNT', 'Amount')}
          <span className='amount' data-test='deposit-amount'>
            {formatMoney(amount, currency)}
          </span>
        </AmountContainerWrapper>
      )}

      <PreviewContainer className='double'>{!hideBankwire && <BankwireDetails details={result} />}</PreviewContainer>

      <ButtonsContainer>
        <Button
          type='button'
          color='primary'
          onClick={() => history.push(paths.dashboard())}
          text={tr('FRONTEND.DEPOSIT.PREVIEW.BUTTON_BACK', 'Back to Overview')}
          data-test='button-back'
        />
      </ButtonsContainer>
    </PreviewWrapper>
  )
}

const BankwireDetails = ({ details }) => {
  const {
    accountNumber,
    accountOwner,
    bankCode,
    bankName,
    bankAddress,
    bicSwift,
    branchCode,
    iban,
    recipientAddress,
    paymentType,
    reference
  } = details || {}

  return (
    <DetailsWrapper>
      <BankwireDetailsWrapper>
        <TitleWrapper>
          {paymentType
            ? paymentType === PaymentMethodEnum.BANKWIRE
              ? tr('FRONTEND.DEPOSIT.PREVIEW.SEPA_BANKWIRE_TITLE', 'SEPA Bankwire')
              : tr('FRONTEND.DEPOSIT.PREVIEW.SWIFT_BANKWIRE_TITLE', 'SWIFT Bankwire')
            : tr('FRONTEND.DEPOSIT.PREVIEW.TITLE', 'Pay with Bankwire')}
        </TitleWrapper>

        <LabelValue
          label={tr('FRONTEND.DEPOSIT.PREVIEW.BANK_NAME', 'Bank Name')}
          value={bankName}
          dataTest='bank-name'
        />
        <LabelValue
          label={tr('FRONTEND.DEPOSIT.PREVIEW.BANK_ADDRESS', 'Bank Address')}
          value={bankAddress}
          dataTest='bank-address'
        />

        <LabelValue label={tr('FRONTEND.DEPOSIT.PREVIEW.IBAN', 'IBAN')} value={iban} dataTest='iban' />
        <LabelValue
          label={tr('FRONTEND.DEPOSIT.PREVIEW.BIC_SWIFT', 'BIC/SWIFT')}
          value={bicSwift}
          dataTest='bic-swift'
        />

        <LabelValue
          label={tr('FRONTEND.DEPOSIT.PREVIEW.BANK_NUMBER', 'Bank Number')}
          value={bankCode}
          dataTest='bank-number'
        />
        <LabelValue
          label={tr('FRONTEND.DEPOSIT.PREVIEW.BRANCH_CODE', 'Branch code')}
          value={branchCode}
          dataTest='branch-code'
        />
        <LabelValue
          label={tr('FRONTEND.DEPOSIT.PREVIEW.ACCOUNT_NUMBER', 'Account Number')}
          value={accountNumber}
          dataTest='account-number'
        />
        <LabelValue
          label={tr('FRONTEND.DEPOSIT.PREVIEW.ACCOUNT_OWNER', 'Account Owner')}
          value={accountOwner}
          dataTest='account-owner'
        />
        <LabelValue
          label={tr('FRONTEND.DEPOSIT.PREVIEW.OWNER_ADDRESS', 'Owner Address')}
          value={recipientAddress}
          dataTest='recipient-address'
        />

        <LabelValue
          label={tr('FRONTEND.DEPOSIT.PREVIEW.REFERENCE', 'Reference')}
          value={reference}
          dataTest='reference'
        />

        {isNotEmptyString(reference) && (
          <WarningMessageWrapper>
            {tr(
              'FRONTEND.DEPOSIT.PREVIEW.WARNING1',
              'Please pay attention to enter your unique reference to your transaction. If the reference is blank or wrong, the transaction will be declined and you will need to enter the correct reference and try again.'
            )}
          </WarningMessageWrapper>
        )}
      </BankwireDetailsWrapper>
    </DetailsWrapper>
  )
}
