import { ReactNode } from 'react'

import { Spinner } from 'mmfintech-portal-commons'
import { SendMoneyButtonContainer, SendMoneyButtonWrapper } from './SendMoneyButton.styled'

interface SendMoneyButtonProps {
  icon?: ReactNode
  label?: string
  description?: string
  onClick?: () => void
  loading?: boolean
  dataTest?: string
}

export const SendMoneyButton = ({ icon, label, description, onClick, loading, dataTest }: SendMoneyButtonProps) => {
  const handleOnClick = () => {
    if (!loading && typeof onClick === 'function') {
      onClick()
    }
  }

  return (
    <SendMoneyButtonWrapper>
      <SendMoneyButtonContainer className='button' onClick={handleOnClick} data-test={dataTest}>
        <span className='arrow' />
        <div className='icon'>{loading ? <Spinner /> : icon}</div>
        <div className='caption'>{label}</div>
        <div className='description'>{description}</div>
      </SendMoneyButtonContainer>
    </SendMoneyButtonWrapper>
  )
}
