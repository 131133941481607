import cn from 'classnames'
import { toast } from 'react-hot-toast'

import { Button } from 'mmfintech-portal-commons'
import { LabelValueWrapper } from './LabelValue.styled'

import { useCurrencies } from 'mmfintech-backend-api'
import { tr, copyTextToClipboard, formatMoney } from 'mmfintech-commons'

import { ILabelValueProps } from './LabelValue.types'

export const LabelValue = ({
  label,
  value,
  amount,
  currency,
  dataTest,
  enableCopy,
  valueClass,
  labelClass,
  copyValue,
  wrapperClassName
}: ILabelValueProps) => {
  const { getCurrencyPrecision } = useCurrencies()

  const handleCopyClick = () => {
    copyTextToClipboard(copyValue || value.toString())
    toast.remove()
    toast.success(tr('FRONTEND.BANKING.COMMON.COPY_SUCCESS', 'Value copied to clipboard.'))
  }

  if (typeof value === 'object' || (typeof value === 'string' && value.length)) {
    return (
      <LabelValueWrapper className={cn('label-value', wrapperClassName)}>
        <div className='label'>{label}</div>
        {enableCopy ? (
          <div className='copy-box'>
            <span data-test={dataTest}>{value}</span>
            <Button
              type='button'
              color='primary'
              text={tr('FRONTEND.BUTTONS.COPY', 'Copy')}
              onClick={handleCopyClick}
            />
          </div>
        ) : (
          <div className={cn('value', valueClass)} data-test={dataTest}>
            {value}
          </div>
        )}
      </LabelValueWrapper>
    )
  }

  if (amount > 0 && currency?.length) {
    return (
      <LabelValueWrapper className={cn('label-value', wrapperClassName)}>
        <div className={cn('label', labelClass)}>{label}</div>
        <div className={cn('value', valueClass)} data-test={dataTest}>
          <span data-test={`${dataTest ? dataTest + '-' : ''}amount`}>
            {formatMoney(amount, currency, getCurrencyPrecision(currency))}
          </span>
        </div>
      </LabelValueWrapper>
    )
  }

  return null
}
