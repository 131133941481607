import styled from 'styled-components'

export const FlowStepsWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  gap: 1.2rem;

  color: #808080;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;

  margin-bottom: 1.5rem;
`

export const FlowStep = styled.div`
  display: inline-flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 1.2rem;

  .num {
    width: 2.4rem;
    height: 2.4rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;

    background: #f6f6f6;
    border-radius: 40px;

    color: #595959;
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
  }

  &.active {
    color: black;

    .num {
      background: #000000;
      border-radius: 40px;
      color: #fcfcfc;
    }
  }
`
