import { useEffect, useState } from 'react'
import fileDownload from 'js-file-download'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'


import { actions } from 'mmfintech-backend-api'

import FileComponent from './FileComponent'
import { Line, LineWrapper } from '../styled/conversationModal.styled'

import { ReactComponent as StaffIcon } from '../../../../images/icons/staff-icon.svg'

const ConversationLine = ({
  isAdminMessage,
  content,
  type
}: {
  isAdminMessage: boolean
  content: string
  type: 'TEXT' | 'DOCUMENT'
}) => {
  const { merchant } = useSelector(
    ({ user: { merchant } }: any) => ({
      merchant
    }),
    shallowEqual
  )
  const dispatch = useDispatch()

  const [documentContent, setDocumentContent] = useState<Blob>()

  useEffect(() => {
    if (type === 'DOCUMENT') {
      dispatch(
        actions.kyb.downloadKybDocument(content, (response: any) => {
          setDocumentContent(response.data)
        })
      )
    }
  }, [type, content, dispatch])

  const handleDownloadPdf = () => {
    if (documentContent) {
      fileDownload(documentContent, 'Document', 'application/pdf')
    }
  }

  const { merchantName, name }: { merchantName: string; name: string } = merchant || {}
  const initials = (name || merchantName)?.match(/\b\w/g)?.join('')?.toUpperCase()
  return (
    <LineWrapper isAdminMessage={isAdminMessage}>
      {isAdminMessage ? <StaffIcon className='staff-icon' /> : <div className='initials'>{initials}</div>}
      <Line isAdminMessage={isAdminMessage}>
        <div className='line-title'>{isAdminMessage ? 'The Kingdom Bank' : name || merchantName}</div>
        <div className='content'>
          {type === 'DOCUMENT' ? (
            documentContent?.type.includes('image') ? (
              <img src={URL.createObjectURL(documentContent)} alt='uploaded-document' />
            ) : (
              <FileComponent
                name='File'
                iconStroke='#ffffff'
                wrapperStyles={{ cursor: 'pointer' }}
                onClick={handleDownloadPdf}
              />
            )
          ) : (
            content
          )}
        </div>
      </Line>
    </LineWrapper>
  )
}

export default ConversationLine
