import { Link } from 'react-router-dom'
import { Preloader } from 'mmfintech-portal-commons'
import { AccountLabel } from './AccountLabel'
import { DashboardChart } from './DashboardChart'
import { AccountsPaneWrapper, GraphPaneWrapper } from './GraphSection.styled'

import { isValidArray } from 'mmfintech-commons'
import { paths, useMerchantAccounts } from 'mmfintech-backend-api'

export const GraphSection = () => {
  const { activeAccounts, accountsFetching } = useMerchantAccounts()

  return (
    <GraphPaneWrapper>
      <div className='graph'>
        <DashboardChart />
      </div>

      <AccountsPaneWrapper>
        <div className='your-accounts-text'>Your Accounts</div>
        {accountsFetching ? (
          <Preloader />
        ) : isValidArray(activeAccounts) ? (
          <>
            {activeAccounts.slice(0, 4).map(account => (
              <AccountLabel account={account} key={account.id} />
            ))}
            <Link className='view-all-link' to={paths.banking.accounts.list()}>
              View All -{'>'}
            </Link>
          </>
        ) : null}
      </AccountsPaneWrapper>
    </GraphPaneWrapper>
  )
}
