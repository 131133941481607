import styled, { css } from 'styled-components'
import { CommonSignupContent } from '../Commons'

export const LoginContentWrapper = styled.div`
  width: 100%;
`

export const LoginContent = styled(CommonSignupContent)`
  &.alternative {
    .input-wrapper {
      .show-password,
      input {
        &:-webkit-autofill,
        &:-webkit-autofill:hover,
        &:-webkit-autofill:focus,
        &:-webkit-autofill:active {
          -webkit-box-shadow: 0 0 0 3rem #ffffff inset !important;
          box-shadow: 0 0 3rem 3rem #ffffff inset !important;
          background: #ffffff !important;
        }
      }
    }
  }
`

export const StyledForm = styled.form`
  width: 100%;

  display: flex;
  flex-direction: column;

  .input-wrapper,
  .button {
    width: 100%;
  }
  .back-button {
    margin-top: 2rem;
  }

  .link-wrap {
    margin: 0.5rem 0 2.4rem;
    text-align: right;

    a {
      color: rgba(0, 97, 232, 0.6);
      font-size: 1.2rem;
      font-weight: 400;
      line-height: 102.52%;
      text-decoration: none;
      outline: none;
    }
  }
`

export const SignupLinkWrapper = styled.div<{ alternative?: boolean }>`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  color: ${({ alternative }) => (alternative ? '#000000' : 'rgba(255, 255, 255, 0.6)')};
  padding: ${({ alternative }) => (alternative ? '2rem' : '0')};

  font-family: inherit;
  font-size: 1.3rem;
  font-style: normal;
  font-weight: 400;
  line-height: 102.52%;
  letter-spacing: -0.025em;

  position: relative;

  ${({ alternative }) =>
    alternative &&
    css`
      &:before {
        content: ' ';
        position: absolute;
        top: 0;
        width: 80%;
        border-top: 1px solid #a7b3be;
      }
    `}
  a {
    border-width: 1px;
    border-style: solid;
    border-color: ${({ alternative }) => (alternative ? '#262626' : 'rgba(255, 255, 255, 0.2)')};
    border-radius: 4px;
    background-color: ${({ alternative }) => (alternative ? '#262626' : 'none')};

    color: #ffffff;
    font-size: 1.3rem;
    font-weight: 600;
    line-height: 1.6rem;
    text-decoration: none;
    outline: none;

    display: flex;
    align-items: center;
    text-align: center;
    padding: 1.2rem 1.8rem;
    margin-left: 2.5rem;
  }
`
