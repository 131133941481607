import { FC } from 'react'

import { Pagination } from 'mmfintech-portal-commons'

import { ReportsTable } from '../ReportsTable'

import { IReportTableProps } from './@types'
import { isValidArray } from 'mmfintech-commons'

export const AllReports: FC<IReportTableProps> = ({ data, pagination, isApproverTable }) => (
  <>
    <ReportsTable data={data} isApproverTable={isApproverTable} reloadPagination={() => pagination.reload()} />
    {isValidArray(data) && <Pagination {...pagination.register()} />}
  </>
)
