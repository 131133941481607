import styled from 'styled-components'
import { ExpenseModalContainer } from '../../expenses/styles/expenseModal.styled'

const ReportModalWrapper = styled(ExpenseModalContainer)`
  min-width: 516px;

  @media (max-width: 530px) {
    min-width: 100%;
  }

  .input-wrapper {
    margin-bottom: 0;
  }

  .buttons-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.6rem;

    .button-primary,
    .button-secondary {
      width: 100%;
      max-width: 13rem;
      align-self: center;

      span {
        font-size: 1.6rem;
        font-weight: 400;
      }
    }

    .button-secondary {
      span {
        color: black;
        font-weight: 600;
      }
    }
  }
`

const ReportModalInner = styled.div`
  .confirmation {
    color: #707881;
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 1.6rem;
    display: flex;
    justify-content: center;

    p {
      color: #000000;
      margin: 0;
      margin-left: 0.5rem;
    }
  }
`

const ReturnRejectModalWrapper = styled(ExpenseModalContainer)`
  min-width: 500px;
  padding: 4rem 5rem;

  @media (max-width: 530px) {
    min-width: 100%;
  }

  .input-wrapper {
    margin-bottom: 0;

    label {
      color: black;
    }
  }

  .err-msg-wrapper {
    justify-content: center;
  }

  .buttons-container {
    display: flex;
    flex-direction: column;
    gap: 1.6rem;
    margin-top: 3rem;

    .button-primary,
    .button-secondary {
      span {
        font-size: 1.6rem;
        font-weight: 400;
      }
    }

    .button-secondary {
      span {
        color: black;
      }
    }
  }
`

const ReturnRejectModalInner = styled.div`
  .confirmation {
    color: #707881;
    font-size: 1.4rem;
    font-weight: 400;
  }
`

const ReturnRejectModalHeader = styled.h3`
  margin: 0;
  font-size: 2.4rem;
  font-weight: 400;
  margin-bottom: 0.4rem;
`

const ApproveModalWrapper = styled(ExpenseModalContainer)`
  min-width: 500px;
  padding: 4rem 5rem;

  @media (max-width: 530px) {
    min-width: 100%;
  }

  .err-msg-wrapper {
    justify-content: center;
  }

  .buttons-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.6rem;

    .button-primary,
    .button-secondary {
      width: 100%;
      max-width: 13rem;
      align-self: center;

      span {
        font-size: 1.6rem;
        font-weight: 400;
      }
    }

    .button-secondary {
      span {
        color: black;
        font-weight: 600;
      }
    }
  }
`

const ApproveModalInner = styled.div`
  .confirmation {
    color: #707881;
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 1.6rem;
    display: flex;
    justify-content: center;

    p {
      color: #000000;
      margin: 0;
      margin-left: 0.5rem;
    }
  }
`

const ApproveModalHeader = styled.h3`
  margin: 0;
  font-size: 2.4rem;
  margin-bottom: 1.6rem;
  text-align: center;
`

export {
  ReportModalWrapper,
  ReportModalInner,
  ReturnRejectModalInner,
  ReturnRejectModalWrapper,
  ReturnRejectModalHeader,
  ApproveModalWrapper,
  ApproveModalInner,
  ApproveModalHeader
}
