import styled from 'styled-components'

import { CashVoucherStatusEnum } from 'mmfintech-commons-types'

const getColorByStatus = (status: string) => {
  switch (status) {
    case CashVoucherStatusEnum.REDEEMED:
      return {
        borderColor: '#4C0',
        backgroundColor: '#F2F9F0',
        statusBackgroundColor: '#4C0',
        text: '#fff'
      }
    case CashVoucherStatusEnum.REDEMPTION_IN_PROGRESS:
      return {
        borderColor: '#FFCE32',
        backgroundColor: '#FFFBEB',
        statusBackgroundColor: '#FFCE32',
        text: '#000'
      }
    case CashVoucherStatusEnum.CANCELLED:
    case CashVoucherStatusEnum.EXPIRED:
      return {
        borderColor: '#FF3131',
        backgroundColor: '#FBF5F5',
        statusBackgroundColor: '#FF3131',
        text: '#fff'
      }
    default:
      return {
        borderColor: '#CCC',
        backgroundColor: '#FFF',
        statusBackgroundColor: '#CCC',
        text: '#000'
      }
  }
}
export const OldVoucherWrapper = styled.div`
  justify-content: center;
  align-items: center;
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  gap: 2rem;

  @media (max-width: 1024px) {
    padding: 0 2rem;
  }

  .animation-container {
    width: 100%;
    display: flex;
    justify-content: center;
  }
`

export const OldVoucherItemWrapper = styled.div<{ $status?: string }>`
  display: flex;
  max-width: 75rem;
  margin: 0 auto;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-radius: 1rem;
  gap: 5rem;
  box-shadow: none;

  padding: 3rem;

  background-color: ${({ $status }) => getColorByStatus($status).backgroundColor};

  border: ${({ $status }) => `2px solid ${getColorByStatus($status).borderColor}`};

  @media (max-width: 1024px) {
    width: 100%;
    align-items: flex-start;
    flex-direction: column;
    gap: 3rem;
    max-width: none;
  }

  .old-voucher-label {
    min-width: 13rem;
    margin-bottom: 0;
  }
`

export const StatusBadge = styled.div<{
  $status?: string
}>`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  min-width: 12rem;

  box-sizing: border-box;

  gap: 0.5rem;
  padding: 0.4rem;

  border-radius: 0.4rem;
  max-height: 2.7rem;

  & > div {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  background-color: ${({ $status }) => getColorByStatus($status).statusBackgroundColor};

  & > span {
    font-size: 14px;
    color: ${({ $status }) => getColorByStatus($status).text};
  }
`

export const OldVoucherItemAmountAndStatusWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 768px) {
    width: 100%;
    align-items: flex-start;
  }

  @media (max-width: 320px) {
    width: 100%;
    flex-direction: column;
    gap: 2rem;
  }
`

export const OldVoucherItemDetails = styled.div`
  display: flex;
  flex: 1;
  gap: 5rem;

  align-items: center;

  @media (max-width: 768px) {
    width: 100%;
    flex-direction: column;
    gap: 3.5rem;

    justify-content: flex-start;
    align-items: flex-start;
  }
`

export const StatusLoaderWrapper = styled.div`
  background: #fff;
  box-sizing: border-box;
  width: 1.9rem;
  height: 1.9rem;
  border-radius: 4px;
  padding: 0.2rem;
`
