import React, { useRef } from 'react'
import { ThreeDots } from 'react-loader-spinner'

import { StatusBadge, StatusLoaderWrapper } from './OldVoucher.styled'

import { CashVoucherStatusEnum } from 'mmfintech-commons-types'

import { ReactComponent as ExpiredIcon } from '../../../images/old-voucher/expired.svg'
import { ReactComponent as RedeemedIcon } from '../../../images/old-voucher/redeemed.svg'

const ICONS_WIDTH = 19
const ICONS_HEIGHT = 19

export const humanizeGiftStatusName = (status: string): string => {
  switch (status) {
    case CashVoucherStatusEnum.REDEMPTION_IN_PROGRESS:
      return 'Pending'
    case CashVoucherStatusEnum.ACTIVE:
      return 'Active'
    case CashVoucherStatusEnum.REDEEMED:
      return 'Redeemed'
    case CashVoucherStatusEnum.EXPIRED:
      return 'Expired'
    case CashVoucherStatusEnum.CANCELLED:
      return 'Cancelled'
    default:
      return status
  }
}

export const StatusBadgeComponent: React.FC<{
  status: string
}> = ({ status }) => {
  const icon = useRef(null)

  switch (status) {
    case CashVoucherStatusEnum.REDEEMED:
      icon.current = (
        <div>
          <RedeemedIcon width={ICONS_WIDTH} height={ICONS_HEIGHT} />
        </div>
      )
      break
    case CashVoucherStatusEnum.EXPIRED:
      icon.current = (
        <div>
          <ExpiredIcon width={ICONS_WIDTH} height={ICONS_HEIGHT} />
        </div>
      )
      break
    case CashVoucherStatusEnum.REDEMPTION_IN_PROGRESS:
      icon.current = (
        <StatusLoaderWrapper>
          <ThreeDots
            height='17'
            width='17'
            color='#F8D44D'
            ariaLabel='three-dots-loading'
            wrapperStyle={{}}
            visible={true}
          />
        </StatusLoaderWrapper>
      )
      break
    default:
      icon.current = null
      break
  }
  return (
    <StatusBadge data-test="voucher-status" $status={status}>
      {icon.current}
      <span>{humanizeGiftStatusName(status)}</span>
    </StatusBadge>
  )
}
