export const hints = {
  unlocked: {
    deposit: 'unlocked-deposits',
    exchange: 'unlocked-exchange'
  },
  new: {
    inviteAndEarn: 'invite-and-earn',
    saving: 'saving'
  }
}
