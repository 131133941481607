// noinspection JSCheckFunctionSignatures

import React from 'react'

import Login from './views/auth/Login'
import Dashboard from './views/dashboard'
import Outlet from './views/Outlet'
import SendMoney from './views/banking/SendMoney'

import CashVoucher from './views/cashVoucher/CashVoucher'
import CashVoucherSuccess from './views/cashVoucher/CashVoucherSuccess'

import { paths } from 'mmfintech-backend-api'

const AdsRegisterOffshoreAndOverseas = React.lazy(() => import('./views/adsPages/AdsRegisterOffshoreAndOverseas'))
const AdsRegisterPageMCA = React.lazy(() => import('./views/adsPages/AdsRegisterMCA'))
const AdsRegisterPage = React.lazy(() => import('./views/adsPages/AdsRegister'))
const Signup = React.lazy(() => import('./views/auth/Signup'))
const LazySumSubVerification = React.lazy(() => import('./views/onboarding/SumSubVerification'))

const ScanAndPay = React.lazy(() => import('./views/scanAndPay/ScanAndPay'))
const ScanAndPayP2P = React.lazy(() => import('./views/scanAndPay/PeerToPeer'))
const P2PQrCodePage = React.lazy(() => import('./views/scanAndPay/QRPayment'))

const Accounts = React.lazy(() => import('./views/account/Accounts'))
const Transactions = React.lazy(() => import('./views/transactions/Transactions'))
const TransactionDetails = React.lazy(() => import('./views/transactions/TransactionDetails'))

const Recipients = React.lazy(() => import('./views/recipients/Recipients'))
const RecipientDetails = React.lazy(() => import('./views/recipients/RecipientDetails'))

const Invoices = React.lazy(() => import('./views/invoices/Invoices'))
const InvoiceEdit = React.lazy(() => import('./views/invoices/InvoiceEdit'))
const InvoiceSend = React.lazy(() => import('./views/invoices/InvoiceSend'))
const InvoiceUploadedEdit = React.lazy(() => import('./views/invoices/InvoiceUploadedEdit'))
const Security = React.lazy(() => import('./views/settings/Security'))
const PaymentInfo = React.lazy(() => import('./views/settings/PaymentInfo/PaymentInfo'))

// logoutAnd404Routes
const Logout = React.lazy(() => import('./views/auth/Logout'))
const Error404 = React.lazy(() => import('./views/auth/Error404'))
// logoutAnd404Routes

// loggedOutRoutes
const Invite = React.lazy(() => import('./views/auth/Invite'))
const ForgotPassword = React.lazy(() => import('./views/auth/ForgotPassword'))
const ForgotPasswordMessage = React.lazy(() => import('./views/auth/ForgottenPasswordEmail'))
const ResetPasswordMessage = React.lazy(() => import('./views/auth/PasswordResetMessage'))
const ResetPassword = React.lazy(() => import('./views/auth/ResetPassword'))
const VerifyEmailMessage = React.lazy(() => import('./views/auth/VerifyEmailMessage'))
const VerifyEmail = React.lazy(() => import('./views/auth/VerifyEmail'))
const SetPassword = React.lazy(() => import('./views/auth/SetPassword'))
const VerifyInvitation = React.lazy(() => import('./views/auth/VerifyInvitation'))
// loggedOutRoutes

/** Reports **/
import ExpenseDashboard from './views/dashboard/ExpensesDashboard'
import ExpenseManagementWrapper from './views/expense-menagement/ExpenseManagementWrapper'
const ReportPage = React.lazy(() => import('./views/reports/report/ReportPage'))
const PaymentsPage = React.lazy(() => import('./views/payments/PaymentsPage'))
const CounterpartyPage = React.lazy(() => import('./views/counterparty/CounterpartyPage'))
const PayPage = React.lazy(() => import('./views/pay-expenses/PayPage'))
/** Reports **/

// prettier-ignore
export const loggedOutRoutes = [
  { path: paths.login(), component: Login },
  { path: paths.signUpViaInviteLink(':referralCode'), check: paths.signUpViaInviteLink('*'), component: Signup },
  { path: paths.signUp(), component: Signup },
  { path: paths.invite(':link'), check: paths.invite('*'), component: Invite },
  // { path: paths.waitListJoined(), component: WaitListJoined, exact: true },
  { path: paths.forgotPassword(), component: ForgotPassword },
  { path: paths.changePasswordEmail(), component: ForgotPasswordMessage },
  { path: paths.passwordReset(), component: ResetPasswordMessage },
  { path: paths.handlePasswordReset(), component: ResetPassword },
  { path: paths.checkEmail(), component: VerifyEmailMessage },
  { path: paths.handleEmailVerify(), component: VerifyEmail },
  { path: paths.handlePasswordResetInvited(), component: SetPassword },
  { path: paths.handleInvitationVerify(), component: VerifyInvitation },

  { path: paths.invoices.checkout(':magicToken'), check: paths.invoices.checkout('*'), component: React.lazy(() => import('./views/invoices/InvoiceCheckout')) },
  { path: paths.invoices.viewByToken(':magicToken'), check: paths.invoices.viewByToken('*'), component: React.lazy(() => import('./views/invoices/InvoicePreview')) },

  { path: paths.p2pQrCode(), component: P2PQrCodePage, exact: true },
  { path: paths.sendMoneyP2P(':publicMerchantId'), check: paths.sendMoneyP2P('*'), component: ScanAndPayP2P },

  //ads pages start
  { path: paths.expos.webX24, component: Signup },
  { path: paths.expos.sigmaEE24, component: Signup },
  { path: paths.expos.entertainmentArena24, component: Signup },
  { path: paths.expos.worldFinanceForum24, component: Signup },
  { path: paths.expos.tes24PRG, component: Signup },
  { path: paths.expos.cyprusGaming24, component: Signup },
  { path: paths.expos.singaporeFintech24, component: Signup },
  { path: paths.expos.worldBlockchain24, component: Signup },
  { path: paths.expos.sigmaEurope24, component: Signup },
  { path: paths.expos.financeMagnets24, component: Signup },
  { path: paths.expos.globalBankingFT24, component: Signup },

  { path: paths.moneyTransfer_FC(), component: AdsRegisterPage },
  { path: paths.b2bPayment_FC(), component: AdsRegisterPage },
  { path: paths.businessAccount_FC(), component: AdsRegisterPage },
  { path: paths.bestOffshoreBankAccount_FC(), component: AdsRegisterOffshoreAndOverseas },
  { path: paths.cryptoFriendlyBank_FC(), component: AdsRegisterPage },
  { path: paths.moneyTransfer_TC(), component: AdsRegisterPage },
  { path: paths.b2bPayment_TC(), component: AdsRegisterPage },
  { path: paths.businessAccount_TC(), component: AdsRegisterPage },
  { path: paths.bestOffshoreBankAccount_TC(), component: AdsRegisterOffshoreAndOverseas },
  { path: paths.cryptoFriendlyBank_TC(), component: AdsRegisterPage },
  { path: paths.overseasBankAccount_FC(), component: AdsRegisterOffshoreAndOverseas },
  { path: paths.overseasBankAccount_TC(), component: AdsRegisterOffshoreAndOverseas },
  { path: paths.internationalBankAccount_FC(), component: AdsRegisterPage },
  { path: paths.internationalBankAccount_TC(), component: AdsRegisterPage },
  { path: paths.multicurrencyAccount_FC(), component: AdsRegisterPageMCA },
  { path: paths.multicurrencyAccount_TC(), component: AdsRegisterPageMCA }
  //end
]

export const logoutAnd404Routes = [
  { path: paths.logout(), component: Logout },
  { path: paths.error404(), component: Error404 },
  { path: '*', component: Error404 }
]

// prettier-ignore
export const routesMain = [
  ...loggedOutRoutes,
  { path: paths.root(), exact: true, component: Outlet },

  { path: paths.ibanIssuingPreview(), component: React.lazy(() => import('./views/banking/IbanRequestPreview')) },
  { path: paths.scanAndPay(), component: ScanAndPay },
  { path: paths.dashboard(), component: Dashboard },
  { path: paths.profile(), component: React.lazy(() => import('./views/settings/Profile')) },
  { path: paths.security(), component: Security },
  { path: paths.expenseManagement.paymentInfo(), component: PaymentInfo },
  { path: paths.userManagement(), component: React.lazy(() => import('./views/users/Users')) },
  { path: paths.introducingBrokersProgeny(':progenyId'), component: React.lazy(() => import('./views/ibPortal/IntroducingBrokersDetails')) },
  { path: paths.introducingBrokers(), component: React.lazy(() => import('./views/ibPortal/IntroducingBrokersPortal')) },
  { path: paths.referralProgram(), component: React.lazy(() => import('./views/referral/ReferralProgram')) },

  { path: paths.banking.depositPreview(), exact: true, component: React.lazy(() => import('./views/banking/ApplicationFeeDeposit')) },
  { path: paths.banking.deposit(), component: React.lazy(() => import('./views/banking/Deposit')) },
  { path: paths.banking.exchange(), component: React.lazy(() => import('./views/banking/Exchange')) },
  { path: paths.banking.sendCrypto(), exact: true, component: React.lazy(() => import('./views/banking/CryptoTransfer')) },
  { path: paths.banking.sendPix(), exact: true, component: React.lazy(() => import('./views/banking/Withdraw/Pix')) },
  { path: paths.banking.sendInterac(), exact: true, component: React.lazy(() => import('./views/banking/Withdraw/Interac')) },
  { path: paths.banking.sendPerfectMoney(), exact: true, component: React.lazy(() => import('./views/banking/Withdraw/PerfectMoney')) },
  { path: paths.banking.sendTransfer(), exact: true, component: React.lazy(() => import('./views/banking/InternalTransfer')) },
  { path: paths.banking.sendBankTransfer(), exact: true, component: React.lazy(() => import('./views/banking/BankTransfer')) },
  { path: paths.banking.sendDigitalWallet(), exact: true, component: React.lazy(() => import('./views/banking/Withdraw/DigitalWallet')) },
  { path: paths.banking.sendIntraAccountTransfer(), exact: true, component: React.lazy(() => import('./views/banking/IntraAccountTransfer')) },
  { path: paths.banking.sendBatchPayment(), exact: true, component: React.lazy(() => import('./views/banking/BatchPayment')) },
  { path: paths.banking.sendTo(':recipientId'), component: SendMoney },
  { path: paths.banking.send(), component: SendMoney },

  // Expense Management
  { path: paths.expenseManagement.expenseManagementDashboard(), component: ExpenseManagementWrapper },
  { path: paths.expenseManagement.counterParty(), component: ExpenseManagementWrapper },
  { path: paths.expenseManagement.reports(), component: ExpenseManagementWrapper },
  { path: paths.expenseManagement.singleCounterparty(':counterpartyId'), exact: true, component: CounterpartyPage },
  { path: paths.expenseManagement.report(':reportId'), exact: true, component: ReportPage },
  { path: paths.expenseManagement.payments(':reportId'), exact: true, component: PaymentsPage },
  { path: paths.expenseManagement.pay(), exact: true, component: PayPage },
  // Expense Management

  { path: paths.banking.accounts.list(), component: Accounts },
  { path: paths.banking.transactions.listByAccountId(':accountId'), component: Transactions },
  { path: paths.banking.transactions.list(), component: Transactions },
  { path: paths.banking.transactions.details(':transactionId'), component: TransactionDetails },

  { path: paths.recipients.details(':recipientId'), component: RecipientDetails },
  { path: paths.recipients.list(), component: Recipients },

  { path: paths.more.fiat(), component: React.lazy(() => import('./views/staking/FiatSavings')) },
  { path: paths.more.crypto(), component: React.lazy(() => import('./views/staking/CryptoStaking')) },

  { path: paths.more.cashVoucher('buy'), component: CashVoucher },
  { path: paths.more.cashVoucher('list'), component: CashVoucher },
  { path: paths.more.cashVoucher('redeem'), component: CashVoucher },
  { path: paths.more.cashVoucherSuccess(':uuid'), component: CashVoucherSuccess },

  { path: paths.forex(), component: React.lazy(() => import('./views/forex/Forex')) },
  { path: paths.developer(), component: React.lazy(() => import('./views/developer/Developer')) },
  { path: paths.webTerminal(), component: React.lazy(() => import('./views/forex/WebTerminal')) },

  { path: paths.customerInvoices(':payerId'), component: React.lazy(() => import('./views/customers/CustomerDetails')) },
  { path: paths.customers(), component: React.lazy(() => import('./views/customers/Customers')) },

  { path: paths.invoices.uploadEdit(':invoiceId'), component: InvoiceUploadedEdit },
  { path: paths.invoices.upload(), component: InvoiceUploadedEdit },
  { path: paths.invoices.addForCustomer(':customerId'), component: InvoiceEdit },
  { path: paths.invoices.add(), component: InvoiceEdit },
  { path: paths.invoices.send(':invoiceId'), component: InvoiceSend },
  { path: paths.invoices.edit(':invoiceId'), component: InvoiceEdit },
  { path: paths.invoices.listByStatus(':status'), component: Invoices },
  { path: paths.invoices.list(), component: Invoices },

  { path: paths.onboarding.documents(), exact: true, component: React.lazy(() => import('./views/onboarding/AdditionalDocuments')) },
  { path: paths.onboarding.selectType(), exact: true, component: React.lazy(() => import('./views/onboarding/SumSubSelectType')) },
  { path: paths.onboarding.questionnaireKyb(), exact: true, component: React.lazy(() => import('./views/onboarding/QuestionnaireKYB')) },
  { path: paths.onboarding.sumSub.start(':merchantType'), exact: true, component: LazySumSubVerification },
  { path: paths.onboarding.sumSub.continue(), exact: true, component: LazySumSubVerification },

  { path: paths.banking.finalStatus.fail(':method'), component: React.lazy(() => import('./views/transactions/TransactionFailed')) },
  { path: paths.banking.finalStatus.success(':method'), component: React.lazy(() => import('./views/transactions/TransactionSuccess')) },

  ...logoutAnd404Routes
]

export const submitterRoutes = [
  ...loggedOutRoutes,
  { path: paths.root(), exact: true, component: Outlet },
  { path: paths.dashboard(), redirect: paths.expenseManagement.expenseManagementDashboard() },
  { path: paths.expenseManagement.expenseManagementDashboard(), exact: true, component: ExpenseDashboard },
  { path: paths.expenseManagement.report(':reportId'), exact: true, component: ReportPage },
  { path: paths.security(), component: Security },
  { path: paths.expenseManagement.paymentInfo(), component: PaymentInfo },
  ...logoutAnd404Routes
]

export const approverRoutes = [
  ...loggedOutRoutes,
  { path: paths.root(), exact: true, component: Outlet },
  { path: paths.dashboard(), redirect: paths.expenseManagement.expenseManagementDashboard() },
  { path: paths.expenseManagement.expenseManagementDashboard(), exact: true, component: ExpenseDashboard },
  { path: paths.expenseManagement.report(':reportId'), exact: true, component: ReportPage },
  { path: paths.security(), component: Security },
  { path: paths.expenseManagement.paymentInfo(), component: PaymentInfo },
  ...logoutAnd404Routes
]
