import { WarningMessageWrapper } from './WarningMessage.styled'

import { ReactComponent as InfoIcon } from '../../images/icons/info-circle-black.svg'

interface WarningMessageProps {
  title: string
  lines: string[]
}

export const WarningMessage = ({ title, lines }: WarningMessageProps) => {
  return (
    <WarningMessageWrapper className='warning-block'>
      <div className='label'>
        <InfoIcon />
        {title}
      </div>

      {lines?.map((line: string, index: number) => (
        <p key={index}>{line}</p>
      ))}
    </WarningMessageWrapper>
  )
}
