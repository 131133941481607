import React, { ReactNode, useEffect, useRef } from 'react'

import {
  InputAmountElement,
  InputAmountError,
  InputAmountLabel,
  InputAmountLimits,
  InputAmountStatic,
  InputAmountWrapper
} from './InputAmount.styled'

import { useCurrencies } from 'mmfintech-backend-api'
import { checkSingleValue, formatFloat, isValidFunction, isValidString, stringStartsWith, tr } from 'mmfintech-commons'

import { PaymentMethodEnum, PaymentOptionStatusEnum } from 'mmfintech-commons-types'

type InputAmountProps = {
  label?: ReactNode
  amount?: string
  account?: any
  setAmount?: (value: string) => void
  paymentOption?: any
  placeholder?: string
  error?: string
  className?: string
  hasFees?: boolean
  [key: string]: any
}

export const InputAmount = ({
  label,
  amount,
  account,
  setAmount,
  paymentOption,
  error,
  setAmountError,
  placeholder,
  dataTest,
  hasFees = true,
  ...rest
}: InputAmountProps) => {
  const inputRef = useRef(null)

  useEffect(() => {
    const handleWheel = event => {
      if (document.activeElement === inputRef.current) {
        event.preventDefault()
      }
    }

    const inputElement = inputRef.current
    inputElement.addEventListener('wheel', handleWheel)

    return () => {
      inputElement.removeEventListener('wheel', handleWheel)
    }
  }, [])

  const { currencyCode } = account || {}

  const { getCurrencyPrecision } = useCurrencies()
  const decimals = getCurrencyPrecision(currencyCode)

  const fixAmount = (value: string): string => (stringStartsWith(value, '.') ? `0${value}` : value)

  const formatAmount = (amount: number, decimals: number) => {
    const pow = Math.pow(10, decimals)
    return formatFloat(Math.floor(amount * pow) / pow, decimals)
  }

  const getLimits = () => {
    const { status, minAmount, maxAmount, paymentMethod } = paymentOption || {}

    if (status === PaymentOptionStatusEnum.AVAILABLE) {
      const res = []

      if (minAmount > 0) {
        const formattedMin = formatAmount(minAmount, decimals)
        res.push(`${tr('FRONTEND.MONEY_INPUT.LABEL_MIN', 'Min')}: ${formattedMin}`)
      }
      if (maxAmount > 0) {
        const formattedMax = formatAmount(maxAmount, decimals)
        if ((maxAmount < minAmount && minAmount > 0) || hasFees) {
          res.push(`${tr('FRONTEND.MONEY_INPUT.LABEL_MAX_AVAILABLE', 'Max available including fees')}: ${formattedMax}`)
        } else {
          res.push(`${tr('FRONTEND.MONEY_INPUT.LABEL_MAX', 'Max')}: ${formattedMax}`)
        }
      }

      if (paymentMethod && paymentMethod == PaymentMethodEnum.KINGDOM_CASH) {
        res.push(tr('FRONTEND.MONEY_INPUT.ADDITIONAL_INFO', 'Must be a multiple of 5'))
      }

      return res.length > 0 && res.join(', ')
    }

    return null
  }

  const handleAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (isValidFunction(setAmount)) {
      const val = checkSingleValue(fixAmount(e.target.value), { validation: 'float', decimals })
      setAmount(val)
      isValidFunction(setAmountError) && setAmountError(null)
    }
  }
  return (
    <InputAmountWrapper {...rest}>
      <InputAmountLabel>{label}</InputAmountLabel>

      {isValidFunction(setAmount) ? (
        <InputAmountElement
          ref={inputRef}
          type='number'
          value={amount || ''}
          onChange={handleAmountChange}
          placeholder={placeholder || '0'}
          maxLength={16}
          data-test={dataTest}
        />
      ) : (
        <InputAmountStatic data-test={dataTest}>{amount || 0}</InputAmountStatic>
      )}

      {isValidString(error) && <InputAmountError>{error}</InputAmountError>}
      <InputAmountLimits>{getLimits() || ' '}</InputAmountLimits>
    </InputAmountWrapper>
  )
}
