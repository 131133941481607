import styled from 'styled-components'

import InfoImage from '../../images/icons/input-icon-small.svg'

export const TrustedRecipientCheckboxStyled = styled.div`
  display: flex;
  flex-direction: row;

  width: 100%;
  position: relative;
  margin-top: 2rem;
  margin-bottom: 3rem;

  div {
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 1.7rem;
    margin-left: 1rem;
  }

  .info {
    width: 12px;
    height: 12px;
    cursor: pointer;
    background: url(${InfoImage}) center center no-repeat;
    margin-left: 1rem;
    svg {
      color: #000000;
    }
  }
`
