import styled from 'styled-components'
import { CommonPageTitle } from '../..'

type TabProps = {
  active: boolean
  isFullWidth?: boolean
}

export const RequestsTitle = styled(CommonPageTitle)`
  margin-bottom: 2rem;
`

export const RequestsWrapper = styled.div`
  grid-row: 2 / 4;
  grid-column: main-content;

  padding: 0 2.5rem;
  margin: 6rem 0 0;

  display: flex;
  flex-flow: column nowrap;

  .expenses-header {
    display: flex;
    flex-flow: column nowrap;

    /* @media (min-width: 768px) {
      align-items: center;
      flex-flow: row nowrap;
    } */
  }

  .headRow {
    th:hover {
      cursor: pointer;
    }

    .activeSortCell {
      color: black !important;
    }

    th:last-of-type {
      text-align: center;
    }

    .sort-icons {
      margin-right: 0.5rem;
    }
  }
`
export const TabsWrapper = styled.div`
  display: flex;
  justify-content: center;

  flex: 1;

  margin-bottom: 3rem;
`
export const Tab = styled.div<TabProps>`
  font-size: 1.4rem;
  line-height: 1.7rem;
  font-weight: 400;
  flex: ${({ isFullWidth }) => (isFullWidth ? '1' : 'initial')};
  text-align: center;
  flex: 1;
  max-width: 20rem;

  color: #85a0ad;
  border-bottom: 2px solid #ccd7dc;
  cursor: pointer;
  padding: 2rem;

  ${({ active }) =>
    active &&
    `
    color: #000000;
    border-bottom: 2px solid #000000;;
  `}
`
export const TableWrapper = styled.div``
