// noinspection SpellCheckingInspection

import styled from 'styled-components'

import ADA from '../../images/currencies/ada.png'
import AED from '../../images/currencies/aed.png'
import AUD from '../../images/currencies/aud.png'
import AUDD from '../../images/currencies/audd.png'
import BGN from '../../images/currencies/bgn.png'
import BHD from '../../images/currencies/bhd.png'
import BNB from '../../images/currencies/bnb.png'
import BRL from '../../images/currencies/brl.png'
import BTC from '../../images/currencies/btc.png'
import CAD from '../../images/currencies/cad.png'
import CLP from '../../images/currencies/clp.png'
import CHF from '../../images/currencies/chf.png'
import COP from '../../images/currencies/cop.png'
import CZK from '../../images/currencies/czk.png'
import DKK from '../../images/currencies/dkk.png'
import DOGE from '../../images/currencies/doge.png'
import EGP from '../../images/currencies/egp.png'
import ETH from '../../images/currencies/eth.png'
import EUR from '../../images/currencies/eur.png'
import EURK from '../../images/currencies/eurk.png'
import GBP from '../../images/currencies/gbp.png'
import GHS from '../../images/currencies/ghs.png'
import HKD from '../../images/currencies/hkd.png'
import HRK from '../../images/currencies/hrk.png'
import HUF from '../../images/currencies/huf.png'
import INR from '../../images/currencies/inr.png'
import JPY from '../../images/currencies/jpy.png'
import KES from '../../images/currencies/kes.png'
import LTC from '../../images/currencies/ltc.png'
import MAD from '../../images/currencies/mad.png'
import MXN from '../../images/currencies/mxn.png'
import NGN from '../../images/currencies/ngn.png'
import NOK from '../../images/currencies/nok.png'
import NZD from '../../images/currencies/nzd.png'
import OMR from '../../images/currencies/omr.png'
import PEN from '../../images/currencies/pen.png'
import PLN from '../../images/currencies/pln.png'
import RON from '../../images/currencies/ron.png'
import RWF from '../../images/currencies/rwf.png'
import SAR from '../../images/currencies/sar.png'
import SEK from '../../images/currencies/sek.png'
import SGD from '../../images/currencies/sgd.png'
import SOL from '../../images/currencies/sol.png'
import TRY from '../../images/currencies/try.png'
import TZS from '../../images/currencies/tzs.png'
import UGX from '../../images/currencies/ugx.png'
import USD from '../../images/currencies/usd.png'
import USDC from '../../images/currencies/usdc.png'
import USDT from '../../images/currencies/usdt.png'
import XAF from '../../images/currencies/xaf_xof.png'
import XRP from '../../images/currencies/xrp.png'
import ZMW from '../../images/currencies/zmw.png'

export const CurrencyIconWrapper = styled.span<{ width?: string; height?: string }>`
  display: inline-block;
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  border-radius: 8px;

  &.card {
    border: 1px solid #ffffff;
  }

  &.circle {
    border-radius: 50%;
  }

  &.ADA {
    background-image: url(${ADA});
  }
  &.AED {
    background-image: url(${AED});
  }
  &.AUD {
    background-image: url(${AUD});
  }
  &.AUDD,
  &.AUDD-ERC20 {
    background-image: url(${AUDD});
  }
  &.BGN {
    background-image: url(${BGN});
  }
  &.BHD {
    background-image: url(${BHD});
  }
  &.BNB {
    background-image: url(${BNB});
  }
  &.BRL {
    background-image: url(${BRL});
  }
  &.BTC {
    background-image: url(${BTC});
  }
  &.CAD {
    background-image: url(${CAD});
  }
  &.CLP {
    background-image: url(${CLP});
  }
  &.CHF {
    background-image: url(${CHF});
  }
  &.COP {
    background-image: url(${COP});
  }
  &.CZK {
    background-image: url(${CZK});
  }
  &.DKK {
    background-image: url(${DKK});
  }
  &.DOGE {
    background-image: url(${DOGE});
  }
  &.EGP {
    background-image: url(${EGP});
  }
  &.ETH {
    background-image: url(${ETH});
  }
  &.EUR {
    background-image: url(${EUR});
  }
  &.EURK-ERC20 {
    background-image: url(${EURK});
  }
  &.GBP {
    background-image: url(${GBP});
  }
  &.GHS {
    background-image: url(${GHS});
  }
  &.HKD {
    background-image: url(${HKD});
  }
  &.HRK {
    background-image: url(${HRK});
  }
  &.HUF {
    background-image: url(${HUF});
  }
  &.INR {
    background-image: url(${INR});
  }
  &.JPY {
    background-image: url(${JPY});
  }
  &.KES {
    background-image: url(${KES});
  }
  &.LTC {
    background-image: url(${LTC});
  }
  &.MAD {
    background-image: url(${MAD});
  }
  &.MXN {
    background-image: url(${MXN});
  }
  &.NGN {
    background-image: url(${NGN});
  }
  &.NOK {
    background-image: url(${NOK});
  }
  &.NZD {
    background-image: url(${NZD});
  }
  &.OMR {
    background-image: url(${OMR});
  }
  &.PEN {
    background-image: url(${PEN});
  }
  &.PLN {
    background-image: url(${PLN});
  }
  &.RON {
    background-image: url(${RON});
  }
  &.RWF {
    background-image: url(${RWF});
  }
  &.SAR {
    background-image: url(${SAR});
  }
  &.SEK {
    background-image: url(${SEK});
  }
  &.SGD {
    background-image: url(${SGD});
  }
  &.SOL {
    background-image: url(${SOL});
  }
  &.TRY {
    background-image: url(${TRY});
  }
  &.TZS {
    background-image: url(${TZS});
  }
  &.UGX {
    background-image: url(${UGX});
  }
  &.USD {
    background-image: url(${USD});
  }
  &.USDC {
    background-image: url(${USDC});
  }
  &.USDC-ERC20 {
    background-image: url(${USDC});
  }
  &.USDC-TRC20 {
    background-image: url(${USDC});
  }
  &.USDT {
    background-image: url(${USDT});
  }
  &.USDT-ERC20 {
    background-image: url(${USDT});
  }
  &.USDT-TRC20 {
    background-image: url(${USDT});
  }
  &.XAF,
  &.XOF {
    background-image: url(${XAF});
  }
  &.XRP {
    background-image: url(${XRP});
  }
  &.ZMW {
    background-image: url(${ZMW});
  }
`
