import cn from 'classnames'

import { LogoWrapper } from './KingdomBankLogo.styled'

import settings from '../../settings'
import { isValidString } from 'mmfintech-commons'

import LogoImage from '../../images/icons/kingdom-bank-logo.svg'

export const KingdomBankLogo = ({ autoWidth = false, url = '' }) => (
  <LogoWrapper className={cn({ 'auto-width': autoWidth })}>
    <a href={isValidString(url) ? url : settings.landingPageUrl}>
      <img src={LogoImage} alt='Logo' />
    </a>
  </LogoWrapper>
)
