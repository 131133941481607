import styled from 'styled-components'

export const LogoWrapper = styled.div`
  width: 92vw;
  max-width: 480px;
  margin-bottom: 3rem;
  img {
    max-height: 56px;
  }

  @media (min-width: 640px) {
    width: auto;
  }

  &.auto-width {
    width: auto;
  }
`
