import { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { shallowEqual, useSelector } from 'react-redux'

import { Button } from 'mmfintech-portal-commons'
import { JumpingButton } from '../../../components'
import {
  BookMeetingWrapper,
  WelcomeButtons,
  WelcomeContainer,
  WelcomeContent,
  WelcomeWrapper
} from './WelcomeModal.styled'

import { paths } from 'mmfintech-backend-api'
import { GlobalContext, tr } from 'mmfintech-commons'

import { ReactComponent as DepositIcon } from '../../../images/icons/deposit.svg'
import { ReactComponent as ExchangeIcon } from '../../../images/icons/exchange.svg'
import { ReactComponent as SendIcon } from '../../../images/icons/send.svg'
import { ReactComponent as BookMeeting } from '../../../images/icons/welcome-book-meeting.svg'
import { ReactComponent as Pointer } from '../../../images/icons/welcome-pointer.svg'

export const WelcomeModal = () => {
  const { modalHide } = useContext(GlobalContext)

  const { merchant } = useSelector(
    ({ user: { merchant } }: any) => ({
      merchant
    }),
    shallowEqual
  )

  const history = useHistory()

  const { entityType } = merchant || {}

  const handleDepositClick = () => {
    history.push(paths.banking.deposit())
    modalHide()
  }

  const handleSendMoneyClick = () => {
    history.push(paths.banking.send())
    modalHide()
  }

  const handleExchangeClick = () => {
    history.push(paths.banking.exchange())
    modalHide()
  }

  return (
    <WelcomeWrapper>
      <WelcomeContainer data-test='welcome-modal'>
        <WelcomeContent>
          <h1 className='title'>{tr('FRONTEND.WELCOME.MODAL.HEADING', 'Welcome to The Kingdom Bank!')}</h1>

          <div className='welcome-text'>
            {tr(
              'FRONTEND.WELCOME.MODAL.ACTIONS.TEXT',
              'Your can now deposit, exchange and send your Crypto on the go!'
            )}
          </div>

          <WelcomeButtons>
            <JumpingButton
              icon={<DepositIcon />}
              label={tr('FRONTEND.DASHBOARD.BUTTONS.DEPOSIT', 'Deposit')}
              onClick={handleDepositClick}
              data-test='button-deposit'
            />
            <JumpingButton
              icon={<ExchangeIcon />}
              label={tr('FRONTEND.DASHBOARD.BUTTONS.EXCHANGE', 'Exchange')}
              onClick={handleExchangeClick}
              data-test='exchange-button'
            />
            <JumpingButton
              icon={<SendIcon />}
              label={tr('FRONTEND.DASHBOARD.BUTTONS.SEND', 'Send')}
              onClick={handleSendMoneyClick}
              data-test='send-money-button'
            />
          </WelcomeButtons>

          <div className='welcome-text italic'>
            {tr(
              'FRONTEND.WELCOME.MODAL.COMPLETE.TEXT',
              'Complete your account to get access to many additional features!'
            )}
          </div>
          <div className='buttons-wrapper'>
            <Button
              text={tr('FRONTEND.WELCOME.MODAL.GET.STARTED.BUTTON', 'Get Started')}
              color='primary'
              onClick={() => {
                modalHide()
              }}
            />
          </div>
        </WelcomeContent>
      </WelcomeContainer>

      {entityType === 'COMPANY' && (
        <BookMeetingWrapper>
          <BookMeeting className='calendar' />
          <div className='book-left-side'>
            <div className='book-text'>{tr('FRONTEND.WELCOME.MODAL.BOOK_TEXT', 'Book a Meeting to Find Out More')}</div>
            <Button
              text={tr('FRONTEND.WELCOME.MODAL.SCHEDULE.BUTTON', 'Schedule Now')}
              className='schedule-button'
              onClick={() => window.location.replace('https://calendly.com/meetthekingdombank/hello')}
            />
            <Pointer />
          </div>
        </BookMeetingWrapper>
      )}
    </WelcomeWrapper>
  )
}
