import React from 'react'

import { Button } from 'mmfintech-portal-commons'
import {
  WarningBullet,
  WarningButtons,
  WarningContent,
  WarningTitle,
  WarningWrapper
} from './BankTransferWarning.styled'

import { tr } from 'mmfintech-commons'

import AttentionIcon from '../../../../images/icons/attention.svg'

import Bullet1 from '../../../../images/icons/bullet11.svg'
import Bullet2 from '../../../../images/icons/bullet12.svg'
import Bullet3 from '../../../../images/icons/bullet13.svg'

interface BankTransferWarning1Props {
  onContinue: () => void
}

export const BankTransferWarning1: React.FC<BankTransferWarning1Props> = ({ onContinue }) => {
  return (
    <WarningWrapper>
      <WarningContent>
        <img src={AttentionIcon} width={90} height={90} alt='attention' />

        <WarningTitle className='mt-4'>
          {tr('CHECKOUT.MANUAL.STEP1.TITLE', "We won't take the money from your bank account automatically.")}
        </WarningTitle>

        <WarningBullet>
          <img src={Bullet1} alt='bullet-1' />
          <span>{tr('CHECKOUT.MANUAL.STEP1.BULLET1', "We'll provide you with our bank details.")}</span>
        </WarningBullet>
        <WarningBullet>
          <img src={Bullet2} alt='bullet-2' />
          <span>{tr('CHECKOUT.MANUAL.STEP1.BULLET2', 'You will need to initiate the bank transfer on your own.')}</span>
        </WarningBullet>
        <WarningBullet>
          <img src={Bullet3} alt='bullet-3' />
          <span>{tr('CHECKOUT.MANUAL.STEP1.BULLET3', 'Once we receive the money, we will credit the funds.')}</span>
        </WarningBullet>
      </WarningContent>
      <WarningButtons>
        <Button
          type='button'
          color='primary'
          text={tr('CHECKOUT.MANUAL.STEP1.CONTINUE_BUTTON', 'I understand')}
          onClick={onContinue}
          data-test='button-confirm'
        />
      </WarningButtons>
    </WarningWrapper>
  )
}
