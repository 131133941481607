import React from 'react'

import { Button } from 'mmfintech-portal-commons'
import {
  WarningButtons,
  WarningContainer,
  WarningContent2,
  WarningTitle,
  WarningWrapper
} from './BankTransferWarning.styled'

import { tr } from 'mmfintech-commons'

import WarningIcon from '../../../../images/icons/warning-blue.svg'

interface BankTransferWarning2Props {
  onContinue: () => void
}

export const BankTransferWarning2: React.FC<BankTransferWarning2Props> = ({ onContinue }) => {
  return (
    <WarningWrapper>
      <WarningContainer>
        <WarningTitle>{tr('CHECKOUT.MANUAL.STEP2.TITLE', 'Are you sending from your own account?')}</WarningTitle>
        <WarningContent2>
          <img src={WarningIcon} alt='warning' />
          <span>
            {tr(
              'CHECKOUT.MANUAL.STEP2.WARNING',
              'Only you can pay for this transfer. We will reject the transfer if the funds are sent by someone else'
            )}
          </span>
        </WarningContent2>
      </WarningContainer>

      <WarningButtons>
        <Button
          type='button'
          color='primary'
          text={tr('CHECKOUT.MANUAL.STEP2.CONTINUE_BUTTON', 'Continue')}
          onClick={onContinue}
          data-test='button-confirm'
        />
      </WarningButtons>
    </WarningWrapper>
  )
}
