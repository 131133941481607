import styled, { css } from 'styled-components'

type FlexCommonPositions = 'flex-start' | 'flex-end' | 'center' | 'space-between' | 'baseline'
export type FlexAlign = 'baseline' | FlexCommonPositions
export type FlexJustify = 'space-between' | 'space-around' | 'space-evenly' | FlexCommonPositions

export type CardAndFormProps = {
  $fullWidth?: boolean
  $justify?: FlexJustify
  $align?: FlexAlign
}

export const cardAndFormCommonStyles = css<CardAndFormProps>`
  display: flex;
  flex-direction: column;

  background-color: #fff;
  box-shadow: 0px 4px 10px 0px rgba(188, 187, 187, 0.25);
  color: #000;

  max-width: ${props => (props.$fullWidth ? 'auto' : '50rem')};
  min-width: ${props => (props.$fullWidth ? '50rem' : 'auto')};

  margin: 0 auto;
  gap: 1rem;
  border-radius: 1rem;
  box-sizing: border-box;

  justify-content: ${props => (props.$justify ? props.$justify : 'center')};
  align-items: ${props => (props.$align ? props.$align : 'center')};

  @media (max-width: 1024px) {
    min-width: 10rem;
    height: 100%;
    width: 100%;

    justify-content: flex-start;
    padding: 2rem 1.5rem;
    margin-top: 2rem;
  }
`

export const ActiveVouchersWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  overflow: hidden;
  box-sizing: border-box;

  padding-bottom: 2rem;

  @media (max-width: 768px) {
    padding: 20px 10px 0 10px;
  }
  justify-content: flex-start;

  .infinite-scroll-component {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
  .infinite-scroll-component__outerdiv {
    width: 100%;
  }
`
export const ActiveVouchersItemWrapper = styled.div<{ $isBulk: boolean }>`
  ${cardAndFormCommonStyles}
  padding: 0;
  gap: 0;
  box-sizing: border-box;
  justify-content: flex-start;
  max-width: 75rem;

  border-top: ${props => `13px solid ${props.$isBulk ? '#000' : '#FF3131'}`};
  border-radius: 10px;
  box-shadow: 0px 4px 10px 0px rgba(188, 187, 187, 0.25);

  font-size: 1.4rem;

  .amount-wrapper {
    margin-right: 0.5rem;
  }

  .header-wrapper {
    width: 100%;
    box-sizing: border-box;
    border-bottom: 3px solid #ededed;

    @media (max-width: 768px) {
      border-bottom: none;
      padding: 2rem;
    }
  }

  @media (max-width: 768px) {
    min-width: auto;
    border-bottom: 3px solid #ededed;
  }

  .header-content {
    box-sizing: border-box;
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 2rem 3rem;

    @media (max-width: 320px) {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 2rem;
    }

    .total-vouchers {
      display: flex;
      align-items: center;
      gap: 0.8rem;
      padding: 0.8rem;
      border-radius: 0.4rem;
      border: 1px solid #a7b2be;
      p {
        margin: 0;
        padding: 0;
        height: 2.9rem;
        width: 2.9rem;
        display: flex;
        justify-content: center;
        align-items: center;

        color: #fff;
        border-radius: 0.4rem;
        background: #1a1a1a;
        padding: 0.8rem 0.4rem;

        font-size: 1.6rem;
        font-weight: 500;
      }
      span {

        color: #3c464f;
        font-size: 1.2rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.4rem;
      }
    }
  }
  .content {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    padding: 3rem;
    gap: 2rem;

    @media (max-width: 480px) {
      padding: 2rem;
    }

    width: 100%;
  }

  .action-voucher-button {
    max-width: 16rem;
    align-self: center;
    font-size: 14px;
  }

  .voucher-details-title {
    font-size: 24px;
    margin: 0;
    font-weight: 600;
  }
`

export const ActiveVouchersDetails = styled.div`
  display: flex;

  align-items: center;
  justify-content: space-between;
  gap: 4rem;

  @media (max-width: 768px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
`

export const RevealPinWrapper = styled.div<{ $shouldFlip?: boolean }>`
  width: 12rem;
  min-width: 10rem;
  height: 5rem;

  background-color: transparent;
  cursor: pointer;

  transition: transform 0.2s ease-in-out;
  perspective: 1000px; /* Remove this if you don't want the 3D effect */

  p {
    margin-bottom: 0.7rem;
  }

  .hidden-pin {
    display: block;
    height: 0;
    margin-top: 1rem;
    font-size: 2rem;
    line-height: 0;
    position: relative;
  }

  /* This container is needed to position the front and back side */
  .flip-card-inner {
    display: flex;
    box-sizing: border-box;
    padding: 0 0.5rem;
    font-size: 1.2rem;
    align-items: center;
    border: 1px solid #a7b2be;
    border-radius: 0.8rem;
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.8s;
    transform-style: preserve-3d;
  }

  /* Do an horizontal flip when you move the mouse over the flip box container */
  & .flip-card-inner {
    transform: ${({ $shouldFlip }) => ($shouldFlip ? 'rotateY(180deg)' : 'rotateY(0)')};
  }

  /* Position the front and back side */
  .flip-card-front,
  .flip-card-back {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    width: 100%;
    box-sizing: border-box;
    height: 100%;
    -webkit-backface-visibility: hidden; /* Safari */
    backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    left: 0;
    padding: 0 0.5rem;
    transform: rotateX(0deg);

    svg {
      margin-left: 1.5rem;
    }
  }

  /* Style the back side */
  .flip-card-back {
    font-weight: 700;
    text-align: center;
    justify-content: center;
    transform: rotateY(180deg);
  }
`

export const NoVouchersWrapper = styled.div`
  ${cardAndFormCommonStyles}
  text-align: center;
  gap: 5rem;
`
