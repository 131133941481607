import styled from 'styled-components'

const ConversationsBannerWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  gap: 1.6rem;

  font-weight: 600;
  font-size: 1.2rem;
  line-height: 1.5rem;

  @media (min-width: 767px) {
    flex-flow: row nowrap;
    align-items: center;
    gap: 2rem;
  }

  .attention-container {
    display: flex;
    flex-flow: row nowrap;
    gap: 8px;
    align-items: center;
  }
  .items-container {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: 1rem;
  }
`

const ConversationItem = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 1.5rem;

  cursor: pointer;

  color: #ff3131;
  background: #fce8e8;
  border-radius: 6px;

  padding: 1rem 1.5rem;
`

export { ConversationsBannerWrapper, ConversationItem }
