import styled from 'styled-components'

import { CommonSignupContent } from '../Commons'

export const SignupContent = styled(CommonSignupContent)`
  width: 100%;
  margin-bottom: 0;
`

export const StyledForm = styled.form`
  width: 100%;

  display: flex;
  flex-direction: column;

  .area-code-button:focus .selected-flag,
  .area-code-button:hover .selected-flag {
    background: transparent;
  }

  .password-terms {
    margin-top: 0;
    margin-bottom: 2.5rem;
  }
`

export const UserAgreement = styled.div`
  display: flex;
  flex-direction: row;

  width: 100%;
  position: relative;
  margin-top: 2rem;
  margin-bottom: 3rem;

  div {
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 1.7rem;
    margin-left: 1rem;

    &:first-child {
      margin-top: 0.6rem;
    }

    a {
      margin: 0;
      color: #0d80f2;
      text-decoration: none;
    }
  }
`
