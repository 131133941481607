import { FileComponentWrapper } from '../styled/conversationModal.styled'
import { ReactComponent as DocumentsIcon } from '../../../../images/icons/documents-icon.svg'

const FileComponent = ({
  name,
  iconStroke,
  wrapperStyles,
  onClick
}: {
  name: string
  iconStroke?: string
  wrapperStyles?: React.CSSProperties
  onClick?: () => void
}) => (
  <FileComponentWrapper style={wrapperStyles} onClick={onClick}>
    <DocumentsIcon stroke={iconStroke} />
    <div>{name.length <= 6 ? name : `${name.substring(0, 6)}... ${name.substring(name.lastIndexOf('.') + 1)}`}</div>
  </FileComponentWrapper>
)

export default FileComponent
