import { useContext, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { shallowEqual, useSelector } from 'react-redux'

import toast from 'react-hot-toast'

import { CurrencyBadge } from '../../../components'
import { SelectedAccount } from './SelectedAccount'
import { NoAvailableAccounts } from './NoAvailableAccounts'
import { Button, ErrorDisplay, Input } from 'mmfintech-portal-commons'
import {
  AccountsContainer,
  BadgeContainer,
  Buttons,
  Fees,
  FeeTitle,
  IbanRequestModalWrapper,
  Tab,
  TabsContainer
} from './IbanRequestModal.styled'

import { paths, useCountries, useIbanRequest } from 'mmfintech-backend-api'
import { tr, GlobalContext, isValidArray, isValidString, formatMoney } from 'mmfintech-commons'

import {
  BankAccountIssuingPreviewRequest,
  BankAccountScheme,
  CountrySupportedPurposeEnum,
  MerchantEntityTypeEnum
} from 'mmfintech-commons-types'
import settings from '../../../settings'

interface IbanRequestModalProps {
  reload?: () => void
}

export const IbanRequestModal = ({ reload }: IbanRequestModalProps) => {
  const { modalHide }: any = useContext(GlobalContext)

  const { merchant } = useSelector(({ user: { merchant } }: any) => ({ merchant }), shallowEqual)

  const { entityType } = merchant || {}
  const isCompany = entityType === MerchantEntityTypeEnum.COMPANY
  const isIndividual = entityType === MerchantEntityTypeEnum.INDIVIDUAL

  const history = useHistory()

  const [step, setStep] = useState<number>(1)

  const { countryOptions, countriesFetching } = useCountries(CountrySupportedPurposeEnum.WITHDRAWAL)
  const [countryListError, setCountryListError] = useState<string>('')

  const {
    selectedScheme,
    setSelectedScheme,
    selectedCurrency,
    setSelectedCurrency,
    selectedAccount,
    setSelectedAccount,
    availableSchemes,
    ibanSchemeCurrencies,
    ibanSchemeCurrenciesError,
    ibanSchemeCurrenciesFetching,
    fees,
    // feesError,
    // feesFetching,
    accounts,
    accountsFetching,
    handleRequest,
    previewError,
    previewFetching,
    // previewResponse,
    initiateError,
    initiateFetching,
    // initiateResponse,
    resetMutations,
    setCountryList,
    countryList,
    formValues
  } = useIbanRequest({
    shouldSkipIssuingFee: isCompany || isIndividual,
    onFee: (payload: BankAccountIssuingPreviewRequest) => {
      modalHide()
      history.push(paths.ibanIssuingPreview(), { payload })
    },
    onSuccess: () => {
      reload()
      modalHide()
      resetMutations()
      toast.remove()
      toast.success(
        'Your IBAN issuing request has been accepted. ' +
          'You will receive confirmation once IBAN has been approved and issued.'
      )
    },
    hasQuestionnaire: isIndividual
  })

  return (
    <IbanRequestModalWrapper>
      <div className='title'>{tr('FRONTEND.IBAN_ISSUING.MODAL.TITLE', 'IBAN issuing request')}</div>

      {step === 1 && (
        <div>
          <div className='instructions-title'>
            {tr('FRONTEND.IBAN_ISSUING.MODAL.INSTRUCTIONS', 'Choose a payment network and currency for your IBAN:')}
          </div>

          <TabsContainer>
            {!ibanSchemeCurrenciesFetching &&
              isValidArray(availableSchemes) &&
              availableSchemes?.map((scheme, index) => (
                <Tab
                  key={index}
                  onClick={() => setSelectedScheme(scheme as BankAccountScheme)}
                  className={selectedScheme === scheme ? 'active' : ''}
                  data-test={`${scheme.toLowerCase()}-tab`}>
                  {scheme}
                </Tab>
              ))}
          </TabsContainer>

          <BadgeContainer>
            {isValidString(selectedScheme) &&
              ibanSchemeCurrencies[selectedScheme]?.map((currency: string) => (
                <CurrencyBadge
                  key={`${selectedScheme}-${currency}`}
                  currencyCode={currency}
                  isSelected={selectedCurrency === currency}
                  onClick={() => setSelectedCurrency(currency)}
                />
              ))}
          </BadgeContainer>

          {isValidString(selectedScheme) && isValidString(selectedCurrency) && (
            <>
              <div className='associate-iban-title'>
                {tr(
                  'FRONTEND.IBAN_ISSUING.MODAL.ASSOCIATE.TITLE',
                  'Choose a currency account to associate with the IBAN:'
                )}
              </div>
              {/*<div className='subtitle'>
            {tr(
              'FRONTEND.IBAN_ISSUING.MODAL.ASSOCIATE.SUBTITLE',
              'If no selection is made, a new currency account will be created automatically.'
            )}
          </div>*/}

              <AccountsContainer>
                {isValidArray(accounts) ? (
                  <div className='inner'>
                    {accounts?.map((acc: any) => (
                      <SelectedAccount
                        account={acc}
                        setAccount={acc => {
                          if (acc.id === selectedAccount?.id) {
                            setSelectedAccount(undefined)
                          } else {
                            setSelectedAccount(acc)
                          }
                        }}
                        isSelected={selectedAccount?.id === acc.id}
                        key={acc.id}
                      />
                    ))}
                  </div>
                ) : !accountsFetching ? (
                  <NoAvailableAccounts currency={selectedCurrency} reload={reload} />
                ) : null}
              </AccountsContainer>
            </>
          )}

          <div className='fees-container'>
            <div className='subtitle'>
              {isCompany || isIndividual
                ? tr(
                    'FRONTEND.IBAN_ISSUING.MODAL.DISCLAIMER_COMPANY',
                    'You may need to update your existing or provide extra documents. Contact your customer support manager for more information regarding your request.'
                  )
                : tr(
                    'FRONTEND.IBAN_ISSUING.MODAL.DISCLAIMER',
                    'Please note that the Iban fee will be deducted from your account. You may need to update your existing or provide extra documents. Contact your customer support manager for more information regarding your request.'
                  )}
            </div>

            {isCompany || isIndividual ? (
              <div className='subtitle'>
                <b>{tr('FRONTEND.IBAN_ISSUING.COMPANY_LABEL', 'Issuing and maintenance fees')}:</b>{' '}
                {isCompany ? (
                  tr('FRONTEND.IBAN_ISSUING.COMPANY_VALUE', 'Contact account manager')
                ) : (
                  <a href={settings.customerServiceUrl} rel='noopener noreferrer' target='_blank'>
                    {tr('FRONTEND.IBAN_ISSUING.INDIVIDUAL_VALUE', 'Customer Service')}
                  </a>
                )}
              </div>
            ) : isValidArray(fees) && isValidString(selectedCurrency) ? (
              <Fees>
                {fees.map((fee: any) => {
                  const { feeType, feeCurrency, value } = fee
                  return (
                    <FeeTitle key={feeType}>
                      <div>
                        {tr(
                          `FRONTEND.IBAN_ISSUING.${feeType}`,
                          feeType === 'IBAN_ISSUING_FEE' ? 'Issuing fee:' : 'Monthly maintenance fee:'
                        )}
                      </div>
                      <div className='fee'>{formatMoney(value, feeCurrency)}</div>
                    </FeeTitle>
                  )
                })}
              </Fees>
            ) : null}
          </div>
        </div>
      )}

      {step === 2 && (
        <div>
          <Input
            name='countryList'
            type='select'
            value={countryList}
            options={countryOptions}
            data-test='file-type'
            label={tr('FRONTEND.IBAN_QUESTIONNAIRE.FORM.COUNTRY', 'Countries from which you will be funding the IBAN')}
            onChange={(_name: any, value: any) => {
              setCountryListError('')
              setCountryList(value)
            }}
            loading={countriesFetching}
            placeholder=''
            isMultiSelect
            error={countryListError}
            required
          />

          <Input
            name='yearlyTransactionsAmountInEur'
            type='number'
            label={tr(
              'FRONTEND.IBAN_QUESTIONNAIRE.FORM.AMOUNT',
              'Average value of your transaction(s) (EUR) in a year(12 months)'
            )}
            required
            {...formValues.registerInput('yearlyTransactionsAmountInEur')}
          />

          <Input
            name='totalNumOfBankAccounts'
            type='number'
            label={tr(
              'FRONTEND.IBAN_QUESTIONNAIRE.FORM.AMOUNT',
              'How many bank accounts are you holding at the moment?'
            )}
            required
            {...formValues.registerInput('totalNumOfBankAccounts')}
          />

          <Input
            name='linkedBankAccountsCount'
            type='number'
            label={tr(
              'FRONTEND.IBAN_QUESTIONNAIRE.FORM.ACCOUNTS_NUMBER',
              'How many payment methods/bank accounts will be used to transact through this IBAN?'
            )}
            required
            {...formValues.registerInput('linkedBankAccountsCount')}
          />

          <Input
            name='occupation'
            type='text'
            label={tr('FRONTEND.IBAN_QUESTIONNAIRE.FORM.OCCUPATION', 'Occupation')}
            required
            {...formValues.registerInput('occupation')}
          />

          <Input
            name='purposeOfAccount'
            type='text'
            label={tr('FRONTEND.IBAN_QUESTIONNAIRE.FORM.PURPOSE', 'Purpose of this account')}
            required
            {...formValues.registerInput('purposeOfAccount')}
          />
        </div>
      )}

      <ErrorDisplay error={[ibanSchemeCurrenciesError, previewError, initiateError]} />

      <Buttons>
        <Button
          color='secondary'
          text={tr('FRONTEND.BUTTONS.CANCEL', 'Cancel')}
          onClick={() => {
            modalHide()
            resetMutations()
          }}
          disabled={previewFetching || initiateFetching}
          data-test='button-cancel'
        />
        <Button
          color='primary'
          text={tr('FRONTEND.BUTTONS.REQUEST', 'Request')}
          onClick={() => {
            if (isIndividual && step == 1) {
              setStep(2)
            } else {
              if (isIndividual && !isValidArray(countryList)) {
                setCountryListError(tr('VALIDATION.ERRORS.REQUIRED', 'This field should not be empty'))
              }
              if (isIndividual && (!formValues.areValid() || !isValidArray(countryList))) return
              handleRequest()
            }
          }}
          data-test='button-new-account'
          loading={previewFetching || initiateFetching}
          disabled={!isValidString(selectedScheme) || !isValidString(selectedCurrency) || !selectedAccount}
        />
      </Buttons>
    </IbanRequestModalWrapper>
  )
}
