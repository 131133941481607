import styled from 'styled-components'
import { ExpenseModalContainer } from '../../expenses/styles/expenseModal.styled'

export const DeleteModalWrapper = styled(ExpenseModalContainer)`
  min-width: 500px;
  padding: 4rem 5rem;

  @media (max-width: 530px) {
    min-width: 100%;
  }

  .input-wrapper {
    margin-bottom: 0;
  }

  .buttons-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.6rem;

    .button-primary,
    .button-secondary {
      width: 100%;
      max-width: 13rem;
      align-self: center;

      span {
        font-size: 1.6rem;
        font-weight: 400;
      }
    }

    .button-secondary {
      span {
        color: black;
        font-weight: 600;
      }
    }
  }
`

export const DeleteModalHeader = styled.h3`
  margin: 0;
  font-size: 2.4rem;
  font-weight: 400;
  text-align: center;
`
