import { useWindowSize, formatMoney } from 'mmfintech-commons'

import { VoucherDetails } from '../voucherDetails/VoucherDetail'
import { StatusBadgeComponent } from './StatusBadge'

import { OldVoucherItemAmountAndStatusWrapper, OldVoucherItemDetails, OldVoucherItemWrapper } from './OldVoucher.styled'

import { LabelValue } from '../../../components'
import { formatVoucherCode } from '../voucherCommons/VoucherCodeInput'

import { CashVoucherResponse } from 'mmfintech-commons-types'

import { ReactComponent as ExpiryIcon } from '../../../images/purchase-voucher/expiry-icon.svg'
import { ReactComponent as BarcodeIcon } from '../../../images/active-voucher/barcode.svg'

export const OldVoucherItem = ({
  amount,
  currency,
  expiryDate,
  voucherNumber,
  totalVouchers,
  status
}: CashVoucherResponse) => {
  const [width] = useWindowSize()
  const isMobile = width < 768

  return (
    <OldVoucherItemWrapper $status={status}>
      <OldVoucherItemAmountAndStatusWrapper>
        <LabelValue
          dataTest='voucher-amount'
          label='Amount'
          value={formatMoney(amount, currency, 2)}
          wrapperClassName='old-voucher-label amount-wrapper'
        />
        {isMobile && <StatusBadgeComponent status={status} />}
      </OldVoucherItemAmountAndStatusWrapper>

      <OldVoucherItemDetails>
        <VoucherDetails icon={<ExpiryIcon width={40} height={40} />} label='Expiration Date:' data={expiryDate} />

        {voucherNumber && (
          <VoucherDetails icon={<BarcodeIcon />} label='Voucher Number:' data={formatVoucherCode(voucherNumber)} />
        )}
        {totalVouchers && (
          <VoucherDetails icon={<BarcodeIcon />} label='Number of vouchers:' data={`${totalVouchers}`} />
        )}
      </OldVoucherItemDetails>

      {!isMobile && <StatusBadgeComponent status={status} />}
    </OldVoucherItemWrapper>
  )
}
