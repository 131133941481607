import { useMemo, useState } from 'react'
import moment from 'moment'

import { Input } from 'mmfintech-portal-commons'

import { useCurrencies } from 'mmfintech-backend-api'
import { checkSingleValue, isValidString, tr } from 'mmfintech-commons'

import { ReactComponent as CalendarIcon } from '../../../images/icons/filter-calendar.svg'
import { ReactComponent as SearchIcon } from '../../../images/icons/search-icon.svg'

import { FilterControls, Filters, ReportFiltersWrapper } from '../../../components'
import { FilterSelectStyles } from '../../transactions/TransactionsFilter/TransactionsFilter.styled'

const fieldsToOmit = new Set(['isOwner', 'state'])

const isValidField = (key: string, value: any) => {
  const isDateValid = value instanceof Date && !isNaN(value.valueOf())
  return (isValidString(value) || isDateValid) && !fieldsToOmit.has(key)
}

export const ReportFilters = ({ filter }) => {
  const [showFilters, setShowFilters] = useState(false)

  const { currencyOptions } = useCurrencies()

  const getCurrencyOptions = () => [
    { value: '', label: tr('FRONTEND.EXPENSES.FILTER.ALL', 'All') },
    ...currencyOptions()
  ]

  const validFieldsCount = useMemo(
    () =>
      Object.entries(filter.filterValues).reduce((count, [key, value]) => {
        return count + (isValidField(key, value) ? 1 : 0)
      }, 0),
    [filter.filterValues]
  )

  const handleShowFilters = () => setShowFilters(prev => !prev)

  return (
    <ReportFiltersWrapper>
      {showFilters && (
        <Filters>
          <Input
            type='text'
            name='search'
            label={tr('FRONTEND.EXPENSES.FILTER.SEARCH', 'Search')}
            {...filter.registerInput('search')}
            placeholder={tr('FRONTEND.EXPENSES.FILTER.NAME_DESCRIPTION', 'Name, Description')}
            icon={<SearchIcon />}
            iconPosition='end'
            data-test='filter-search'
            hideErrorLine
          />
          <Input
            type='date'
            name='from'
            {...filter.registerInput('from')}
            maxDate={filter.get('to')}
            icon={<CalendarIcon />}
            label={tr('FRONTEND.EXPENSES.FILTER.FROM', 'From')}
            dateFormat='MM/dd/yyyy'
            hideErrorLine
          />
          <Input
            type='date'
            name='to'
            minDate={filter.get('from')}
            maxDate={moment()}
            icon={<CalendarIcon />}
            label={tr('FRONTEND.EXPENSES.FILTER.TO', 'To')}
            {...filter.registerInput('to')}
            dateFormat='MM/dd/yyyy'
            hideErrorLine
          />
          <Input
            type='text'
            name='fromAmount'
            label={tr('FRONTEND.EXPENSES.FILTER.FROM_AMOUNT', 'From Amount')}
            placeholder={tr('FRONTEND.EXPENSES.FILTER.AMOUNT', 'Amount')}
            value={filter.get('fromAmount')}
            onChange={(name: string, value: string) => {
              filter.set(name, checkSingleValue(value, { validation: 'float' }))
              filter.reload()
            }}
            hideErrorLine
          />
          <Input
            type='text'
            name='toAmount'
            label={tr('FRONTEND.EXPENSES.FILTER.TO_AMOUNT', 'To Amount')}
            placeholder={tr('FRONTEND.EXPENSES.FILTER.AMOUNT', 'Amount')}
            value={filter.get('toAmount')}
            onChange={(name: string, value: string) => {
              filter.set(name, checkSingleValue(value, { validation: 'float' }))
              filter.reload()
            }}
            hideErrorLine
          />
          <Input
            type='select'
            name='currency'
            styles={FilterSelectStyles}
            className='currency'
            label={tr('FRONTEND.EXPENSES.FILTER.CURRENCY', 'Currency')}
            {...filter.registerInput('currency')}
            options={getCurrencyOptions()}
            hideErrorLine
          />
        </Filters>
      )}
      <FilterControls
        filter={filter}
        validFieldsCount={validFieldsCount}
        handleShowFilters={handleShowFilters}
        showFilters={showFilters}
        resetProps={['states']}
      />
    </ReportFiltersWrapper>
  )
}
