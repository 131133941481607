import styled from 'styled-components'

export const FilterWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-gap: 3rem;
  grid-template-columns: 1fr;
  margin-bottom: 1rem;

  @media (min-width: 1024px) {
    grid-template-columns: 1fr auto;
  }
`

export const FilterButtons = styled.div`
  grid-row: 1;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;

  .button {
    height: 3.5rem;

    font-size: 1.4rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.7rem;

    .icon {
      svg {
        height: 18px;
      }
    }

    &#clear-all {
      background: #ffffff;
      border: 1px solid rgba(255, 49, 49, 0.7);
      border-radius: 4px;
      box-shadow: 0 0 25px rgba(46, 53, 56, 0.1);
      color: #ff3131;
    }

    &#clear-filters {
      background: rgba(255, 49, 49, 0.1);
      border-radius: 4px;
      color: #ff3131;
    }
  }

  @media (min-width: 640px) {
    justify-content: flex-end;
  }
  @media (min-width: 1024px) {
    padding-top: 2rem;
    grid-row: auto;
  }
`

export const FiltersContainer = styled.div`
  display: flex;
  flex-flow: row wrap;

  .input-wrapper {
    margin-bottom: 2rem;
    margin-right: 1.5rem;

    & > div {
      background: #ffffff;
      border-radius: 4px;
      border: 1px solid rgba(133, 160, 173, 0.3);
      padding: 0;
    }

    label {
      color: #85a0ad;
      font-size: 1.2rem;
      font-weight: 400;
      line-height: 1.5rem;
      margin-bottom: 0.5rem;
      padding-left: 0;
      text-transform: none;
    }

    input {
      height: 3.5rem;
      font-size: 1.4rem;
      font-weight: 400;
      padding-left: 1rem;
      padding-right: 1rem;

      &::-webkit-input-placeholder {
        font-size: 1.4rem;
        font-weight: normal;
      }
      &:-ms-input-placeholder {
        font-size: 1.4rem;
        font-weight: normal;
      }
      &::placeholder {
        font-size: 1.4rem;
        font-weight: normal;
      }
    }
  }

  [data-test='date-input-from'],
  [data-test='date-input-to'] {
    max-width: 14rem;

    & > div {
      height: 3.5rem;
      padding: 0 1rem;

      .icon {
        margin-right: 0;
      }

      input {
        cursor: pointer;
      }
    }
  }

  [data-test='number-input-id'] {
    max-width: 10rem;
  }
  [data-test='text-input-foreignTransactionId'] {
    max-width: 14rem;
  }
  [data-test='text-input-reference'] {
    max-width: 14rem;
  }
  [data-test='text-input-customerEmail'],
  [data-test='text-input-description'] {
    max-width: 22rem;
  }
  [data-test='text-input-toAmount'],
  [data-test='text-input-fromAmount'] {
    max-width: 14rem;
  }
  [data-test='select-input-statuses'] {
    max-width: 16rem;
    & > div {
      padding-left: 0;
      padding-right: 0;
    }
  }
  [data-test='select-input-currency'] {
    max-width: 10rem;
    & > div {
      padding-left: 0;
      padding-right: 0;
    }
  }
  [data-test='select-input-paymentMethods'] {
    width: auto;
    min-width: 20rem;
    & > div {
      padding-left: 0;
      padding-right: 0;
    }
  }
`

// noinspection DuplicatedCode
export const FilterSelectStyles = {
  container: (provided: any) => ({
    ...provided,
    width: '100%',
    fontFamily: 'inherit',
    fontSize: '1.4rem',
    lineHeight: '1.7rem',
    // background: '#000000',
    borderRadius: '4px'
  }),
  control: (provided: any) => ({
    ...provided,
    cursor: 'pointer',
    borderWidth: '1px',
    borderStyle: 'none',
    borderRadius: '4px',
    minHeight: '3.5rem',
    //paddingLeft: '1rem',
    padding: '0',
    transition: 'none',
    boxShadow: 'none',
    outline: 'none'
  }),
  multiValue: (provided: any) => ({
    ...provided,
    background: '#4b4b4b',
    borderRadius: '3px',
    color: '#ffffff'
  }),
  multiValueLabel: (provided: any) => ({
    ...provided,
    color: 'inherit'
  }),
  singleValue: (provided: any) => ({
    ...provided,
    color: 'inherit'
  }),
  valueContainer: (provided: any) => ({
    ...provided,
    color: 'black',
    padding: '0 8px'
  }),
  clearIndicator: (provided: any) => ({
    ...provided,
    padding: '0 8px 0 0'
  }),
  dropdownIndicator: (provided: any) => ({
    ...provided,
    padding: '0 8px 0 0',
    color: '#85A0AD'
  }),
  input: (provided: any) => ({
    ...provided,
    marginBottom: 0,
    marginTop: 0,
    paddingBottom: 0,
    paddingTop: 0
  }),
  placeholder: (provided: any) => ({
    ...provided,
    color: '#ffffff',
    fontSize: '1.4rem',
    fontWeight: 'normal',
    lineHeight: '120%'
  }),
  menu: (provided: any) => ({
    ...provided,
    background: '#ffffff',
    border: 'none !important',
    borderRadius: '1rem',
    margin: '1rem 0 0 0',
    padding: '1rem 0',
    boxShadow: '0 5px 30px rgba(172, 182, 191, 0.25)',
    ':hover': {
      border: 'none !important'
    },
    zIndex: 5
  }),
  option: (provided: any) => ({
    ...provided,
    color: '#000000',
    fontSize: '1.4rem',
    lineHeight: '120%',
    cursor: 'pointer',
    textAlign: 'left',
    background: '#ffffff',
    //marginTop: '5px',
    padding: '1rem 2rem',
    ':hover': {
      background: '#e7ebee'
    }
  })
}

export const CheckBoxGroupContainer = styled.div`
  background: #ffffff;
  border: 1px solid #e1e5ea;
  border-radius: 10px;
  padding: 2rem 3rem;
`
