import { motion } from 'framer-motion';
import styled from 'styled-components';

export const StyledTab = styled.div`
  position: relative; // Added to ensure the z-index works correctly
  z-index: 1; // Ensure the tab content is above the pseudo-element
  flex: 1; // Distribute space evenly between tabs
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: none;

  text-decoration: none !important;

  box-sizing: border-box;
  min-height: 4.4rem;

  border-radius: 1rem;
`;

export const StyledTabs = styled.div`
  position: relative; // Added for positioning the pseudo-element
  display: flex;
  box-sizing: border-box;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  padding: 0.4rem;
  background-color: #EDEDED;
  border-radius: 1.2rem;
  width: 100%;

  @media (max-width: 1024px) {
    width: 90%;
  }

  @media (max-width: 320px) {
    min-width: 26rem;
    width: 90%;
  }

  @media (max-width: 500px) {
    min-width: 30rem;
    width: 90%;
  }
`;

export const AnimatedBg = styled(motion.div)`
  position: absolute;
  box-sizing: border-box;
  left: 0.4rem;
  background-color: #fff;
  min-height: 4.4rem;
  box-shadow: 0px 4px 7px 0px rgba(182, 179, 213, 0.28);
  border-radius: 1rem;
  z-index: 0; // Ensure it's below the tab content
`;

export const TabsWrapper = styled.div`
  display: flex;
  box-sizing: border-box;
  padding-left: 1rem;
  padding-right: 1rem;
  max-width: 30rem;
  width: 100%;
  margin: 2rem 0;

  justify-content: center;

  @media (max-width: 480px) {
    width: 100%;
    margin: 2rem 0;
    padding-left: 0;
    padding-right: 0;
  }

  @media (max-width: 320px) {
    font-size: 14px;
  }
`;
