import styled from 'styled-components'

export const SuccessContainer = styled.div`
  background: #ffffff;
  border-radius: 3px;
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.08);

  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;

  &.centered {
    align-items: center;

    img {
      margin-bottom: 2rem;
    }

    .message {
      margin-top: 1rem;
      margin-bottom: 4rem;
    }

    button {
      max-width: 20rem;
      background-color: #000000;
      text-transform: uppercase;
      &:hover {
        background-color: #1a1a1a;
      }
    }
  }

  width: 100%;
  max-width: 50rem;
  margin-bottom: 2rem;

  font-size: 1.4rem;

  padding: 2rem;

  @media (min-width: 762px) {
    padding: 4rem 5rem;
  }

  .info {
    margin-top: 2rem;
    margin-bottom: -1rem;
    span {
      margin-left: 1rem;

      :first-of-type {
        margin-left: 0;
      }
    }
  }
`

export const SuccessSubtitle = styled.div`
  width: 100%;

  color: #000000;
  font-size: 2.4rem;
  font-style: normal;
  font-weight: normal;
  line-height: 2.9rem;
  text-align: center;

  margin-bottom: 4rem;
`
