import { shallowEqual, useSelector } from 'react-redux'

import ConversationsBanner from '../Conversation/ConversationsBanner'

import { ErrorDisplay } from 'mmfintech-portal-commons'
import { GraphSection } from './GraphSection'
import { AccountSection } from './AccountSection'
import { DashboardTitle } from '../Dashboard.styled'
import { OnboardingBanner } from '../OnboardingBanner'
import { TransactionsSection } from '../TransactionsSection'
import { DashboardBanners, DashboardContainer, DashboardHeader } from './AdminDashboard.styled'

import { tr, useWindowSize } from 'mmfintech-commons'

export const AdminDashboard = () => {
  const { aggregatedBalance, aggregatedBalanceError, merchant } = useSelector(
    ({ user: { aggregatedBalance, aggregatedBalanceError, merchant } }: any) => ({
      merchant,
      aggregatedBalance,
      aggregatedBalanceError
    }),
    shallowEqual
  )

  const { capabilities } = merchant || {}
  const { enablePortalWalletCheckout } = capabilities || {}

  const [width] = useWindowSize()

  const transactionFilter = {
    page: 0,
    size: 5,
    sort: 'reqTime-desc,id-desc'
  }

  return (
    <DashboardContainer data-test='admin-dashboard'>
      <DashboardHeader>
        {width > 767 && <DashboardTitle className='title'>{tr('FRONTEND.DASHBOARD.TITLE', 'Overview')}</DashboardTitle>}
        <DashboardBanners>
          <ConversationsBanner />
          <OnboardingBanner className='dashboard' />
        </DashboardBanners>
      </DashboardHeader>

      <AccountSection aggregatedBalance={aggregatedBalance} enablePortalWalletCheckout={enablePortalWalletCheckout} />

      <ErrorDisplay error={aggregatedBalanceError} />

      <GraphSection />

      <TransactionsSection filter={transactionFilter} />
    </DashboardContainer>
  )
}
