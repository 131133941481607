import React, { useContext } from 'react'
import { GlobalContext, formatMoney } from 'mmfintech-commons'
import { Button } from 'mmfintech-portal-commons'

import { formatVoucherCode } from '../voucherCommons/VoucherCodeInput'
import { VoucherDetails } from '../voucherDetails/VoucherDetail'
import { RevealPin } from './RevealPin'
import { LabelValue } from '../../../components'
import { RedeemModal } from '../redeemVoucherTab/RedeemModal'
import { StatusBadgeComponent } from '../oldVoucher/StatusBadge'

import { ActiveVouchersDetails, ActiveVouchersItemWrapper } from './SingleVoucher.styled'

import { CashVoucherBulkPortalResponse, CashVoucherStatusEnum } from 'mmfintech-commons-types'

import { ReactComponent as BarcodeIcon } from '../../../images/active-voucher/barcode.svg'
import { ReactComponent as ExpiryIcon } from '../../../images/purchase-voucher/expiry-icon.svg'
import { ReactComponent as RedeemIcon } from '../../../images/active-voucher/redeem-voucher.svg'
import { ReactComponent as ExportIcon } from '../../../images/active-voucher/download-csv-vouchers.svg'
import { useDownloadVoucherCsv } from './useDownloadVoucherCsv'

export const ActiveVouchersItem: React.FC<CashVoucherBulkPortalResponse> = ({
  amount,
  currency,
  voucherNumber,
  expiryDate,
  uuid,
  status,
  activeVouchers,
  totalVouchers,
  slipId
}) => {
  const isBulk = Boolean(activeVouchers && totalVouchers)
  const { modalShow } = useContext(GlobalContext)
  const { handleExportAsCsv, transactionsExportFetching } = useDownloadVoucherCsv({ slipId })

  const handleRedeemClick = () => {
    modalShow({
      content: <RedeemModal voucherId={uuid} currency={currency} />,
      options: { overflow: 'visible' }
    })
  }

  return (
    <ActiveVouchersItemWrapper $isBulk={isBulk}>
      <div className='header-wrapper'>
        <div className='header-content'>
          <LabelValue
            dataTest='voucher-amount'
            label={'Voucher Amount' /*todo: localize*/}
            value={formatMoney(amount, currency)}
            wrapperClassName='amount-wrapper'
          />
          {isBulk ? (
            <div className='total-vouchers'>
              <p>{totalVouchers}</p>
              <span>{'Number of Vouchers' /*todo: localize*/}</span>
            </div>
          ) : (
            <RevealPin voucherId={uuid} />
          )}
        </div>
      </div>
      <div className='content'>
        <h3 className='voucher-details-title'>Voucher Details</h3>
        <ActiveVouchersDetails>
          <VoucherDetails icon={<ExpiryIcon width={40} height={40} />} label='Expiration Date:' data={expiryDate} />
          {!isBulk && (
            <VoucherDetails
              icon={<BarcodeIcon />}
              label={'Voucher Number:' /*todo: localize*/}
              data={formatVoucherCode(voucherNumber)}
              allowCopy
            />
          )}
          {status === CashVoucherStatusEnum.ACTIVE ? (
            isBulk ? (
              <Button
                className='action-voucher-button'
                text={'Export as CSV' /*todo: localize*/}
                loading={transactionsExportFetching}
                onClick={handleExportAsCsv}
                icon={<ExportIcon />}
                iconPosition='left'
              />
            ) : (
              <Button
                className='action-voucher-button'
                text={'Redeem Voucher' /*todo: localize*/}
                onClick={handleRedeemClick}
                icon={<RedeemIcon />}
                iconPosition='left'
              />
            )
          ) : (
            <StatusBadgeComponent status={status} />
          )}
        </ActiveVouchersDetails>
      </div>
    </ActiveVouchersItemWrapper>
  )
}
