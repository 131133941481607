import { shallowEqual, useSelector } from 'react-redux'

import { isNotEmptyString, isValidObject, redirectToExternalUrl } from 'mmfintech-commons'

import { PixPreview } from './PixPreview'
import { CryptoPreview } from './CryptoPreview'
import { IframePreview } from './IframePreview'
import { MessagePreview } from './MessagePreview'
import { BankwirePreview } from './BankwirePreview'
import { PerfectMoneyPreview } from './PerfectMoneyPreview'
import { CryptoApplicationFeePreview } from './CryptoApplicationFeePreview'
import { RedeemVoucherSuccess } from '../../cashVoucher/redeemVoucherTab'

import { PaymentResultTypeEnum } from 'mmfintech-commons-types'

interface DepositResultProps {
  response: unknown
  onStepBack?: () => void
  isAmountRequired?: boolean
}

export const DepositResult = ({ response, onStepBack, isAmountRequired = true }: DepositResultProps) => {
  const { result, resultType, redirectUrl, processingAmount, processingCurrency, trnId }: any = response || {}

  const { depositPreview, invoice } = useSelector(
    ({ banking: { depositPreview }, invoices: { invoice } }: any) => ({ depositPreview, invoice }),
    shallowEqual
  )

  switch (resultType) {
    case PaymentResultTypeEnum.MESSAGE:
      return <MessagePreview message={result} />

    case PaymentResultTypeEnum.REDIRECT_URL:
      if (typeof result === 'string') {
        redirectToExternalUrl(result)
      } else {
        const { redirectUrl } = result || {}
        redirectToExternalUrl(redirectUrl)
      }
      return null

    case PaymentResultTypeEnum.QR_CODE:
      return <PixPreview address={result} />

    case PaymentResultTypeEnum.CASH_VOUCHER:
      return <RedeemVoucherSuccess />

    case PaymentResultTypeEnum.BANKWIRE_DETAILS:
      const { total: invoiceTotal, currency: invoiceCurrency } = invoice || {}
      const { paymentType }: any = response || {}
      return (
        <BankwirePreview
          result={{
            paymentType,
            ...(processingAmount
              ? { processingAmount, processingCurrency }
              : { processingAmount: invoiceTotal, processingCurrency: invoiceCurrency }),
            ...depositPreview,
            ...result
          }}
          isAmountRequired={isAmountRequired}
        />
      )

    case PaymentResultTypeEnum.CRYPTO_ADDRESS_DETAILS:
    case PaymentResultTypeEnum.CHECKOUT_CRYPTO_ADDRESS_DETAILS:
      return (
        <CryptoPreview
          result={{
            ...depositPreview,
            ...result,
            ...(processingAmount ? { processingAmount, processingCurrency } : null)
          }}
          onStepBack={onStepBack}
        />
      )

    case PaymentResultTypeEnum.IFRAME_URL:
      return <IframePreview url={result} />

    // INSTRUCTIONS
    // result: { reference, redirectUrl }, externalId, status=PENDING
    // reference and externalId contain transactionId

    // WALLET_DEPOSIT
    // qrCode, redirectUrl, hostedQrPage

    // INTERNAL_EXECUTION -- used for Instant bank transfer with orchestrator currently

    case 'APPLICATION_FEE_CRYPTO_DETAILS':
      return <CryptoApplicationFeePreview {...depositPreview} />

    default:
    // do nothing
  }

  if (isValidObject(depositPreview) && 'perfectMoneyFormData' in depositPreview) {
    return <PerfectMoneyPreview trnId={trnId} />
  }

  if (isNotEmptyString(redirectUrl)) {
    redirectToExternalUrl(redirectUrl)
  }

  return null
}
