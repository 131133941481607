export const ActivitiesIcon = ({ ...rest }) => (
  <span {...rest}>
    <svg width='30' height='30' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <circle opacity='0.6' cx='15' cy='15' r='14.5' stroke='#A7B3BE' />
      <g opacity='0.6'>
        <path
          d='M10.4706 11.8175C10.4706 9.31597 12.4985 7.28809 15 7.28809V7.28809C17.5015 7.28809 19.5294 9.31597 19.5294 11.8175V13.4089C19.5294 14.7408 19.9639 16.0364 20.7669 17.0991L20.9421 17.3309C21.3769 17.9062 20.9664 18.7308 20.2453 18.7308H9.75473C9.03356 18.7308 8.62313 17.9062 9.05792 17.3309L9.23308 17.0991C10.0361 16.0364 10.4706 14.7408 10.4706 13.4089V11.8175Z'
          stroke='#D13737'
        />
        <path
          d='M13.0099 20.4855C13.1272 20.9199 13.3857 21.3039 13.7452 21.5777C14.1048 21.8515 14.5454 21.9999 14.9986 21.9999C15.4518 21.9999 15.8923 21.8515 16.2519 21.5777C16.6115 21.3039 16.8699 20.9199 16.9872 20.4855'
          stroke='#D13737'
          strokeLinecap='round'
        />
      </g>
    </svg>
  </span>
)
