import { configuration } from 'mmfintech-backend-api'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import ReactGA4 from 'react-ga4'

export const usePageTracking = () => {
  const location = useLocation()

  useEffect(() => {
    if (configuration.isProduction()) {
      ReactGA4.gtag('event', 'page_view', {
        page_path: location.pathname + location.search
      })
    }
  }, [location])
}
