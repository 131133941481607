import styled from 'styled-components'

import EmptyIcon from '../../images/icons/checkbox-group-empty.svg'
import CheckedIcon from '../../images/icons/checkbox-group-checked.svg'

export const CheckBoxGroupWrapper = styled.div`
  width: 100%;

  color: #1a1a1a;
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
`

export const CheckBoxGroupFooter = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  margin-top: 2rem;
`

export const CheckBoxGroupHeader = styled.div`
  font-weight: 500;
  margin-bottom: 2rem;
`

export const CheckBoxGroupItems = styled.div``

export const CheckBoxGroupItem = styled.div`
  width: 100%;
  height: 4.4rem;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #dcdfe3;
  white-space: nowrap;

  :first-child {
    border-top: 1px solid #dcdfe3;
  }

  .checkbox {
    cursor: pointer;
    display: inline-block;

    width: 16px;
    height: 16px;
    margin-left: 4rem;

    background-image: url(${EmptyIcon});
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    &.checked {
      background-image: url(${CheckedIcon});
    }
  }
`
