import styled from 'styled-components'

const NewsModalWrapper = styled.div`
  border-radius: 2rem;
  background-color: #ffffff;
  padding-bottom: 4rem;
  display: flex;
  flex-flow: column nowrap;
  gap: 4rem;
  .fallback-icon {
    margin-top: 4rem;
    align-self: center;
  }

  .inner,
  .buttons-container {
    padding: 0 4rem;
  }
  .inner {
    word-wrap: break-word;
    h3 {
      font-size: 2.4rem;
      font-weight: 600;
    }
    p {
      white-space: pre-wrap;
      font-size: 1.6rem;
    }
    .bullets-container {
      display: flex;
      gap: 1.6rem;
      justify-content: center;
      margin: 2.4rem 0;
      .bullet {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: #e1e1e1;
        cursor: pointer;
      }
      .bullet.active {
        background-color: #1a1a1a;
      }
    }
  }
  .buttons-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas: 'left right';
    align-items: center;
    gap: 2rem;
    [data-test='previous-button'] {
      grid-area: left;
    }
    [data-test='next-button'] {
      grid-area: right;
    }

    .button-secondary:disabled {
      background-color: #ffffff;
      box-shadow: none;
      color: #1a1a1a;
    }
  }
`

const NewsImage = styled.div<{ image?: string }>`
  width: 100%;
  height: 25rem;
  background-image: url(${props => props.image});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;

  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
`

export { NewsModalWrapper, NewsImage }
