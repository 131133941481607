import { useContext } from 'react'

import { GlobalContext } from 'mmfintech-commons'
import { Button, ErrorDisplay, Input } from 'mmfintech-portal-commons'
import {
  AccountCreateButtons,
  AccountCreateForm,
  AccountCreateTitle,
  AccountCreateWrapper
} from './AccountCreateModal.styled'

import { tr } from 'mmfintech-commons'
import { useAccountCreateQuery } from 'mmfintech-backend-api'

interface AccountCreateModalProps {
  filteredCurrencies?: any[]
  onSuccess: (currency?: string, accountId?: number) => void
}

export const AccountCreateModal = ({ filteredCurrencies, onSuccess }: AccountCreateModalProps) => {
  const { modalHide } = useContext(GlobalContext)

  // const { currencies, formValues, createAccountError, createAccountFetching, handleSubmit } = useAccountCreate({
  //   filteredCurrencies,
  //   onSuccess
  // })
  const { currencies, formValues, createAccountError, createAccountFetching, handleSubmit } = useAccountCreateQuery({
    filteredCurrencies,
    onSuccess
  })

  return (
    <AccountCreateWrapper data-test='create-account-modal'>
      <AccountCreateTitle className='title'>
        {tr('FRONTEND.ACCOUNTS.CREATE.TITLE', 'Add New Account')}
      </AccountCreateTitle>

      <AccountCreateForm noValidate>
        <Input
          type='select'
          data-test='currency'
          label={tr('FRONTEND.ACCOUNTS.CREATE.CURRENCY_LABEL', 'Currency')}
          options={currencies}
          onChange={(name: string, value: string) => formValues.setValue(name, value)}
          {...formValues.registerShort('currency')}
          menuPosition='fixed'
        />

        <Input
          type='text'
          data-test='account-name'
          label={tr('FRONTEND.ACCOUNTS.CREATE.NAME_LABEL', 'Account Name')}
          {...formValues.registerInput('name')}
        />

        <ErrorDisplay error={createAccountError} />

        <AccountCreateButtons>
          <Button
            type='button'
            color='primary'
            text={tr('FRONTEND.ACCOUNTS.CREATE.SUBMIT_BUTTON', 'Add')}
            loading={createAccountFetching}
            data-test='button-submit'
            onClick={handleSubmit}
          />

          <Button
            type='button'
            color='secondary'
            text={tr('FRONTEND.BUTTONS.CANCEL', 'Cancel')}
            disabled={createAccountFetching}
            data-test='button-cancel'
            onClick={modalHide}
          />
        </AccountCreateButtons>
      </AccountCreateForm>
    </AccountCreateWrapper>
  )
}
