import { useHistory } from 'react-router-dom'

import QRCode from 'qrcode.react'
import parse from 'html-react-parser'

import { Button } from 'mmfintech-portal-commons'
import { LabelValue, WarningMessage } from '../../../../components'
import { CryptoPreviewTitle, CryptoPreviewWrapper, FeeWrapper } from './CryptoPreview.styled'

import { actions, useCurrencies } from 'mmfintech-backend-api'
import { cryptoCurrencyNotes, formatFloat, formatMoney, tr } from 'mmfintech-commons'

export const CryptoPreview = ({ result, onStepBack }) => {
  const { fee, feeCurrency, processingAmount, currency, address, tag } = result || {}

  const history = useHistory()

  const { getCurrencyPrecision } = useCurrencies()

  const handleCloseClick = () => actions.routing.returnFromDeposit(history)

  const formatFee = () =>
    ' <span data-test="fee-amount">' + formatMoney(fee, feeCurrency, getCurrencyPrecision(feeCurrency)) + '</span>'

  const notes = cryptoCurrencyNotes(currency)

  return (
    <>
      <CryptoPreviewWrapper>
        <CryptoPreviewTitle>{tr('FRONTEND.DEPOSIT.CRYPTO_DETAILS.TITLE', 'Details')}</CryptoPreviewTitle>

        <LabelValue
          label={tr('FRONTEND.DEPOSIT.CRYPTO_DETAILS.ADDRESS', 'Deposit address')}
          value={address}
          enableCopy={true}
          dataTest='address'
        />

        <p className='text-center code'>
          <QRCode value={address} renderAs='svg' size={256} />
        </p>

        <LabelValue
          label={tr('FRONTEND.DEPOSIT.CRYPTO_DETAILS.TAG', 'Tag')}
          value={tag}
          dataTest='tag'
          enableCopy={true}
        />

        {processingAmount && (
          <LabelValue
            label={tr('FRONTEND.BANKING.COMMON.AMOUNT_LABEL', 'Amount')}
            value={`${formatFloat(processingAmount, getCurrencyPrecision(currency))} ${currency}`}
            dataTest='amount'
            enableCopy={true}
            copyValue={formatFloat(processingAmount, getCurrencyPrecision(currency))}
          />
        )}
        {fee > 0 && (
          <FeeWrapper style={{ marginTop: '-1.5rem' }}>
            {parse(tr('FRONTEND.BANKING.COMMON.FEE_INCLUDED', 'Fee %FEE% included').replace('%FEE%', formatFee()))}
          </FeeWrapper>
        )}

        <div className='mb-2' />

        {typeof onStepBack === 'function' ? (
          <Button
            type='button'
            color='secondary'
            text={tr('FRONTEND.BUTTONS.BACK', 'Back')}
            onClick={onStepBack}
            data-test='button-step-back'
          />
        ) : null}
        <Button
          type='button'
          color='primary'
          text={tr('FRONTEND.BUTTONS.CLOSE', 'Close')}
          onClick={handleCloseClick}
          data-test='button-close'
        />
      </CryptoPreviewWrapper>

      {notes ? (
        <WarningMessage title={tr('FRONTEND.DEPOSIT.CRYPTO_DETAILS.PLEASE_NOTE', 'Please note')} lines={notes} />
      ) : null}
    </>
  )
}
