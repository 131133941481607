import React from 'react'
import { useHistory } from 'react-router-dom'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'

import { Button } from 'mmfintech-portal-commons'
import { CloseIcon } from '../CloseIcon'
import { IHamburgerMenuProps } from './HamburgerMenu.types'
import { HamburgerMenuWrapper } from './HamburgerMenu.styled'

import { tr } from 'mmfintech-commons'
import { actions, paths } from 'mmfintech-backend-api'

import { LoginStatusEnum } from 'mmfintech-commons-types'

export const HamburgerMenu = ({ opened, maxWidth, hideMenu, Items }: IHamburgerMenuProps) => {
  const { userStatus } = useSelector(
    ({ user: { userStatus } }: any) => ({
      userStatus
    }),
    shallowEqual
  )
  const history = useHistory()
  const dispatch = useDispatch()
  const isLoggedIn = () => userStatus === LoginStatusEnum.LOGGED_IN

  const handleLogoutClick = (e: React.SyntheticEvent) => {
    e.preventDefault()
    hideMenu()
    localStorage.removeItem('cookieTimestamp')
    history.push(paths.login())
    dispatch(actions.auth.logout())
    return false
  }

  return (
    <HamburgerMenuWrapper open={opened} maxMobileWidth={maxWidth}>
      <CloseIcon onClick={hideMenu} />
      <div className='items-container'>{isLoggedIn() && <Items />}</div>
      <div className='action-button'>
        {isLoggedIn() && (
          <Button
            type='button'
            color='primary'
            text={tr('FRONTEND.HEADER.LOGOUT', 'Log Out')}
            data-test='header-logout'
            onClick={handleLogoutClick}
          />
        )}
        {!isLoggedIn() && (
          <Button
            type='button'
            color='primary'
            text={tr('FRONTEND.HEADER.LOGIN', 'Log in')}
            data-test='header-login'
            onClick={() => {
              history.push(paths.login())
              hideMenu()
            }}
          />
        )}
        {!isLoggedIn() && (
          <Button
            type='button'
            color='primary'
            text={tr('FRONTEND.HEADER.SIGNUP', 'Sign Up')}
            data-test='header-signup'
            onClick={() => {
              history.push(paths.signUp())
              hideMenu()
            }}
          />
        )}
      </div>
    </HamburgerMenuWrapper>
  )
}
