import { useSelector, shallowEqual } from 'react-redux'
import { useLocation, useHistory, Switch, Route } from 'react-router-dom'

import { paths } from 'mmfintech-backend-api'
import { Preloader } from 'mmfintech-portal-commons'

import { AccessDenied, CommonPageTitle } from '../../components'

import { BuyVouchersTab } from './buyVoucherTab/BuyVouchersTab'
// import { RedeemVoucherTab } from './redeemVoucherTab/RedeemVoucherTab'
import { MyVouchers } from './myVouchersTab/MyVouchersTab'

import { CashTabs, CashVoucherWrapper } from './CashVoucher.styled'
import { Tab } from '../../components/SpendingBar/styles/Requests.styled'
import { RedeemVoucherTab } from './redeemVoucherTab'

type Tabs = 'buy' | 'redeem' | 'list'
const tabConfig: { title: string; name: Tabs }[] = [
  {
    title: 'Buy New', //todo: localize
    name: 'buy'
  },
  {
    title: 'Redeem',
    name: 'redeem'
  },
  {
    title: 'My Vouchers',
    name: 'list'
  }
]

const CashVoucher = () => {
  const location = useLocation()
  const history = useHistory()
  const { merchant } = useSelector(
    ({ user: { merchant } }: any) => ({
      merchant
    }),
    shallowEqual
  )
  const { capabilities } = merchant || {}

  if (!merchant) {
    return <Preloader />
  }
  if (capabilities && !('enableCashVouchers' in capabilities)) {
    return <AccessDenied />
  }
  const handleTabClick = (tabName: Tabs) => {
    history.push(paths.more.cashVoucher(tabName))
  }
  return (
    <CashVoucherWrapper>
      <CommonPageTitle>Kingdom Cash</CommonPageTitle>
      <CashTabs>
        {tabConfig.map(tab => {
          return (
            <Tab key={tab.name} active={location.pathname.includes(tab.name)} onClick={() => handleTabClick(tab.name)}>
              {tab.title}
            </Tab>
          )
        })}
      </CashTabs>
      <Switch>
        <Route exact path={paths.more.cashVoucher('buy')}>
          <BuyVouchersTab />
        </Route>
        <Route exact path={paths.more.cashVoucher('redeem')}>
          <RedeemVoucherTab />
        </Route>
        <Route exact path={paths.more.cashVoucher('list')}>
          <MyVouchers />
        </Route>
      </Switch>
    </CashVoucherWrapper>
  )
}

export default CashVoucher
