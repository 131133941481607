import React, { useEffect } from 'react'

import { Link } from 'react-router-dom'
import { shallowEqual, useSelector } from 'react-redux'

import { tr } from 'mmfintech-commons'
import { paths } from 'mmfintech-backend-api'

import { Button, ErrorDisplay, Input } from 'mmfintech-portal-commons'
import { LoginContent, LoginContentWrapper, SignupLinkWrapper, StyledForm } from './LoginForm.styled'
import { LoginStatusEnum } from 'mmfintech-commons-types'

export const LoginForm = ({
  styles,
  alternative,
  backButtonHandler,
  formValues,
  handleSubmit
}: {
  styles?: React.CSSProperties
  alternative?: boolean
  backButtonHandler?: any
  formValues: any
  handleSubmit: any
}) => {
  const { fetching, otpRequestError, loginError, loginFetching, userStatus, userId } = useSelector(
    ({
      otp: { fetching, otpRequestError },
      user: { loginError, loginFetching, selectedLanguage, userStatus, userId }
    }: any) => ({
      fetching,
      otpRequestError,
      loginError,
      loginFetching,
      selectedLanguage,
      userStatus,
      userId
    }),
    shallowEqual
  )

  useEffect(() => {
    if (userStatus === LoginStatusEnum.LOGGED_IN && userId) {
      localStorage.setItem('cookieTimestamp', userId)
    }
  }, [userStatus, userId])

  return (
    <LoginContentWrapper>
      <LoginContent style={{ ...styles }} className={alternative ? 'alternative' : ''}>
        <div className='heading'>{tr('FRONTEND.LOGIN.TITLE', 'Sign In')}</div>

        <StyledForm noValidate onSubmit={handleSubmit}>
          <Input
            id='email'
            type='email'
            data-test='email'
            label={tr('FRONTEND.LOGIN.EMAIL', 'E-mail Address')}
            {...formValues?.registerInput('email')}
            disabled={fetching || loginFetching}
            hideErrorLine
            hideRequired
            autoFocus
          />

          <Input
            id='password'
            type='password'
            data-test='password'
            label={tr('FRONTEND.LOGIN.PASSWORD', 'Password')}
            {...formValues?.registerInput('password')}
            disabled={fetching || loginFetching}
            autoComplete='off'
            hideErrorLine
            hideRequired
          />

          <div className='link-wrap' data-test='forgot-password'>
            <Link to={paths.forgotPassword()} data-test='forgot-password-link'>
              {tr('FRONTEND.LOGIN.FORGOT_PASSWORD', 'Forgot password?')}
            </Link>
          </div>

          <ErrorDisplay error={[loginError, otpRequestError]} />

          <Button
            type='submit'
            color='alternative'
            text={tr('FRONTEND.LOGIN.BUTTON_LOGIN', 'Log in')}
            loading={fetching || loginFetching}
            data-test='button-submit'
          />
          {backButtonHandler && (
            <Button
              color='secondary'
              className='back-button'
              text={tr('FRONTEND.BUTTONS.BACK', 'Back')}
              onClick={backButtonHandler}
              data-test='button-back'
            />
          )}
        </StyledForm>
      </LoginContent>

      <SignupLinkWrapper alternative={alternative}>
        {tr('FRONTEND.LOGIN.NO_ACCOUNT', "Don't have an account?")}
        <Link
          to={paths.signUp()}
          data-test='sign-up'
          target={alternative ? '_blank' : '_self'}
          rel={alternative ? 'noopener noreferrer' : ''}>
          <span>{tr('FRONTEND.LOGIN.SIGNUP_BUTTON', 'Sign up')} →</span>
        </Link>
      </SignupLinkWrapper>
    </LoginContentWrapper>
  )
}

export default LoginForm
