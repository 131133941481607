import styled from 'styled-components'

import IconClose from '../../../images/icons/icon-close.svg'

export const ManageCookiesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  color: #000000;

  position: relative;

  padding: 1.5625rem;

  .close-icon {
    top: 1rem;
    right: 1rem;

    @media (max-width: 600px) {
      :hover {
        color: #bdbdbd;
        background: url(${IconClose}) center center no-repeat;
      }
    }

    @media (min-width: 500px) {
      top: 2rem;
      right: 2rem;
    }

    @media (min-width: 600px) {
      width: 4.2rem;
      height: 4.2rem;
    }
  }

  @media (min-width: 600px) {
    padding: 2.5rem;
  }

  @media (min-width: 800px) {
    padding: 3.5rem;
  }
`

export const ManageCookiesTitleWrapper = styled.div`
  display: flex;
  align-items: center;

  margin: 1.5rem 0 1.5rem 0;

  div {
    display: flex;
    align-items: center;

    :first-child {
      margin-right: 1.25rem;
    }

    img,
    svg {
      width: 5rem;
    }

    h1 {
      font-size: 2.4rem;
      line-height: 2.4rem;
    }
  }

  @media (min-width: 600px) {
    margin: 0 0 3rem 0;
  }
`

export const NecessaryCookiesWrapper = styled.div`
  display: flex;

  margin-bottom: 4rem;

  div {
    :first-child {
    }

    :last-child {
      display: flex;
      align-items: flex-start;
    }

    h2 {
      margin: 0;
      font-size: 1.8rem;
      line-height: 2.2rem;
    }

    p {
      font-weight: 500;
      font-size: 1.6rem;
      line-height: 2rem;
      margin-right: 2rem;
    }
  }
`

export const OptionalCookiesWrapper = styled.div`
  display: flex;

  div {
    :first-child {
    }

    :last-child {
      display: flex;
      align-items: flex-start;
    }

    h2 {
      margin: 0;
      font-size: 1.8rem;
      line-height: 2.2rem;
    }

    p {
      font-weight: 500;
      font-size: 1.6rem;
      line-height: 2rem;
      margin-right: 2rem;
    }
  }
`

export const SaveButtonWrapper = styled.div`
  display: flex;
  justify-content: center;

  button {
    &.btn-consent {
      max-width: 23rem;
      margin-top: 2rem;
      font-weight: 600;
      font-size: 1.6rem;
      line-height: 1.6rem;
      color: #ffffff;
      background-color: #000000;
    }
  }
`
