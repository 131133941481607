import styled from 'styled-components'

import BankingServices from '../../images/icons/product-banking-services.svg'
import PaymentServices from '../../images/icons/product-payment-services.svg'
import ForexTrading from '../../images/icons/product-forex.svg'
import Other from '../../images/icons/product-other.svg'

import Checked from '../../images/icons/product-checkbox-checked.svg'
import Unchecked from '../../images/icons/product-checkbox-unchecked.svg'

export const CustomCheckboxWrapper = styled.div`
  width: 100%;
  margin-bottom: 1.5rem;
  padding: 1rem 2.5rem 1rem 2.2rem;

  background: #ffffff;
  box-shadow: 0 0 25px rgba(46, 53, 56, 0.1);

  color: #000000;
  font-family: inherit;
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.9rem;

  display: flex;
  flex-flow: column nowrap;
  align-items: center;

  &.space-after {
    margin-bottom: 3rem;
  }

  .checkbox-container {
  }
`

export const CustomCheckboxContainer = styled.div`
  width: 100%;
  min-height: 5rem;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;

  .checkbox {
    width: 20px;
    height: 20px;
    cursor: pointer;
    background: url(${Unchecked}) center no-repeat;

    &.checked {
      background-image: url(${Checked});
    }
  }
`

export const CustomCheckboxLabel = styled.div`
  display: inline-flex;
  flex-flow: row nowrap;
  align-items: center;

  .icon {
    width: 55px;
    min-height: 5rem;
    background: url(${Other}) left center no-repeat;

    &.banking-services {
      background-image: url(${BankingServices});
    }
    &.payment-services {
      background-image: url(${PaymentServices});
    }
    &.forex-trading {
      background-image: url(${ForexTrading});
    }
  }
`
