import styled from 'styled-components';

export const VoucherDetailWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  gap: 1.5rem;

`;
export const VoucherDetailText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  font-size: 14px;
  gap: 1rem;

  .details-value {
    font-weight: 600;
  }
`;
