import { NoVouchersWrapper } from '../CashVoucher.styled'

export const NoVouchersComponent = () => {
  return (
    <NoVouchersWrapper>
      <h2>Awaiting Your Vouchers...</h2>
      <p>You currently don't have any vouchers. Purchase one now to enjoy our services!</p>
    </NoVouchersWrapper>
  )
}
