import styled from 'styled-components'

import { CommonSignupContent } from '../Commons'

export const ProductFormWrapper = styled(CommonSignupContent)`
  .highlight {
    background: rgba(88, 138, 238, 0.06);
    outline: 1px solid rgba(88, 138, 238, 0.57);
  }

  button {
    margin-top: 2.5rem;
  }
`

export const StyledInput = styled.input`
  width: 100%;
  height: 4rem;
  border: none;
  border-bottom: 1px solid rgba(163, 184, 194, 0.3);
  margin-bottom: 1rem;
  outline: none;

  color: #000000;
  font-family: inherit;
  font-size: 1.6rem;
  font-style: normal;
  font-weight: normal;
  line-height: 1.9rem;

  :-webkit-autofill,
  :-webkit-autofill:hover,
  :-webkit-autofill:focus {
    caret-color: #000000 !important;
    outline: none !important;
    border: none !important;
    border-bottom: 1px solid rgba(163, 184, 194, 0.3) !important;
  }

  ::-webkit-input-placeholder {
    color: #85a0ad;
    font-style: italic;
  }
  :-ms-input-placeholder {
    color: #85a0ad;
    font-style: italic;
  }
  ::placeholder {
    color: #85a0ad;
    font-style: italic;
  }
`
