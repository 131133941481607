import React from 'react'
import { DragAndDrop, FileInput } from 'mmfintech-portal-commons'
import { Expense } from 'mmfintech-commons-types'

interface IExpenseDragAndDropComponent {
  file: Expense['displayFile']
  onFileUpload: () => void
  onRemoveAll: () => void
  removeFile: () => void
  error: unknown
}

interface IExpenseDragAndDrop {
  file: Expense['displayFile']
  Component: React.FC<IExpenseDragAndDropComponent>
  onSelected?: (file: File) => void
  onError?: (error: any) => void
  onRemoveFile?: () => void
  error?: unknown
}

export const ExpenseDragAndDrop: React.FC<IExpenseDragAndDrop> = ({
  file,
  Component,
  onError,
  onSelected,
  onRemoveFile,
  error
}) => {
  const handleSelected = (list: File[] | FileList) => {
    if (list.length) {
      typeof onSelected === 'function' && onSelected(list[0])
    }
  }

  const handleError = (errors: any) => {
    typeof onError === 'function' && onError(errors)
  }

  const handleRemoveFile = () => {
    typeof onRemoveFile === 'function' && onRemoveFile()
  }

  return (
    <DragAndDrop showDropTarget={true} acceptType={['png', 'jpg', 'pdf']} onDrop={handleSelected} onError={handleError}>
      <FileInput
        maxNumber={1}
        // maxFileSize={certificateMaxFileSize}
        acceptType={['png', 'jpg', 'pdf']}
        onChange={handleSelected}
        onError={handleError}>
        {({ onFileUpload, onRemoveAll }) => (
          <Component
            file={file}
            onFileUpload={onFileUpload}
            onRemoveAll={onRemoveAll}
            removeFile={handleRemoveFile}
            error={error}
          />
        )}
      </FileInput>
    </DragAndDrop>
  )
}
