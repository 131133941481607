import parse from 'html-react-parser'

import { SuccessImage } from '../../../../icons'
import { SuccessMessageWrapper } from './MessagePreview.styled'

import { tr } from 'mmfintech-commons'

export const MessagePreview = ({ message }) => {
  const displayMessage = message.replace(/\r?\n/g, '<br/>').replace(/\\n/g, '<br/>')

  return (
    <SuccessMessageWrapper>
      <SuccessImage className='icon' />
      <div>
        <div className='title'>{tr('FRONTEND.DEPOSIT.RESULT.SUCCESS', 'Success')}</div>
        {parse(displayMessage)}
      </div>
    </SuccessMessageWrapper>
  )
}
