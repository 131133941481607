import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { formatMoney, tr } from 'mmfintech-commons'

import { actions } from 'mmfintech-backend-api'

import { ProgressBarWithInfoWrapper, ProgressBarInfoWrapper } from './styles/SpendingBar.styled'

const SpendingLimitBarUser = () => {
  const dispatch = useDispatch()

  const { userId, userExpenseLimits } = useSelector(({ user: { userId, userExpenseLimits } }: any) => ({
    userId,
    userExpenseLimits
  }))
  const { availableMonthlyVolume, monthlyVolume, currency, spentMonthlyVolume } = userExpenseLimits || {}

  useEffect(() => {
    dispatch(actions.merchant.getExpenseLimitUser(userId))
  }, [dispatch, userId])

  const availableMonthlyVol = availableMonthlyVolume > 0 ? availableMonthlyVolume : 0
  const progressPercentage = monthlyVolume ? 100 - (availableMonthlyVol / monthlyVolume) * 100 : 100

  if (!userExpenseLimits) {
    return null
  }

  return (
    <div>
      <ProgressBarInfoWrapper>
        <div>
          <span className='info-text'>
            {tr('FRONTEND.USERS.EXPENSE_MANAGEMENT.DASHBOARD.MONEY_SPENT', 'Money Spent:')}
          </span>
          {formatMoney(spentMonthlyVolume, currency)}
        </div>
        <div>
          <span className='info-text'>
            {tr('FRONTEND.USERS.EXPENSE_MANAGEMENT.DASHBOARD.MONTHLY_LIMIT', 'Monthly Limit:')}{' '}
          </span>
          {monthlyVolume ? formatMoney(monthlyVolume, currency) : <span className='infinity'>∞</span>}
        </div>
      </ProgressBarInfoWrapper>
      <ProgressBarWithInfoWrapper percentage={progressPercentage} monthlyVolume={monthlyVolume}>
        <div className='progress-bar'>
          <div>{monthlyVolume ? Math.round(progressPercentage) : 0}%</div>
        </div>
      </ProgressBarWithInfoWrapper>
    </div>
  )
}

export default SpendingLimitBarUser
