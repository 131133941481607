import { useContext, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { shallowEqual, useSelector } from 'react-redux'

import { AccessDenied } from '../../../components'
import { SendMoneyButton } from './SendMoneyButton'
import { NoSuitableAccountModal } from './NoSuitableAccountModal'
import { ArrowButton, ErrorDisplay, Preloader } from 'mmfintech-portal-commons'
import { SendMoneyFlowsContainer, SendMoneyTitle, SendMoneyWrapper } from './SendMoney.styled'

import { useHasWritePermissions } from '../../../hooks'
import { GlobalContext, isValidArray, tr } from 'mmfintech-commons'
import {
  actions,
  isOwnerOrAdministrator,
  paths,
  useLazyWithdrawAccountsQuery,
  useWithdrawSubFlows
} from 'mmfintech-backend-api'

import { WithdrawalSubFlow, WithdrawalSubFlowEnum } from 'mmfintech-commons-types'

import { ReactComponent as BankTransferIcon } from '../../../images/icons/send-bank-transfer.svg'
import { ReactComponent as BatchPaymentsIcon } from '../../../images/icons/send-batch-payment.svg'
import { ReactComponent as BetweenOwnAccountsIcon } from '../../../images/icons/send-between-own-accounts.svg'
import { ReactComponent as CryptoIcon } from '../../../images/icons/send-crypto.svg'
import { ReactComponent as JetonIcon } from '../../../images/icons/send-jeton.svg'
import { ReactComponent as PerfectMoneyIcon } from '../../../images/icons/send-perfect-money.svg'
import { ReactComponent as PixIcon } from '../../../images/icons/send-pix.svg'
import { ReactComponent as TransferIcon } from '../../../images/icons/send-transfer.svg'

import InteracIcon from '../../../images/icons/send-interac.png'

export const SendMoney = () => {
  const { modalHide, modalShow } = useContext(GlobalContext)

  const { customerRole, permissionsInvitedCustomersFetching } = useSelector(
    ({ user: { customerRole }, customer: { permissionsInvitedCustomersFetching } }: any) => ({
      customerRole,
      permissionsInvitedCustomersFetching
    }),
    shallowEqual
  )

  const [action, setAction] = useState<string>(null)

  const history = useHistory()
  const hasAnyPermissions = useHasWritePermissions()

  const { isSubFlowExists, withdrawSubFlowsError, withdrawSubFlowsFetching } = useWithdrawSubFlows()
  const [fetchAccounts, { isLoading: accountsFetching, error: accountsError }] = useLazyWithdrawAccountsQuery()

  const handleBackClick = () => actions.routing.returnFromWithdraw(history)

  const showNoSuitableAccountModal = () => {
    modalShow({
      options: { size: 'medium', transparent: true, closeOnClickOutside: false },
      content: <NoSuitableAccountModal onClose={modalHide} />
    })
  }

  const handleWithdrawal = async (flowType: WithdrawalSubFlow, redirectUrl: string) => {
    setAction(flowType)

    try {
      const accounts = await fetchAccounts(flowType).unwrap()
      if (isValidArray(accounts)) {
        history.push(redirectUrl)
      } else {
        showNoSuitableAccountModal()
      }
    } catch {}
  }

  return (
    <SendMoneyWrapper>
      <ArrowButton text={tr('FRONTEND.BUTTONS.BACK', 'Back')} onClick={handleBackClick} />

      <SendMoneyTitle data-test='send-title'>{tr('FRONTEND.BANKING.SEND_MONEY.TITLE', 'Send')}</SendMoneyTitle>

      <ErrorDisplay error={[accountsError, withdrawSubFlowsError]} />

      {withdrawSubFlowsFetching || permissionsInvitedCustomersFetching ? (
        <Preloader />
      ) : !hasAnyPermissions ? (
        <AccessDenied />
      ) : (
        <SendMoneyFlowsContainer>
          {isSubFlowExists(WithdrawalSubFlowEnum.BANKWIRE) && (
            <SendMoneyButton
              icon={<BankTransferIcon />}
              label={tr('FRONTEND.SEND_MONEY.BANK_TRANSFER_LABEL', 'Bank Transfer')}
              description={tr('FRONTEND.SEND_MONEY.BANK_TRANSFER_DESCRIPTION', 'Send money to a bank account')}
              loading={accountsFetching && action === WithdrawalSubFlowEnum.BANKWIRE}
              onClick={() => handleWithdrawal(WithdrawalSubFlowEnum.BANKWIRE, paths.banking.sendBankTransfer())}
              dataTest='bank-transfer-button'
            />
          )}
          {isSubFlowExists(WithdrawalSubFlowEnum.CRYPTO) && (
            <SendMoneyButton
              icon={<CryptoIcon />}
              label={tr('FRONTEND.SEND_MONEY.CRYPTO_LABEL', 'Send Crypto')}
              description={tr('FRONTEND.SEND_MONEY.CRYPTO_DESCRIPTION', "Send crypto to a recipient's address")}
              loading={accountsFetching && action === WithdrawalSubFlowEnum.CRYPTO}
              onClick={() => handleWithdrawal(WithdrawalSubFlowEnum.CRYPTO, paths.banking.sendCrypto())}
              dataTest='send-crypto-button'
            />
          )}
          {isSubFlowExists(WithdrawalSubFlowEnum.BATCH) && (
            <SendMoneyButton
              icon={<BatchPaymentsIcon />}
              label={tr('FRONTEND.BATCH_PAYMENTS.BUTTON.CAPTION', 'Batch Payment')}
              description={tr('FRONTEND.BATCH_PAYMENTS.BUTTON.DESCRIPTION', 'Make a one click mass payment')}
              loading={accountsFetching && action === WithdrawalSubFlowEnum.BATCH}
              onClick={() => history.push(paths.banking.sendBatchPayment())}
              dataTest='batch-payment-button'
            />
          )}
          {isSubFlowExists(WithdrawalSubFlowEnum.DIGITAL_WALLET) && (
            <SendMoneyButton
              icon={<JetonIcon />}
              label={tr('FRONTEND.DIGITAL_WALLET.LABEL', 'Jeton Wallet')}
              description={tr('FRONTEND.DIGITAL_WALLET.DESCRIPTION', 'Send money to Jeton Wallet')}
              loading={accountsFetching && action === WithdrawalSubFlowEnum.DIGITAL_WALLET}
              onClick={() => handleWithdrawal(WithdrawalSubFlowEnum.DIGITAL_WALLET, paths.banking.sendDigitalWallet())}
              dataTest='digital-wallet-button'
            />
          )}
          {isSubFlowExists(WithdrawalSubFlowEnum.INTERNAL_TRANSFER) && (
            <SendMoneyButton
              icon={<TransferIcon />}
              label={tr('FRONTEND.SEND_MONEY.USER_LABEL', 'Kingdom User')}
              description={tr('FRONTEND.SEND_MONEY.USER_DESCRIPTION', 'Send funds to another Kingdom Bank user')}
              loading={accountsFetching && action === WithdrawalSubFlowEnum.INTERNAL_TRANSFER}
              onClick={() => handleWithdrawal(WithdrawalSubFlowEnum.INTERNAL_TRANSFER, paths.banking.sendTransfer())}
              dataTest='internal-transfer-button'
            />
          )}
          {isSubFlowExists(WithdrawalSubFlowEnum.INTRA_ACCOUNT_TRANSFER) && isOwnerOrAdministrator(customerRole) && (
            <SendMoneyButton
              icon={<BetweenOwnAccountsIcon />}
              label={tr('FRONTEND.SEND_MONEY.OWN_ACCOUNTS.LABEL', 'Between Own Accounts')}
              description={tr('FRONTEND.SEND_MONEY.OWN_ACCOUNTS.DESCRIPTION', 'Transfer funds between your accounts')}
              loading={accountsFetching && action === WithdrawalSubFlowEnum.INTRA_ACCOUNT_TRANSFER}
              onClick={() =>
                handleWithdrawal(WithdrawalSubFlowEnum.INTRA_ACCOUNT_TRANSFER, paths.banking.sendIntraAccountTransfer())
              }
              dataTest='transfer-between-accounts-button'
            />
          )}
          {isSubFlowExists(WithdrawalSubFlowEnum.PIX) && (
            <SendMoneyButton
              icon={<PixIcon />}
              label={tr('FRONTEND.WITHDRAW.PIX_TRANSFER.TITLE', 'PIX Transfer')}
              description={'Send money to a PIX account'}
              loading={accountsFetching && action === WithdrawalSubFlowEnum.PIX}
              onClick={() => handleWithdrawal(WithdrawalSubFlowEnum.PIX, paths.banking.sendPix())}
              dataTest='pix-transfer-button'
            />
          )}
          {isSubFlowExists(WithdrawalSubFlowEnum.PERFECT_MONEY) && (
            <SendMoneyButton
              icon={<PerfectMoneyIcon />}
              label={tr('FRONTEND.WITHDRAW.PERFECT_MONEY_TRANSFER.TITLE', 'Perfect Money Transfer')}
              description='Send money to a Perfect Money account'
              loading={accountsFetching && action === WithdrawalSubFlowEnum.PERFECT_MONEY}
              onClick={() => handleWithdrawal(WithdrawalSubFlowEnum.PERFECT_MONEY, paths.banking.sendPerfectMoney())}
              dataTest='perfect-money-transfer-button'
            />
          )}
          {isSubFlowExists(WithdrawalSubFlowEnum.INTERAC) && (
            <SendMoneyButton
              icon={<img src={InteracIcon} alt='' />}
              label={tr('FRONTEND.WITHDRAW.INTERAC.TITLE', 'Interac transfer')}
              description={tr('FRONTEND.WITHDRAW.INTERAC.SUBTITLE', 'Send money to an Interac account')}
              loading={accountsFetching && action === WithdrawalSubFlowEnum.INTERAC}
              onClick={() => handleWithdrawal(WithdrawalSubFlowEnum.INTERAC, paths.banking.sendInterac())}
              dataTest='interac-transfer-button'
            />
          )}
        </SendMoneyFlowsContainer>
      )}
    </SendMoneyWrapper>
  )
}
