import styled from 'styled-components'
import { CommonRoundShadowedContainer } from '../../../../components'

const RoundSquareContainer = styled(CommonRoundShadowedContainer)`
  padding: 2rem 3rem;

  @media (min-width: 1024px) {
    padding: 3rem 5rem;
  }
`

export const ModalMessageWrapper = styled(RoundSquareContainer)`
  width: 100%;
  max-width: 50rem;
  margin: 0 1rem;

  display: flex;
  flex-flow: column nowrap;
  align-items: center;

  .secondary {
    font-size: 1.8rem;
    font-weight: 600;
    line-height: 2.2rem;
  }
`

export const MessageContainer = styled.div`
  color: #000000;

  font-family: inherit;
  font-size: 1.6rem;
  font-style: normal;
  font-weight: normal;
  line-height: 1.9rem;

  text-align: center;
  margin-bottom: 4rem;
`

export const ButtonsContainer = styled.div`
  margin-bottom: 2rem;

  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
`

export const StyledButton = styled(RoundSquareContainer)`
  width: 100%;
  height: 10rem;
  max-width: 16rem;

  padding: 2rem 1rem;

  display: inline-flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;

  color: #a3b8c2;
  font-family: inherit;
  font-size: 1.6rem;
  font-style: normal;
  font-weight: normal;
  line-height: 1.9rem;
  text-transform: capitalize;

  cursor: pointer;

  margin-right: 2rem;
  &:last-of-type {
    margin-right: 0;
  }

  img,
  svg {
    margin-bottom: 1rem;
  }

  &:hover {
    margin-top: -1rem;
    margin-bottom: 1rem;
    color: #000000;
    transition: all 0.5s;
  }
  transition: all 0.5s;
`
