import { useEffect } from 'react'
import cn from 'classnames'

import { ModalContainer, ModalWrapper } from './ModalDialog.styled'

import { useModal } from 'mmfintech-commons'
import { useLocation } from 'react-router-dom'
import { ReactComponent as CloseIcon } from '../../images/icons/icon-close.svg'

export const ModalDialog = ({ content, options, visible, onClose }) => {
  const { ref, modalOptions, handleClose, clickOutsideRef } = useModal({
    options,
    visible,
    onClose
  })
  const location = useLocation()

  useEffect(() => {
    if (visible) {
      typeof onClose === 'function' && onClose()
    }
    // eslint-disable-next-line
  }, [location.pathname])

  if (!visible) {
    return null
  }

  return (
    <ModalWrapper ref={ref}>
      <ModalContainer
        data-test='modal-container'
        className={cn({
          'size-auto': modalOptions.size === 'auto',
          'size-large': modalOptions.size === 'large',
          'size-small': modalOptions.size === 'small',
          'size-extra-large': modalOptions.size === 'extra-large',
          'size-custom': modalOptions.size === 'custom',
          centered: modalOptions.size === 'centered',
          horizontal: modalOptions.size === 'horizontal',
          transparent: modalOptions.transparent === true,
          'overflow-none': modalOptions.overflow === 'none',
          'overflow-visible': modalOptions.overflow === 'visible'
        })}
        custom={modalOptions.size === 'custom' ? modalOptions.customSize : null}>
        {modalOptions.hideCloseIcon ? null : <CloseIcon className='close-icon' onClick={handleClose} />}
        <div ref={clickOutsideRef}>{content}</div>
      </ModalContainer>
    </ModalWrapper>
  )
}
