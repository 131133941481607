import { Button } from 'mmfintech-portal-commons'

export const NewsButtonsContainer = ({ activeNewsIndex, availableNewsLength, handleTransition }) => (
  <div className='buttons-container'>
    {activeNewsIndex > 0 && (
      <Button
        data-test='previous-button'
        text='Previous'
        color='secondary'
        aria-label='Go to previous news'
        onClick={() => handleTransition(activeNewsIndex - 1)}
      />
    )}
    <Button
      data-test='next-button'
      aria-label='Go to next news'
      text={activeNewsIndex === availableNewsLength - 1 ? 'Got it!' : 'Next'}
      onClick={() => handleTransition(activeNewsIndex + 1)}
    />
  </div>
)
